import React, { useContext, useState, useEffect, useCallback } from "react";
import { CarritoContext } from "../services/CarritoProvider";
import { Link, useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../services/Config";
import { verificarStockCarrito } from "../services/Api";
import Footer from "../services/Footer";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";

const Carrito = () => {
  const {
    carrito,
    setCarrito,
    removerProducto,
    obtenerTotal,
    actualizarCantidad,
    manejarCambioCantidad,
  } = useContext(CarritoContext);
  const [mostrarModalVerificacion, setMostrarModalVerificacion] =
    useState(false);
  const [respuestaVerificacion, setRespuestaVerificacion] = useState(null);
  const navigate = useNavigate();

  const verificarStock = useCallback(async () => {
    try {
      const respuesta = await verificarStockCarrito(carrito);
      setRespuestaVerificacion(respuesta);

      if (!respuesta.sinCambios) {
        setMostrarModalVerificacion(true);
      }
    } catch (error) {
      console.error("Error al verificar stock:", error);
    }
  }, [carrito]);

  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem("carrito")) || [];
    setCarrito(storedCart);
  }, [setCarrito]);

  useEffect(() => {
    if (carrito.length > 0) {
      verificarStock();
    }
  }, [carrito, verificarStock]);

  const sincronizarCarritoConBackend = (carritoActualizadoDesdeBackend) => {
    const carritoActual = [...carrito];

    const carritoSincronizado = carritoActual
      .map((productoDelCarrito) => {
        const productoActualizado = carritoActualizadoDesdeBackend.find(
          (p) => p.id === productoDelCarrito.id
        );

        if (productoActualizado) {
          // Si la cantidad disponible es 0, mostrar mensaje y no incluir este producto en el carrito actualizado
          if (productoActualizado.cantidadDisponible === 0) {
            //alert(`El stock del producto ${productoActualizado.nombre} se ha agotado y será eliminado de tu carrito.`);
            return null; // Marcamos el producto para eliminación no incluyéndolo en el carrito sincronizado
          }

          // Actualizar la cantidad del producto y cualquier otro dato relevante
          return {
            ...productoDelCarrito,
            cantidad: productoActualizado.cantidadDisponible,
            estado: productoActualizado.estado,
          };
        }

        return productoDelCarrito; // Si el producto no está en carritoActualizadoDesdeBackend, lo retornamos sin cambios
      })
      .filter(
        (producto) => producto !== null && producto.estado !== "inactivo"
      ); // Elimina productos agotados o inactivos

    // Actualiza el carrito con los datos sincronizados
    setCarrito(carritoSincronizado);
    localStorage.setItem("carrito", JSON.stringify(carritoSincronizado));
    setMostrarModalVerificacion(false);
  };

  const incrementarCantidad = (productoId) => {
    const producto = carrito.find((producto) => producto.id === productoId);
    if (!producto) return; // Si por alguna razón el producto no se encuentra, aborta la función.

    manejarCambioCantidad(productoId, producto.cantidad + 1);
  };

  const decrementarCantidad = (productoId) => {
    const producto = carrito.find((producto) => producto.id === productoId);
    if (!producto) return; // Similarmente, aborta si el producto no se encuentra.

    // Aquí decides no llamar a manejarCambioCantidad si la cantidad será 0 y optas por remover el producto.
    if (producto.cantidad === 1) {
      removerProducto(productoId);
    } else {
      manejarCambioCantidad(productoId, producto.cantidad - 1);
    }
  };

  const verificarStockAntesDeComprar = async () => {
    try {
      const respuesta = await verificarStockCarrito(carrito);
      setRespuestaVerificacion(respuesta);
      if (!respuesta.sinCambios) {
        // Hay problemas con el stock, mostrar modal para correcciones
        setMostrarModalVerificacion(true);
      } else {
        // No hay problemas con el stock, proceder con la compra
        procederConLaCompra();
      }
    } catch (error) {
      console.error("Error al verificar stock:", error);
      alert(
        "Hubo un problema al verificar el stock. Por favor, intenta de nuevo."
      );
    }
  };

  const procederConLaCompra = () => {
    // Lógica para proceder con la compra...

    // Por ejemplo, navegar a la página de pago o confirmación
    navigate("/pago");
  };

  return (
    <div className="flex flex-col min-h-screen">
      <div
        className="container mx-auto mt-10 p-4 rounded-lg bg-white flex-grow mb-6"
        style={{ maxWidth: "1100px" }}
      >
        <h2 className="text-2xl font-bold mb-8">Carrito</h2>
        {carrito.length === 0 ? (
          <div className="flex flex-col justify-center items-center h-full py-8">
            <p className="mb-4 text-lg text-gray-700">Tu carrito está vacío.</p>
            <Link
              to="/catalogo"
              className="bg-blue-600 text-white font-semibold py-3 px-6 rounded-lg shadow hover:bg-blue-700 transition duration-150"
            >
              Volver a la tienda
            </Link>
          </div>
        ) : (
          <div>
            {/* Versión móvil: Lista de productos en tarjetas */}
            <div className="md:hidden">
              {carrito.map((producto) => (
                <div
                  key={producto.id}
                  className="bg-white shadow rounded-lg mb-4 p-4 border border-gray-100"
                >
                  <div className="flex items-center">
                    <img
                      src={API_BASE_URL + producto.imagenes[0].uri_foto}
                      alt={producto.nombre}
                      className="w-32 h-32 object-cover mr-4 border border-gray-300 rounded-sm"
                    />
                    <div className="ml-12">
                      <h3 className="text-lg font-semibold">
                        {producto.nombre}
                      </h3>
                      <p>Precio: ${Number(producto.precio).toLocaleString("es-CL")}</p>
                      <div className="flex items-center justify-between border border-gray-200 p-1 rounded-lg">
                        <IconButton
                          onClick={() => decrementarCantidad(producto.id)}
                          size="small"
                          sx={{ color: "rgba(0, 0, 0, 0.54)" }}
                        >
                          <RemoveIcon />
                        </IconButton>
                        <input
                          type="text"
                          value={producto.cantidad}
                          onChange={(e) =>
                            actualizarCantidad(
                              producto.id,
                              parseInt(e.target.value)
                            )
                          }
                          className="text-center w-12"
                          readOnly
                        />
                        <IconButton
                          onClick={() => incrementarCantidad(producto.id)}
                          size="small"
                          sx={{ color: "rgba(0, 0, 0, 0.54)" }}
                        >
                          <AddIcon />
                        </IconButton>
                      </div>
                      <p>Subtotal: ${(producto.precio * producto.cantidad).toLocaleString("es-CL")}</p>
                    </div>
                  </div>
                  <div className="text-right mt-4">
                    <button
                      onClick={() => removerProducto(producto.id)}
                      className="text-red-600 hover:text-red-800 text-sm"
                    >
                      Quitar
                    </button>
                  </div>
                </div>
              ))}
            </div>
            {/* Versión de escritorio: Tabla de productos */}
            <div className="hidden md:block overflow-y-auto max-h-[550px] mb-6">
              <table className="min-w-full">
                <thead>
                  <tr>
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Producto
                    </th>
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Precio
                    </th>
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Cantidad
                    </th>
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Total
                    </th>
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50"></th>
                  </tr>
                </thead>
                <tbody
                  className={`overflow-y-auto ${
                    carrito.length > 4 ? "h-96" : "h-auto"
                  }`}
                >
                  {carrito.map((producto) => (
                    <tr key={producto.id} className="border-b">
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          {producto.imagenes && producto.imagenes.length > 0 ? (
                            <img
                              src={`${API_BASE_URL}${producto.imagenes[0].uri_foto}`}
                              alt={producto.nombre}
                              className="w-32 h-32 object-cover mr-4 border border-gray-300 rounded-sm"
                            />
                          ) : (
                            <div className="w-32 h-32 flex items-center justify-center mr-4 border border-gray-300 rounded-sm">
                              Sin Imagen
                            </div>
                          )}

                          <div>
                            <p className="text-xl font-semibold">
                              {producto.nombre}
                            </p>
                            <button
                              onClick={() => removerProducto(producto.id)}
                              className="text-red-600 hover:text-red-800 text-xs md:text-sm"
                            >
                              Quitar
                            </button>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                        ${Number(producto.precio).toLocaleString("es-CL")}
                      </td>
                      <td className="px-0 py-4 whitespace-nowrap">
                        <div className="flex items-center justify-center border border-gray-200 p-0.5 rounded-lg w-28">
                          <IconButton
                            onClick={() => decrementarCantidad(producto.id)}
                            size="small"
                            sx={{ color: "rgba(0, 0, 0, 0.54)" }}
                          >
                            <RemoveIcon />
                          </IconButton>
                          <input
                            type="text"
                            value={producto.cantidad}
                            onChange={(e) =>
                              actualizarCantidad(
                                producto.id,
                                parseInt(e.target.value)
                              )
                            }
                            className="text-center w-10"
                            readOnly
                          />
                          <IconButton
                            onClick={() => incrementarCantidad(producto.id)}
                            size="small"
                            sx={{ color: "rgba(0, 0, 0, 0.54)" }}
                          >
                            <AddIcon />
                          </IconButton>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                        $
                        {(producto.precio * producto.cantidad).toLocaleString(
                          "es-CL"
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex flex-col md:flex-row-reverse mt-6 md:mt-0">
              {/* Sección de subtotal y botón de pago */}
              <div className="md:w-1/4 md:ml-4">
                <div className="flex md:justify-end">
                  <div className="w-full md:max-w-xs">
                    <div className="flex justify-between mb-4">
                      <h3 className="text-lg font-bold">Subtotal</h3>
                      <span className="text-lg">${obtenerTotal().toLocaleString("es-CL")}</span>
                    </div>
                    <p className="text-sm mb-6">
                      Solo se realizan ventas en Chile.
                    </p>
                    <button
                      onClick={verificarStockAntesDeComprar}
                      className="block w-full text-center bg-black text-white font-bold py-3 px-6 rounded hover:bg-gray-900 transition-colors"
                    >
                      Finalizar Pedido
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {mostrarModalVerificacion && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 flex justify-center items-center">
            <div className="bg-white p-8 rounded-lg shadow-2xl w-full max-w-md">
              <h2 className="text-2xl font-semibold mb-6 text-gray-800">
                Verificación de Stock
              </h2>
              {respuestaVerificacion.carritoActualizado
                .filter((producto) => producto.estado === "stock insuficiente")
                .map((producto) => (
                  <div key={producto.id} className="flex mb-6 items-center">
                    <div className="flex-1">
                      <p className="font-bold text-lg text-gray-700">
                        {producto.nombre}
                      </p>
                      <p className="text-red-500">{producto.estado}</p>
                      <p className="text-gray-600">
                        Cantidad disponible: {producto.cantidadDisponible}
                      </p>
                      <p className="text-gray-600">
                        Cantidad solicitada: {producto.solicitado}
                      </p>
                    </div>
                    <img
                      src={API_BASE_URL + producto.uri_foto}
                      alt={producto.nombre}
                      className="w-32 h-32 object-cover ml-4 border border-gray-300 rounded-sm"
                    />
                  </div>
                ))}
              <div className="flex justify-between mt-6 space-x-4">
                <button
                  onClick={() =>
                    sincronizarCarritoConBackend(
                      respuestaVerificacion.carritoActualizado
                    )
                  }
                  className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-6 rounded transition duration-150 ease-in-out"
                >
                  Aceptar Cambios
                </button>
                <button
                  onClick={() => setMostrarModalVerificacion(false)}
                  className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded transition duration-150 ease-in-out"
                >
                  Revisar Carrito
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Carrito;
