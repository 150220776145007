import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CarritoContext } from './CarritoProvider';
import { API_BASE_URL } from './Config';
import { MdClose } from 'react-icons/md'; // Importar el icono de cierre

const ResumenCarrito = () => {
  const { carrito, productoAgregado, setProductoAgregado } = useContext(CarritoContext);
  const [visible, setVisible] = useState(false);

  let navigate = useNavigate();
  
  useEffect(() => {
    if (productoAgregado) {
      setVisible(true);
      const timer = setTimeout(() => {
        setVisible(false);
        setProductoAgregado(null);
      }, 7000);

      return () => clearTimeout(timer);
    }
  }, [productoAgregado, setProductoAgregado]);

  if (!visible || !productoAgregado) {
    return null;
  }

  const irACarrito = () => {
    navigate('/carrito');
    setVisible(false);
    setProductoAgregado(null); 
  };

  const ultimoProducto = carrito[carrito.length - 1];

  return (
    <div className="fixed top-16 right-0 bg-white shadow-lg p-4 rounded-lg animate-slide-in" style={{ zIndex: 1000, width: '300px' }}>
      <style>{`
        @keyframes slideIn {
          0% {
            transform: translateX(100%);
            opacity: 0;
          }
          100% {
            transform: translateX(0);
            opacity: 1;
          }
        }

        .animate-slide-in {
          animation: slideIn 0.3s ease-out;
        }
      `}</style>
      <button onClick={() => setVisible(false)} className="absolute top-0 right-0 m-2"><MdClose /></button>
      <div className="flex items-center space-x-4">
        {ultimoProducto.imagenes && ultimoProducto.imagenes.length > 0 ? (
          <img
            src={`${API_BASE_URL}${ultimoProducto.imagenes[0].uri_foto}`}
            alt={ultimoProducto.nombre}
            className="w-16 h-16 object-cover"
          />
        ) : (
          <div className="w-16 h-16 flex items-center justify-center border border-gray-200">
            Sin Imagen
          </div>
        )}
        <div>
          <p className="font-bold">{ultimoProducto.nombre}</p>
          <p>Cantidad: {ultimoProducto.cantidad}</p>
        </div>
      </div>
      <div className="mt-4">
        <button onClick={irACarrito} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
          VER CARRITO ({carrito.length})
        </button>
        <button className="text-blue-500 px-4 py-2 hover:underline" onClick={() => setVisible(false)}>Seguir comprando</button>
      </div>
    </div>
  );
};

export default ResumenCarrito;
