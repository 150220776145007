import React, { useState, useEffect } from "react";
import { obtenerCarruselImagenes, editarCarrusel } from "../../services/Api";
import ImageCropperEditCarrusel from "../../services/ImageCropperEditCarrusel";
import ImageCropperEditCarruselMovil from "../../services/ImageCropperEditCarruselMovil";
import imageCompression from "browser-image-compression";


const Carrusel = () => {
  const [carrusel, setCarrusel] = useState([]);
  const [resetEditorKey, setResetEditorKey] = useState(0);
  const [imagenesProcesadas, setImagenesProcesadas] = useState([]);
  const [imagenesProcesadasMovil, setImagenesProcesadasMovil] = useState([]);


  useEffect(() => {
    const cargarCarrusel = async () => {
      try {
        const carruselesObtenidos = await obtenerCarruselImagenes();
        // Asume que el primer item es el carrusel de escritorio y el segundo el de móvil
        const imagenesEditables = carruselesObtenidos[0].imagenes.map((imagen, index) => ({
          id: `edit-desktop-${carruselesObtenidos[0].id}-${index}`,
          src: imagen.base64,
        }));
        const imagenesEditablesMovil = carruselesObtenidos[1].imagenes.map((imagen, index) => ({
          id: `edit-movil-${carruselesObtenidos[1].id}-${index}`,
          src: imagen.base64,
        }));
        setCarrusel(carruselesObtenidos);
        setImagenesProcesadas(imagenesEditables);
        setImagenesProcesadasMovil(imagenesEditablesMovil);
      } catch (error) {
        console.error("Error al obtener carrusel:", error);
      }
    };
  
    cargarCarrusel();
  }, []);

  //Escritorio

  const handleSubmitActualizarCarrusel = async () => {
    if (imagenesProcesadas.length === 0) {
      alert("Por favor, añade al menos una imagen al carrusel.");
      return;
    }

    // Prepara los datos para enviar
    const datos = {
      imagenes: imagenesProcesadas.map((imagen) => imagen.src),
      // Añade cualquier otro dato necesario del carrusel aquí
    };

    try {
      
      const respuesta = await editarCarrusel(carrusel[0].id, datos);
      if (
        respuesta &&
        respuesta.message === "Carrusel actualizado exitosamente"
      ) {
        setResetEditorKey((prevKey) => prevKey + 1);
        alert(respuesta.message);
        // Aquí puedes manejar cualquier lógica adicional después de la actualización exitosa
        // Por ejemplo, recargar los datos del carrusel o cerrar un modal
      } else {
        alert("No se pudo actualizar el carrusel correctamente.");
      }
    } catch (error) {
      console.error("Error al actualizar el carrusel:", error);
      alert("Error al actualizar el carrusel. Por favor, inténtelo de nuevo.");
    }
  };

  const manejarImagenesProcesadas = async (imagenProcesada, id) => {
    try {
        const options = {
            maxSizeMB: 1, // Tamaño máximo permitido en megabytes
            maxWidthOrHeight: 1024, // Ancho o altura máximo permitido
            useWebWorker: true, // Usar Web Worker para una mejor eficiencia
        };

        let imagenComprimida;
        if (typeof imagenProcesada === 'string' && imagenProcesada.startsWith('data:')) {
            // Si la imagen procesada es una cadena en formato base64
            imagenComprimida = await compressBase64Image(imagenProcesada, options);
            
        } else {
            // Si la imagen procesada es un Blob
            imagenComprimida = await imageCompression(imagenProcesada, options);
        }

        setImagenesProcesadas((prevImagenes) => {
            const imagenExiste = prevImagenes.some((imagen) => imagen.id === id);
            if (imagenExiste) {
                return prevImagenes.map((imagen) =>
                    imagen.id === id ? { ...imagen, src: imagenComprimida } : imagen
                );
            } else {
                return [...prevImagenes, { id, src: imagenComprimida }];
            }
        });
    } catch (error) {
        console.error("Error al comprimir la imagen:", error);
    }
};


async function compressBase64Image(base64String, maxWidthOrQuality) {
  try {
      // Convertir la cadena base64 en un Blob
      var blob = base64StringToBlob(base64String);

      // Comprimir el Blob utilizando la función existente
      var compressedBlob = await imageCompression(blob, maxWidthOrQuality);

      // Leer el Blob comprimido como una cadena base64
      const compressedBase64 = await readBlobAsBase64(compressedBlob);

      // Devolver la cadena base64 comprimida
      return compressedBase64;
  } catch (error) {
      console.error("Error al comprimir la imagen:", error);
      throw error;
  }
}

function readBlobAsBase64(blob) {
  return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
  });
}


  const manejarCambioDeOrden = async (nuevoOrdenDeImagenes) => {
    // Este código asume que todas las imágenes pueden necesitar ser convertidas de blob a Base64.
    // Si las imágenes ya están en Base64, simplemente se mantienen como están.
    const imagenesConvertidas = await Promise.all(
      nuevoOrdenDeImagenes.map(async (imagen) => {
        if (!imagen.src.startsWith("blob:")) {
          return imagen; // La imagen ya está en Base64
        }
        const srcBase64 = await blobToBase64(imagen.src);
        return { ...imagen, src: srcBase64 };
      })
    );
    setImagenesProcesadas(imagenesConvertidas);
  };

  function blobToBase64(blobUrl) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      fetch(blobUrl) // Fetch the blob from the blob URL
        .then((res) => res.blob())
        .then((blob) => {
          reader.readAsDataURL(blob); // Read the blob as DataURL
          reader.onloadend = function () {
            resolve(reader.result); // Return the result
          };
          reader.onerror = reject;
        })
        .catch(reject);
    });
  }

  function base64StringToBlob(base64String) {
    var parts = base64String.split(';base64,');
    var contentType = parts[0].split(':')[1];
    var raw = window.atob(parts[1]);
    var rawLength = raw.length;
    var uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
}

  const manejarEliminacionDeImagen = (imageId) => {
    

    // Filtra directamente las imágenes procesadas para eliminar la imagen con el id específico
    const imagenesActualizadas = imagenesProcesadas.filter(
      (imagen) => imagen.id !== imageId
    );

    setImagenesProcesadas(imagenesActualizadas);

    
  };

  //Movil

  const handleSubmitActualizarCarruselMovil = async () => {
    if (imagenesProcesadasMovil.length === 0) {
      alert("Por favor, añade al menos una imagen al carrusel.");
      return;
    }

    // Prepara los datos para enviar
    const datos = {
      imagenes: imagenesProcesadasMovil.map((imagen) => imagen.src),
      // Añade cualquier otro dato necesario del carrusel aquí
    };

    try {
      
      const respuesta = await editarCarrusel(carrusel[1].id, datos);
      if (
        respuesta &&
        respuesta.message === "Carrusel actualizado exitosamente"
      ) {
        setResetEditorKey((prevKey) => prevKey + 1);
        alert(respuesta.message);
        // Aquí puedes manejar cualquier lógica adicional después de la actualización exitosa
        // Por ejemplo, recargar los datos del carrusel o cerrar un modal
      } else {
        alert("No se pudo actualizar el carrusel correctamente.");
      }
    } catch (error) {
      console.error("Error al actualizar el carrusel:", error);
      alert("Error al actualizar el carrusel. Por favor, inténtelo de nuevo.");
    }
  };

  const manejarImagenesProcesadasMovil = async (imagenProcesada, id) => {
    try {
      const options = {
        maxSizeMB: 1, // Tamaño máximo permitido en megabytes
        maxWidthOrHeight: 1024, // Ancho o altura máximo permitido
        useWebWorker: true, // Usar Web Worker para una mejor eficiencia
      };
  
      let imagenComprimida;
      if (typeof imagenProcesada === 'string' && imagenProcesada.startsWith('data:')) {
        // Si la imagen procesada es una cadena en formato base64
        imagenComprimida = await compressBase64Image(imagenProcesada, options);
      } else {
        // Si la imagen procesada es un Blob
        imagenComprimida = await imageCompression(imagenProcesada, options);
      }
  
      setImagenesProcesadasMovil((prevImagenes) => { // Asegúrate de actualizar el estado correcto aquí
        const imagenExiste = prevImagenes.some(imagen => imagen.id === id);
        if (imagenExiste) {
          return prevImagenes.map(imagen =>
            imagen.id === id ? { ...imagen, src: imagenComprimida } : imagen
          );
        } else {
          return [...prevImagenes, { id, src: imagenComprimida }];
        }
      });
    } catch (error) {
      console.error("Error al comprimir la imagen:", error);
    }
  };
  

  const manejarCambioDeOrdenMovil = async (nuevoOrdenDeImagenes) => {
    // Este código asume que todas las imágenes pueden necesitar ser convertidas de blob a Base64.
    // Si las imágenes ya están en Base64, simplemente se mantienen como están.
    const imagenesConvertidas = await Promise.all(
      nuevoOrdenDeImagenes.map(async (imagen) => {
        if (!imagen.src.startsWith("blob:")) {
          return imagen; // La imagen ya está en Base64
        }
        const srcBase64 = await blobToBase64(imagen.src);
        return { ...imagen, src: srcBase64 };
      })
    );
    setImagenesProcesadasMovil(imagenesConvertidas);
  };

  const manejarEliminacionDeImagenMovil = (imageId) => {
    // Filtra las imágenes para eliminar la que coincida con `imageId`
    const imagenesActualizadas = imagenesProcesadasMovil.filter(imagen => imagen.id !== imageId);
    setImagenesProcesadasMovil(imagenesActualizadas);
  };
  
  

  return (
    <div className="container mx-auto p-4">
      <div className="bg-white p-4 rounded shadow-md mb-8">
        <h2 className="text-lg font-semibold mb-4">Carrusel de Escritorio</h2>
        <ImageCropperEditCarrusel
          key={`desktop-${resetEditorKey}`}
          imagenesExistentes={imagenesProcesadas}
          onImagesProcessed={manejarImagenesProcesadas}
          onOrderChange={manejarCambioDeOrden}
          onImageRemove={manejarEliminacionDeImagen}
        />
        <button
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          onClick={handleSubmitActualizarCarrusel}
        >
          Actualizar Carrusel de Escritorio
        </button>
      </div>
  
      <div className="bg-white p-4 rounded shadow-md">
        <h2 className="text-lg font-semibold mb-4">Carrusel Móvil</h2>
        <ImageCropperEditCarruselMovil
          key={`movil-${resetEditorKey}`}
          imagenesExistentes={imagenesProcesadasMovil}
          onImagesProcessed={manejarImagenesProcesadasMovil}
          onOrderChange={manejarCambioDeOrdenMovil}
          onImageRemove={manejarEliminacionDeImagenMovil}
        />
        <button
          className="mt-4 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
          onClick={handleSubmitActualizarCarruselMovil}
        >
          Actualizar Carrusel Móvil
        </button>
      </div>
    </div>
  );
  
};

export default Carrusel;
