import React, { useState, useEffect } from "react";
import {
  obtenerProductos,
  agregarProducto,
  obtenerCategorias,
  editarProducto,
  obtenerMateriales,
  obtenerProductoConImagenes,
} from "../../services/Api";
import ImageCropper from "../../services/ImageCropper";
import ImageCropperEdit from "../../services/ImageCropperEdit";
import { API_BASE_URL } from "../../services/Config";
import imageCompression from "browser-image-compression";

const Productos = () => {
  const [nuevoProducto, setNuevoProducto] = useState({
    nombre: "",
    precio: "",
    descripcion: "",
    estado: "",
    stock: "",
    tamanio: "",
    Categoriaid: "",
    Materialid: "",
  });

  const [mostrarModalAgregar, setMostrarModalAgregar] = useState(false);
  const [productos, setProductos] = useState([]);
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [messageType, setMessageType] = useState("success"); // o 'error'
  const [errors, setErrors] = useState({});
  const [categorias, setCategorias] = useState([]);
  const [materiales, setMateriales] = useState([]);
  const [mostrarModalEditar, setMostrarModalEditar] = useState(false);
  const [mostrarModalConfirmacion, setMostrarModalConfirmacion] =
    useState(false);
  const [productoAEditar, setProductoAEditar] = useState(null);
  const [productoACambiarEstado, setProductoACambiarEstado] = useState(null);
  const [imagenesProcesadas, setImagenesProcesadas] = useState([]);
  const [resetEditorKey, setResetEditorKey] = useState(0);
  const [paginaActual, setPaginaActual] = useState(1);
  const productosPorPagina = 10;
  const totalPaginas = Math.ceil(productos.length / productosPorPagina);

  useEffect(() => {
    cargarCategorias();
    cargarProductos();
    cargarMateriales();
  }, []);

  useEffect(() => {
    let timerId;
    if (showMessage) {
      // Establecer el temporizador para ocultar el mensaje después de 5 segundos
      timerId = setTimeout(() => {
        setShowMessage(false);
      }, 5000);
    }
    // Función de limpieza para borrar el temporizador
    return () => clearTimeout(timerId);
  }, [showMessage]);

  const cargarProductos = async () => {
    try {
      const productosObtenidos = await obtenerProductos();
      setProductos(productosObtenidos.map((p) => ({ ...p, abierto: false })));
      
    } catch (error) {
      console.error("Error al obtener productos:", error);
    }
  };

  const cargarCategorias = async () => {
    try {
      const categoriasObtenidas = await obtenerCategorias();
      setCategorias(categoriasObtenidas);
    } catch (error) {
      console.error("Error al obtener las categorías:", error);
    }
  };

  const cargarMateriales = async () => {
    try {
      const materialesObtenidos = await obtenerMateriales();
      setMateriales(materialesObtenidos);
    } catch (error) {
      console.error("Error al obtener los materiales:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNuevoProducto({ ...nuevoProducto, [name]: value });
  };

  const handleSubmitAgregar = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    for (const key in nuevoProducto) {
      if (nuevoProducto[key]) {
        formData.append(key, nuevoProducto[key]);
      }
    }

    imagenesProcesadas.forEach((imagen, index) => {
      // Asegúrate de que aquí solo estás añadiendo el contenido de la imagen (src),
      // que es lo que se espera en el backend
      formData.append(`imagenes[${index}]`, imagen.src);
    });

    // Validar el formulario antes de la subida
    const formErrors = validateProductoForm(nuevoProducto);
    if (Object.keys(formErrors).length === 0) {
      try {
        const respuesta = await agregarProducto(formData);
        if (respuesta && respuesta.message === "Producto creado exitosamente") {
          mostrarMensaje(respuesta.message, "success");
          const productosObtenidos = await obtenerProductos();
          setProductos(productosObtenidos);
          setNuevoProducto({
            nombre: "",
            precio: "",
            descripcion: "",
            estado: "",
            stock: "",
            tamanio: "",
            Categoriaid: "",
            Materialid: "",
          });
          setImagenesProcesadas([]);
          setResetEditorKey((prevKey) => prevKey + 1);
          setMostrarModalAgregar(false);
        } else {
          mostrarMensaje(
            "No se pudo agregar el producto correctamente.",
            "error"
          );
        }
      } catch (error) {
        mostrarMensaje(
          "Error al agregar el producto. Por favor, inténtelo de nuevo.",
          "error"
        );
      }
    } else {
      setErrors(formErrors);
    }
  };

  const mostrarMensaje = (texto, tipo) => {
    setMessage(texto);
    setMessageType(tipo);
    setShowMessage(true);
  };

  const toggleDetalles = (id) => {
    setProductos(
      productos.map((producto) => {
        if (producto.id === id) {
          return { ...producto, abierto: !producto.abierto };
        }
        return producto;
      })
    );
  };

  const abrirModalEditar = async (producto) => {
    try {
      const productoConImagenes = await obtenerProductoConImagenes(producto.id);

      // Configurar las imágenes procesadas para usar el formato base64 directamente
      const imagenesEditables = productoConImagenes.imagenes.map(
        (imagen, index) => ({
          id: `edit-${productoConImagenes.id}-${index}`, // Genera un ID único
          src: imagen.base64, // Usa la imagen en formato base64 directamente
        })
      );

      setProductoAEditar(productoConImagenes);
      setImagenesProcesadas(imagenesEditables);
      setMostrarModalEditar(true);
    } catch (error) {
      console.error("Error al abrir modal de edición:", error);
      // Manejo adicional del error, posiblemente mostrar un mensaje al usuario
    }
  };

  const confirmarCambioEstado = (producto) => {
    setProductoACambiarEstado(producto);
    setMostrarModalConfirmacion(true);
  };

  const cambiarEstadoProducto = async () => {
    if (!productoACambiarEstado) return;

    const estadoActualizado =
      productoACambiarEstado.estado === "Activo" ? "Inactivo" : "Activo";
    const formData = new FormData();
    formData.append("estado", estadoActualizado);

    try {
      const respuesta = await editarProducto(
        productoACambiarEstado.id,
        formData
      );
      if (
        respuesta &&
        respuesta.message === "Producto actualizado exitosamente"
      ) {
        mostrarMensaje(respuesta.message, "success");
        cargarProductos();
      } else {
        mostrarMensaje("No se pudo cambiar el estado del producto.", "error");
      }
    } catch (error) {
      mostrarMensaje(
        "Error al cambiar el estado del producto. Por favor, inténtelo de nuevo.",
        "error"
      );
    }

    setMostrarModalConfirmacion(false);
    setProductoACambiarEstado(null);
  };

  const handleSubmitEditar = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    for (const key in productoAEditar) {
      if (productoAEditar[key] && key !== "imagenes") {
        // Excluye 'imagenes' del objeto principal
        formData.append(key, productoAEditar[key]);
      }
    }

    // Añade las imágenes procesadas al formData
    imagenesProcesadas.forEach((imagen, index) => {
      if (imagen.src.startsWith("data:image")) {
        // Verifica si la imagen está en formato base64
        formData.append(`imagenes[${index}]`, imagen.src); // Añade la imagen en base64
      } else if (typeof imagen.src === "object" && imagen.src instanceof File) {
        // Si la imagen es un archivo (para el caso de imágenes nuevas añadidas como File)
        formData.append(`imagenes[${index}]`, imagen.src, imagen.src.name);
      }
    });

    const formErrors = validateProductoForm(productoAEditar);
    if (Object.keys(formErrors).length === 0) {
      try {
        const respuesta = await editarProducto(productoAEditar.id, formData);
        if (
          respuesta &&
          respuesta.message === "Producto actualizado exitosamente"
        ) {
          mostrarMensaje(respuesta.message, "success");
          // Actualiza la lista de productos o maneja la respuesta como sea necesario
          setMostrarModalEditar(false);
        } else {
          mostrarMensaje(
            "No se pudo actualizar el producto correctamente.",
            "error"
          );
        }
      } catch (error) {
        let errorMessage = "Error al actualizar el producto. Por favor, inténtelo de nuevo.";
  
        // Verificar si el error incluye una respuesta y extraer el mensaje de error de la misma.
        if (error.response && error.response.data && error.response.data.error) {
          errorMessage = error.response.data.error;
        } else if (error.message) {
          errorMessage = error.message;
        }
        
        mostrarMensaje(errorMessage, "error");
      }
    } else {
      setErrors(formErrors);
    }
  };

  const validateProductoForm = (datosProducto) => {
    let formErrors = {};

    // Validación para el título
    if (!datosProducto.nombre.trim()) {
      formErrors.nombre = "El nombre es requerido";
    } else if (datosProducto.nombre.trim().length > 255) {
      formErrors.titulo = "El nombre debe tener menos de 255 caracteres";
    }

    // Validación para el tamaño
    if (datosProducto.tamanio && datosProducto.tamanio.trim().length > 255) {
      formErrors.tamanio = "El tamaño debe tener menos de 255 caracteres";
    }

    // Validación para la categoría
    if (!datosProducto.Categoriaid) {
      formErrors.Categoriaid = "La categoría es requerida";
    } else if (
      isNaN(datosProducto.Categoriaid) ||
      parseInt(datosProducto.Categoriaid) <= 0
    ) {
      formErrors.Categoriaid = "La categoría seleccionada es inválida";
    }

    // Validación para el material
    if (!datosProducto.Materialid) {
      formErrors.Materialid = "El material es requerido";
    } else if (
      isNaN(datosProducto.Materialid) ||
      parseInt(datosProducto.Materialid) <= 0
    ) {
      formErrors.Materialid = "El material seleccionado es inválido";
    }

    // Validación para la presencia de al menos una imagen
    if (imagenesProcesadas.length === 0) {
      formErrors.imagenes = "Al menos una imagen es requerida.";
    }

    // Validación para el precio
    if (!datosProducto.precio) {
      formErrors.precio = "El precio es requerido";
    } else if (isNaN(datosProducto.precio) || datosProducto.precio <= 0) {
      formErrors.precio = "El precio debe ser un número mayor que cero";
    }

    // Validación para el stock
    if (!datosProducto.stock) {
      formErrors.stock = "El stock es requerido";
    } else if (isNaN(datosProducto.stock) || datosProducto.stock < 0) {
      formErrors.stock = "El stock debe ser un número no negativo";
    }

    return formErrors;
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setProductoAEditar({ ...productoAEditar, [name]: value });
  };

  const manejarImagenesProcesadas = async (imagenProcesada, id) => {
    try {
        const options = {
            maxSizeMB: 1, // Tamaño máximo permitido en megabytes
            maxWidthOrHeight: 1024, // Ancho o altura máximo permitido
            useWebWorker: true, // Usar Web Worker para una mejor eficiencia
        };

        let imagenComprimida;
        if (typeof imagenProcesada === 'string' && imagenProcesada.startsWith('data:')) {
            // Si la imagen procesada es una cadena en formato base64
            imagenComprimida = await compressBase64Image(imagenProcesada, options);
            
        } else {
            // Si la imagen procesada es un Blob
            imagenComprimida = await imageCompression(imagenProcesada, options);
        }

        setImagenesProcesadas((prevImagenes) => {
            const imagenExiste = prevImagenes.some((imagen) => imagen.id === id);
            if (imagenExiste) {
                return prevImagenes.map((imagen) =>
                    imagen.id === id ? { ...imagen, src: imagenComprimida } : imagen
                );
            } else {
                return [...prevImagenes, { id, src: imagenComprimida }];
            }
        });
    } catch (error) {
        console.error("Error al comprimir la imagen:", error);
    }
};


async function compressBase64Image(base64String, maxWidthOrQuality) {
  try {
      // Convertir la cadena base64 en un Blob
      var blob = base64StringToBlob(base64String);

      // Comprimir el Blob utilizando la función existente
      var compressedBlob = await imageCompression(blob, maxWidthOrQuality);

      // Leer el Blob comprimido como una cadena base64
      const compressedBase64 = await readBlobAsBase64(compressedBlob);

      // Devolver la cadena base64 comprimida
      return compressedBase64;
  } catch (error) {
      console.error("Error al comprimir la imagen:", error);
      throw error;
  }
}

function readBlobAsBase64(blob) {
  return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
  });
}

  const manejarCambioDeOrden = async (nuevoOrdenDeImagenes) => {
    // Este código asume que todas las imágenes pueden necesitar ser convertidas de blob a Base64.
    // Si las imágenes ya están en Base64, simplemente se mantienen como están.
    const imagenesConvertidas = await Promise.all(
      nuevoOrdenDeImagenes.map(async (imagen) => {
        if (!imagen.src.startsWith("blob:")) {
          return imagen; // La imagen ya está en Base64
        }
        const srcBase64 = await blobToBase64(imagen.src);
        return { ...imagen, src: srcBase64 };
      })
    );
    setImagenesProcesadas(imagenesConvertidas);
  };

  function blobToBase64(blobUrl) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      fetch(blobUrl) // Fetch the blob from the blob URL
        .then((res) => res.blob())
        .then((blob) => {
          reader.readAsDataURL(blob); // Read the blob as DataURL
          reader.onloadend = function () {
            resolve(reader.result); // Return the result
          };
          reader.onerror = reject;
        })
        .catch(reject);
    });
  }

  function base64StringToBlob(base64String) {
    var parts = base64String.split(';base64,');
    var contentType = parts[0].split(':')[1];
    var raw = window.atob(parts[1]);
    var rawLength = raw.length;
    var uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
}

  const manejarEliminacionDeImagen = (imageId) => {
    

    // Filtra directamente las imágenes procesadas para eliminar la imagen con el id específico
    const imagenesActualizadas = imagenesProcesadas.filter(
      (imagen) => imagen.id !== imageId
    );

    setImagenesProcesadas(imagenesActualizadas);

    
  };

  // Funciones para cambiar de página
  const irAPaginaSiguiente = () => {
    setPaginaActual((paginaActual) => Math.min(paginaActual + 1, totalPaginas));
  };

  const irAPaginaAnterior = () => {
    setPaginaActual((paginaActual) => Math.max(paginaActual - 1, 1));
  };

  // Calcular los productos a mostrar en la página actual
  const indiceUltimoProducto = paginaActual * productosPorPagina;
  const indicePrimerProducto = indiceUltimoProducto - productosPorPagina;
  const productosActuales = productos.slice(
    indicePrimerProducto,
    indiceUltimoProducto
  );

  return (
    <div className="container mx-auto p-4">
      <div className="bg-white p-4 rounded shadow-md">
        {showMessage && (
          <div
            className={`mb-4 p-2 border rounded flex justify-between items-center ${
              messageType === "error"
                ? "bg-red-100 border-red-400 text-red-700"
                : "bg-green-100 border-green-400 text-green-700"
            }`}
          >
            <span>{message}</span>
            <button
              onClick={() => setShowMessage(false)}
              className="text-green-700 hover:text-green-500"
            >
              &#10005;
            </button>
          </div>
        )}
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold mb-4">Productos</h2>
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={() => setMostrarModalAgregar(true)}
          >
            Agregar Producto
          </button>
        </div>
        {productosActuales.map((producto) => (
          <div key={producto.id} className="border-b border-gray-200 py-4">
            <div className="flex justify-between items-center">
              <h3
                className="text-lg font-semibold cursor-pointer"
                onClick={() => toggleDetalles(producto.id)}
              >
                {producto.nombre}
              </h3>
              <div>
                <button
                  className="mr-2 px-3 py-1 bg-blue-500 text-white rounded"
                  onClick={() => abrirModalEditar(producto)}
                >
                  Editar
                </button>
                {producto.estado === "Activo" ? (
                  <button
                    onClick={() => confirmarCambioEstado(producto)}
                    className="px-3 py-1 bg-red-500 text-white rounded"
                  >
                    Deshabilitar
                  </button>
                ) : (
                  <button
                    onClick={() => confirmarCambioEstado(producto)}
                    className="mr-2 px-3 py-1 bg-green-500 text-white rounded"
                  >
                    Habilitar
                  </button>
                )}
              </div>
            </div>
            {producto.abierto && (
              <div className="mt-2 text-gray-600">
                <p>
                  <strong>Descripción:</strong> {producto.descripcion}
                </p>
                <p>
                  <strong>Precio:</strong> $
                  {producto.precio
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                </p>
                <p>
                  <strong>Stock:</strong>{" "}
                  {producto.stock
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                </p>
                <p>
                  <strong>Estado:</strong> {producto.estado}
                </p>
                <p>
                  <strong>Material:</strong> {producto.material.nombre}
                </p>
                <p>
                  <strong>Categoria:</strong> {producto.categoria.nombre}
                </p>
                <p>
                  <strong>Tamaño:</strong> {producto.tamanio}
                </p>

                {producto.imagenes && producto.imagenes.length > 0 && (
                  <div className="mt-2 max-w-xs overflow-hidden">
                    <div className="flex gap-2 snap-x snap-mandatory overflow-auto py-2">
                      {producto.imagenes.map((imagen, index) => (
                        <div key={index} className="snap-start shrink-0">
                          <img
                            src={API_BASE_URL + imagen.uri_foto}
                            alt={`Imagen de ${producto.nombre}`}
                            className="w-48 h-48 object-cover"
                          />
                        </div>
                      ))}
                    </div>
                    {producto.imagenes.length > 1 && (
                      <p className="mt-1 text-sm text-center text-gray-500">
                        Desliza para ver más imágenes.
                      </p>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
        <div className="flex justify-between items-center mt-8">
          <button
            onClick={irAPaginaAnterior}
            disabled={paginaActual === 1}
            className={`px-4 py-2 rounded-lg text-white ${
              paginaActual === 1
                ? "bg-gray-500"
                : "bg-blue-500 hover:bg-blue-700"
            } transition-colors duration-150`}
          >
            Anterior
          </button>
          <span className="text-lg font-semibold">
            Página {paginaActual} de {totalPaginas}
          </span>
          <button
            onClick={irAPaginaSiguiente}
            disabled={paginaActual === totalPaginas}
            className={`px-4 py-2 rounded-lg text-white ${
              paginaActual === totalPaginas
                ? "bg-gray-500"
                : "bg-blue-500 hover:bg-blue-700"
            } transition-colors duration-150`}
          >
            Siguiente
          </button>
        </div>
      </div>
      {/* Modal para agregar producto */}
      {mostrarModalAgregar && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 flex justify-center items-center overflow-y-auto">
          <div className="bg-white p-6 rounded-lg shadow-xl w-3/4 md:w-1/2 lg:w-1/3 max-h-[80vh] overflow-y-auto rounded-t-lg">
            <form onSubmit={handleSubmitAgregar} className="space-y-4">
              {/* Título */}
              <div className="mb-4">
                <label
                  htmlFor="nombre"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Título:
                </label>
                <input
                  type="text"
                  name="nombre"
                  id="nombre"
                  value={nuevoProducto.nombre}
                  onChange={handleInputChange}
                  placeholder="Introduce el título"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {errors.nombre && (
                  <p className="text-red-500 text-sm">{errors.nombre}</p>
                )}
              </div>

              {/* Descripción */}
              <div className="mb-4">
                <label
                  htmlFor="descripcion"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Descripción:
                </label>
                <textarea
                  name="descripcion"
                  id="descripcion"
                  value={nuevoProducto.descripcion}
                  onChange={handleInputChange}
                  placeholder="Breve descripción del producto"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                />
                {errors.descripcion && (
                  <p className="text-red-500 text-sm">{errors.descripcion}</p>
                )}
              </div>

              {/* Precio */}
              <div className="mb-4">
                <label
                  htmlFor="precio"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Precio:
                </label>
                <input
                  type="number"
                  name="precio"
                  id="precio"
                  value={nuevoProducto.precio}
                  onChange={handleInputChange}
                  placeholder="Precio del producto"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {errors.precio && (
                  <p className="text-red-500 text-sm">{errors.precio}</p>
                )}
              </div>

              {/* Stock */}
              <div className="mb-4">
                <label
                  htmlFor="stock"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Stock:
                </label>
                <input
                  type="number"
                  name="stock"
                  id="stock"
                  value={nuevoProducto.stock}
                  onChange={handleInputChange}
                  placeholder="Cantidad en stock"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {errors.stock && (
                  <p className="text-red-500 text-sm">{errors.stock}</p>
                )}
              </div>

              {/* Categoría */}
              <div className="mb-4">
                <label
                  htmlFor="categoria"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Categoría:
                </label>
                <select
                  name="Categoriaid"
                  id="categoria"
                  value={nuevoProducto.Categoriaid}
                  onChange={handleInputChange}
                  className="shadow border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value="">Seleccione una categoría</option>
                  {categorias.map((categoria) => (
                    <option key={categoria.id} value={categoria.id}>
                      {categoria.nombre}
                    </option>
                  ))}
                </select>
                {errors.Categoriaid && (
                  <p className="text-red-500 text-sm">{errors.Categoriaid}</p>
                )}
              </div>

              {/* Material */}
              <div className="mb-4">
                <label
                  htmlFor="material"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Material:
                </label>
                <select
                  name="Materialid"
                  id="material"
                  value={nuevoProducto.Materialid}
                  onChange={handleInputChange}
                  className="shadow border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value="">Seleccione un material</option>
                  {materiales.map((material) => (
                    <option key={material.id} value={material.id}>
                      {material.nombre}
                    </option>
                  ))}
                </select>
                {errors.Materialid && (
                  <p className="text-red-500 text-sm">{errors.Materialid}</p>
                )}
              </div>

              {/* Tamaño */}
              <div className="mb-4">
                <label
                  htmlFor="tamanio"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Tamaño:
                </label>
                <input
                  type="text"
                  name="tamanio"
                  id="tamanio"
                  value={nuevoProducto.tamanio}
                  onChange={handleInputChange}
                  placeholder="Dimensiones del trabajo"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {errors.tamanio && (
                  <p className="text-red-500 text-sm">{errors.tamanio}</p>
                )}
              </div>

              <div className="App mt-4">
                <p className="text-center text-lg font-semibold mb-2">
                  Imágenes
                </p>
                <div className="p-4 border border-gray-200 rounded-lg">
                  <ImageCropper
                    key={resetEditorKey}
                    onImagesProcessed={manejarImagenesProcesadas}
                    onOrderChange={manejarCambioDeOrden}
                    onImageRemove={manejarEliminacionDeImagen}
                  />
                </div>
                {errors.imagenes && (
                  <p className="text-red-500 text-sm">{errors.imagenes}</p>
                )}
              </div>

              {/* Botones del formulario, invertidos */}
              <div className="flex items-center justify-between mt-4">
                <button
                  type="button"
                  onClick={() => setMostrarModalAgregar(false)}
                  className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-6 rounded transition ease-in-out duration-150"
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded transition ease-in-out duration-150"
                >
                  Agregar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {mostrarModalEditar && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 flex justify-center items-center overflow-y-auto">
          <div className="bg-white p-6 rounded-lg shadow-xl w-3/4 md:w-1/2 lg:w-1/3 max-h-[80vh] overflow-y-auto rounded-t-lg">
            <form onSubmit={(e) => handleSubmitEditar(e)} className="space-y-4">
              {/* Título */}
              <div className="mb-4">
                <label
                  htmlFor="productoEditar"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Título:
                </label>
                <input
                  type="text"
                  name="nombre"
                  id="productoEditar"
                  value={productoAEditar.nombre}
                  onChange={handleEditInputChange}
                  placeholder="Introduce el título"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {errors.producto && (
                  <p className="text-red-500 text-sm">{errors.producto}</p>
                )}
              </div>

              {/* Descripción */}
              <div className="mb-4">
                <label
                  htmlFor="descripcionEditar"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Descripción:
                </label>
                <textarea
                  name="descripcion"
                  id="descripcionEditar"
                  value={productoAEditar.descripcion || ""}
                  onChange={handleEditInputChange}
                  placeholder="Breve descripción del producto"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                />
                {errors.descripcion && (
                  <p className="text-red-500 text-sm">{errors.descripcion}</p>
                )}
              </div>

              {/* Precio */}
              <div className="mb-4">
                <label
                  htmlFor="precio"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Precio:
                </label>
                <input
                  type="number"
                  name="precio"
                  id="precio"
                  value={productoAEditar.precio}
                  onChange={handleEditInputChange}
                  placeholder="Precio del producto"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {errors.precio && (
                  <p className="text-red-500 text-sm">{errors.precio}</p>
                )}
              </div>

              {/* Stock */}
              <div className="mb-4">
                <label
                  htmlFor="stock"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Stock:
                </label>
                <input
                  type="number"
                  name="stock"
                  id="stock"
                  value={productoAEditar.stock}
                  onChange={handleEditInputChange}
                  placeholder="Cantidad en stock"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {errors.stock && (
                  <p className="text-red-500 text-sm">{errors.stock}</p>
                )}
              </div>

              {/* Tamaño */}
              <div className="mb-4">
                <label
                  htmlFor="tamanioEditar"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Tamaño:
                </label>
                <input
                  type="text"
                  name="tamanio"
                  id="tamanioEditar"
                  value={productoAEditar.tamanio}
                  onChange={handleEditInputChange}
                  placeholder="Dimensiones del trabajo"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {errors.tamanio && (
                  <p className="text-red-500 text-sm">{errors.tamanio}</p>
                )}
              </div>

              {/* Categoría */}
              <div className="mb-4">
                <label
                  htmlFor="categoriaEditar"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Categoría:
                </label>
                <select
                  name="Categoriaid"
                  id="categoriaEditar"
                  value={productoAEditar.Categoriaid}
                  onChange={handleEditInputChange}
                  className="shadow border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value="">Seleccione una categoría</option>
                  {categorias.map((categoria) => (
                    <option key={categoria.id} value={categoria.id}>
                      {categoria.nombre}
                    </option>
                  ))}
                </select>
                {errors.Categoriaid && (
                  <p className="text-red-500 text-sm">{errors.Categoriaid}</p>
                )}
              </div>

              {/* Material */}
              <div className="mb-4">
                <label
                  htmlFor="materialEditar"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Material:
                </label>
                <select
                  name="Materialid"
                  id="materialEditar"
                  value={productoAEditar.Materialid}
                  onChange={handleEditInputChange}
                  className="shadow border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value="">Seleccione un material</option>
                  {materiales.map((material) => (
                    <option key={material.id} value={material.id}>
                      {material.nombre}
                    </option>
                  ))}
                </select>
                {errors.Materialid && (
                  <p className="text-red-500 text-sm">{errors.Materialid}</p>
                )}
              </div>

              {/* Imagen */}
              <div className="App mt-4">
                <p className="text-center text-lg font-semibold mb-2">
                  Imágenes
                </p>
                <div className="p-4 border border-gray-200 rounded-lg">
                  <ImageCropperEdit
                    key={`editar-${productoAEditar?.id}`} // Asegúrate de que la key cambia para forzar la re-renderización.
                    imagenesExistentes={imagenesProcesadas}
                    onImagesProcessed={manejarImagenesProcesadas}
                    onOrderChange={manejarCambioDeOrden}
                    onImageRemove={manejarEliminacionDeImagen}
                  />
                </div>
                {errors.imagenes && (
                  <p className="text-red-500 text-sm">{errors.imagenes}</p>
                )}
              </div>

              {/* Botones del formulario */}
              <div className="flex items-center justify-between">
                <button
                  type="button"
                  onClick={() => setMostrarModalEditar(false)}
                  className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Guardar Cambios
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {mostrarModalConfirmacion && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 flex justify-center items-center">
          <div className="bg-white p-8 rounded-lg shadow-xl max-w-md mx-auto">
            <h3 className="mb-4 text-lg font-semibold text-center">
              ¿Estás seguro de que quieres{" "}
              {productoACambiarEstado?.estado === "Activo"
                ? "deshabilitar"
                : "habilitar"}{" "}
              este producto?
            </h3>
            <div className="flex justify-center gap-4">
              <button
                onClick={() => setMostrarModalConfirmacion(false)}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-6 rounded transition ease-in-out duration-150"
              >
                Cancelar
              </button>
              <button
                onClick={cambiarEstadoProducto}
                className={`${
                  productoACambiarEstado?.estado === "Activo"
                    ? "bg-red-500 hover:bg-red-700"
                    : "bg-green-500 hover:bg-green-700"
                } text-white font-bold py-2 px-6 rounded transition ease-in-out duration-150`}
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Productos;
