import { API_BASE_URL } from "./Config";
const BASE_URL = API_BASE_URL+'api/';
async function fetchData(endpoint, options = {}) {
  try {
      const response = await fetch(`${BASE_URL}${endpoint}`, options);
      const data = await response.json();
      if (!response.ok) {
          // Se incluye más información en el mensaje de error
          const errorMessage = data.message || 'Error en la solicitud';
          throw new Error(JSON.stringify({ status: response.status, message: errorMessage, errors: data.errors }));
      }
      return data;
  } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
  }
}

// Solicitud de contacto

export async function obtenerSolicitudesDeContacto() {
  try {
      const token = localStorage.getItem('auth_token');
      if (!token) {
          throw new Error('No hay token de autenticación disponible');
      }

      const response = await fetch(`${BASE_URL}solicitud-contacto`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
          }
      });

      if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
      }

      const solicitudes = await response.json();
      return solicitudes;
  } catch (error) {
      console.error('Error al obtener solicitudes de contacto:', error);
      throw error;
  }
}

export async function enviarSolicitudContacto(datos) {
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(datos),
    };
    return await fetchData('solicitud-contacto', options);
}

// Usuario

export async function registrarUsuario(datos) {
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(datos),
    };
    return await fetchData('register', options);
}

export async function iniciarSesion(datos) {
  const options = {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(datos),
  };
  return await fetchData('login', options);
}

export async function cerrarSesion(token) {
    const options = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    };
    return await fetchData('logout', options);
}

export async function solicitarRestablecimientoContrasena(datos) {
  const options = {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(datos),
  };
  return await fetchData('forgot-password', options);
}

export async function restablecerContrasena(datos) {
  const options = {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(datos),
  };
  return await fetchData('reset-password', options);
}

export async function obtenerUsuario() {
  const token = localStorage.getItem('auth_token');
  if (!token) {
      return false;
  }
  const options = {
      method: 'GET',
      headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
      },
  };
  return await fetchData('show-user', options);
}

export async function verificarToken() {
  const token = localStorage.getItem('auth_token');
  if (!token) {
      return false;
  }

  const options = {
      method: 'GET',
      headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
      },
  };

  try {
      const data = await fetchData('verify-token', options);
      if (data.user) {
          // Si el backend confirma que el token es válido
          localStorage.setItem('auth_user', JSON.stringify(data.user));
          localStorage.setItem('auth_user_rol', data.user.rol);
          // Aquí puedes actualizar cualquier otro dato relevante del usuario
          return true;
      }
  } catch (error) {
      console.error('Error al verificar el token:', error);
      localStorage.removeItem('auth_token'); // Elimina el token si no es válido
      // Aquí también debes eliminar cualquier otra información del usuario que tengas almacenada
      localStorage.removeItem('auth_user');
      localStorage.removeItem('auth_user_rol');
      return false;
  }
}

export async function obtenerUsuarios() {
  try {
    const token = localStorage.getItem('auth_token');
    if (!token) {
      throw new Error('No hay token de autenticación disponible');
    }

    const options = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    };

    return await fetchData('user', options);
  } catch (error) {
    console.error('Error al obtener usuarios:', error);
    throw error;
  }
}

export async function modificarUsuario(id, datosModificados) {
  try {
    const token = localStorage.getItem('auth_token');
    if (!token) {
      throw new Error('No hay token de autenticación disponible');
    }

    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(datosModificados)
    };

    return await fetchData(`user/${id}`, options);
  } catch (error) {
    console.error(`Error al modificar el usuario con ID ${id}:`, error);
    throw error;
  }
}

export async function modificarUsuarioPorToken(datosModificados) {
  try {
    const token = localStorage.getItem('auth_token');
    if (!token) {
      throw new Error('No hay token de autenticación disponible');
    }

    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(datosModificados)
    };

    return await fetchData('user/update', options);
  } catch (error) {
    console.error(`Error al modificar el usuario:`, error);
    throw error;
  }
}

// Preguntas frecuentes

export async function agregarPreguntaFrecuente(datos) {
    const token = localStorage.getItem('auth_token');
    if (!token) {
      throw new Error('No hay token de autenticación disponible');
    }
  
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(datos)
    };
  
    return await fetchData('preguntas', options);
  }

  export async function editarPreguntaFrecuente(datos, id) {
    const token = localStorage.getItem('auth_token');
    if (!token) {
      throw new Error('No hay token de autenticación disponible');
    }
  
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(datos)
    };
  
    return await fetchData(`preguntas/${id}`, options); // Corrección aquí
}

export async function obtenerPreguntasFrecuentes() {
    try {
        const token = localStorage.getItem('auth_token');
        if (!token) {
            throw new Error('No hay token de autenticación disponible');
        }

        const response = await fetch(`${BASE_URL}preguntas`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const preguntas = await response.json();
        return preguntas;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

export async function obtenerPreguntasFrecuentesActivas() {
  try {
      const response = await fetch(`${BASE_URL}preguntasActivas`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
          }
      });

      if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
      }

      const preguntas = await response.json();
      return preguntas;
  } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
  }
}

export async function eliminarPreguntaFrecuente(id) {
    try {
        const token = localStorage.getItem('auth_token');
        if (!token) {
            throw new Error('No hay token de autenticación disponible');
        }

        const response = await fetch(`${BASE_URL}preguntas/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Error al eliminar pregunta frecuente:', error);
        throw error;
    }
}

// Categorias

export async function obtenerCategorias() {
    try {
      const token = localStorage.getItem('auth_token');
      if (!token) {
        throw new Error('No hay token de autenticación disponible');
      }
  
      const response = await fetch(`${BASE_URL}categorias`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const categorias = await response.json();
      return categorias;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }

  export async function obtenerCategoriasActivas() {
    try {  
      const response = await fetch(`${BASE_URL}categoriasActivas`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const categorias = await response.json();
      return categorias;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }

  export async function agregarCategoria(datos) {
    const token = localStorage.getItem('auth_token');
    if (!token) {
      throw new Error('No hay token de autenticación disponible');
    }
  
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(datos)
    };
  
    return await fetchData('categorias', options);
  }

  export async function editarCategoria(datos, id) {
    const token = localStorage.getItem('auth_token');
    if (!token) {
      throw new Error('No hay token de autenticación disponible');
    }
  
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(datos)
    };
  
    return await fetchData(`categorias/${id}`, options);
  }

  // Material

export async function obtenerMateriales() {
  try {
    const token = localStorage.getItem('auth_token');
    if (!token) {
      throw new Error('No hay token de autenticación disponible');
    }

    const response = await fetch(`${BASE_URL}materiales`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const materiales = await response.json();
    return materiales;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

export async function obtenerMaterialesActivos() {
  try {
    const response = await fetch(`${BASE_URL}materialesActivos`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const materiales = await response.json();
    return materiales;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

export async function agregarMateriales(datos) {
  const token = localStorage.getItem('auth_token');
  if (!token) {
    throw new Error('No hay token de autenticación disponible');
  }

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(datos)
  };

  return await fetchData('materiales', options);
}

export async function editarMateriales(datos, id) {
  
  const token = localStorage.getItem('auth_token');
  if (!token) {
    throw new Error('No hay token de autenticación disponible');
  }

  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(datos)
  };

  return await fetchData(`materiales/${id}`, options);
}
  
  //Portafolios

  export async function agregarPortafolio(datos) {
    const token = localStorage.getItem('auth_token');
    if (!token) {
      throw new Error('No hay token de autenticación disponible');
    }

    const options = {
      method: 'POST',
      headers: {
        // No se establece 'Content-Type': 'application/json' porque se está enviando FormData
        'Authorization': `Bearer ${token}`
      },
      body: datos
    };

    return await fetchData('portafolios', options);
}

export async function editarPortafolios(id, datos) {
  const token = localStorage.getItem('auth_token');
  if (!token) {
    throw new Error('No hay token de autenticación disponible');
  }

  const options = {
    method: 'POST',
    headers: {
      // Asegúrate de que no se establezca el 'Content-Type' manualmente
      'Authorization': `Bearer ${token}`
    },
    body: datos
  };

  return await fetchData(`portafolioEditar/${id}`, options);
}

export async function obtenerPortafolios() {
  try {
      const token = localStorage.getItem('auth_token');
      if (!token) {
          throw new Error('No hay token de autenticación disponible');
      }

      const options = {
          method: 'GET',
          headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
          }
      };

      return await fetchData('portafolios', options);
  } catch (error) {
      console.error('Error al obtener portafolios:', error);
      throw error;
  }
}

export async function obtenerPortafoliosActivos() {
  try {
      const options = {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
          }
      };

      return await fetchData('portafoliosActivos', options);
  } catch (error) {
      console.error('Error al obtener portafolios:', error);
      throw error;
  }
}

//Productos

export async function obtenerProductos() {
  try {
      const token = localStorage.getItem('auth_token');
      if (!token) {
          throw new Error('No hay token de autenticación disponible');
      }

      const options = {
          method: 'GET',
          headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
          }
      };

      return await fetchData('productos', options);
  } catch (error) {
      console.error('Error al obtener productos:', error);
      throw error;
  }
}

// Actualiza esta función para aceptar parámetros adicionales
export async function obtenerProductosActivos(paginaActual, filtroCategoria = 'todos', filtroMaterial = 'todos') {
  const endpoint = `productosActivos/${paginaActual}/${filtroCategoria}/${filtroMaterial}`;
  const options = {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
      }
  };

  return await fetchData(endpoint, options);
}


export async function obtenerProductoPorNombreYPublicId(nombre, publicId) {
  try {
      const options = {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
          }
      };

      // Ajusta la URL para incluir el nombre y publicId
      return await fetchData(`productos/${encodeURIComponent(nombre)}/${encodeURIComponent(publicId)}`, options);

  } catch (error) {
      console.error('Error al obtener producto:', error);
      throw error;
  }
}

// En tu componente de servicios
export async function obtenerProductoConImagenes(id) {
  try {
      const token = localStorage.getItem('auth_token');
      if (!token) {
          throw new Error('No hay token de autenticación disponible');
      }

      const options = {
          method: 'GET',
          headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
          }
      };

      // Asume que la ruta para obtener un producto por ID y sus imágenes es 'producto/{id}/imagenes'
      return await fetchData(`producto/${id}/imagenes`, options);
  } catch (error) {
      console.error('Error al obtener el producto con imágenes:', error);
      throw error;
  }
}




export async function agregarProducto(datos) {
  const token = localStorage.getItem('auth_token');
  if (!token) {
      throw new Error('No hay token de autenticación disponible');
  }

  const options = {
      method: 'POST',
      headers: {
          // No se establece 'Content-Type': 'application/json' porque se está enviando FormData
          'Authorization': `Bearer ${token}`
      },
      body: datos
  };

  return await fetchData('productos', options);
}

export async function editarProducto(id, datos) {
  const token = localStorage.getItem('auth_token');
  if (!token) {
      throw new Error('No hay token de autenticación disponible');
  }

  const options = {
      method: 'POST',
      headers: {
          'Authorization': `Bearer ${token}`
      },
      body: datos
  };

  return await fetchData(`productoEditar/${id}`, options);
}

export async function verificarStockCarrito(carrito) {
  try {
      

      const options = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({ carrito })
      };

      const response = await fetchData('verificar-reservar-stock', options);
      return await response;
  } catch (error) {
      console.error('Error al verificar el stock del carrito:', error);
      throw error;
  }
}

export async function verificarStockProducto(productoId, cantidad) {
  try {
  
  const options = {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          
      },
      body: JSON.stringify({ productoId, cantidad })
  };

  return await fetchData('verificar-stock-producto', options);
} catch (error) {
  console.error('Error al verificar el stock del producto:', error);
  throw error;
}
}



//Pago

export async function realizarPago(datos, carrito) {
  try {
    const token = localStorage.getItem("auth_token");
    if (!token) {
      console.error("No hay token de autenticación disponible");
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({datos: datos, carrito: carrito } )
      };
  
      const data = await fetchData("procesar-pago-no-auth", options);
      
      return data; // Devuelve la respuesta del servidor
    }

    const options = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({datos: datos, carrito: carrito } )
    };

    const data = await fetchData("procesar-pago", options);
    
    return data; // Devuelve la respuesta del servidor
  } catch (error) {
    console.error("Error al realizar el pago:", error);
    throw error;
  }
};

export async function confirmarPago(tokenWs, TBK_TOKEN, carrito, numeroOrden) {
  try {
    
    
    const options = {
        method: 'POST',
        headers: {
          
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ tokenWs: tokenWs, TBK_TOKEN: TBK_TOKEN, carrito: carrito, numeroOrden: numeroOrden })
    };

    const response = await fetchData('confirmar-pago', options);
    return await response;
  } catch (error) {
    console.error('Error al confirmar el pago:', error);
    throw error;
  }
}

//Transacción

export async function obtenerTransacciones() {
  try {
    const token = localStorage.getItem('auth_token');
    if (!token) {
      throw new Error('No hay token de autenticación disponible');
    }

    const options = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    };

    return await fetchData('transacciones-all', options);
  } catch (error) {
    console.error('Error al obtener transacción:', error);
    throw error;
  }
}

//Carrusel

export async function editarCarrusel(id, datos) {
  const token = localStorage.getItem('auth_token');
  if (!token) {
      throw new Error('No hay token de autenticación disponible');
  }

  // Asegúrate de que los datos se envían como string JSON
  const bodyData = JSON.stringify(datos);

  const options = {
      method: 'POST', // Utiliza POST como método HTTP
      headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json' // Asegúrate de incluir este header
      },
      body: bodyData
  };

  // Ajusta la URL del endpoint para reflejar el nuevo path
  // Asegúrate de que el endpoint 'carrusel/{id}' es el correcto según tu backend
  return await fetchData(`carrusel/${id}`, options);
}

export async function obtenerCarrusel(nombreCarrusel) {

  const options = {
      method: 'GET',
      
  };

  // Ajusta la URL del endpoint para reflejar el nuevo path
  // Asegúrate de que el endpoint 'carrusel/{id}' es el correcto según tu backend
  return await fetchData(`obtener-carrusel/${nombreCarrusel}`, options);
}

export async function obtenerCarruselImagenes() {
  const token = localStorage.getItem('auth_token');
  if (!token) {
      throw new Error('No hay token de autenticación disponible');
  }

  const options = {
      method: 'GET', // Utiliza POST como método HTTP
      headers: {
          'Authorization': `Bearer ${token}`,
          
      },
      
  };

  // Ajusta la URL del endpoint para reflejar el nuevo path
  // Asegúrate de que el endpoint 'carrusel/{id}' es el correcto según tu backend
  return await fetchData('obtener-carrusel-imagenes', options);
}