import React, { useState, useEffect } from "react";
import {
  obtenerPreguntasFrecuentes,
  agregarPreguntaFrecuente,
  eliminarPreguntaFrecuente,
  editarPreguntaFrecuente,
} from "../../services/Api";

const PreguntasFrecuentes = () => {
  const [preguntas, setPreguntas] = useState([]);
  const [nuevaPregunta, setNuevaPregunta] = useState({
    titulo: "",
    descripcion: "",
  });
  const [mostrarModalAgregar, setMostrarModalAgregar] = useState(false);
  const [mostrarModalEditar, setMostrarModalEditar] = useState(false);
  const [preguntaAEditar, setPreguntaAEditar] = useState(null);
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [
    mostrarModalConfirmacionEliminar,
    setMostrarModalConfirmacionEliminar,
  ] = useState(false);
  const [preguntaAEliminar, setPreguntaAEliminar] = useState(null);
  const [paginaActual, setPaginaActual] = useState(1);
  const preguntasPorPagina = 10;

  useEffect(() => {
    const cargarPreguntas = async () => {
      try {
        const preguntasObtenidas = await obtenerPreguntasFrecuentes();
        setPreguntas(preguntasObtenidas);
      } catch (error) {
        console.error("Error al obtener preguntas:", error);
      }
    };

    cargarPreguntas();
  }, []);

  useEffect(() => {
    let timerId;
    if (showMessage) {
      // Establecer el temporizador para ocultar el mensaje después de 5 segundos
      timerId = setTimeout(() => {
        setShowMessage(false);
      }, 5000);
    }
    // Función de limpieza para borrar el temporizador
    return () => clearTimeout(timerId);
  }, [showMessage]);

  const toggleRespuesta = (id) => {
    setPreguntas(
      preguntas.map((pregunta) => {
        if (pregunta.id === id) {
          return { ...pregunta, abierta: !pregunta.abierta };
        }
        return pregunta;
      })
    );
  };

  const handleInputChange = (e) => {
    setNuevaPregunta({ ...nuevaPregunta, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    let formErrors = {};
    if (!nuevaPregunta.titulo.trim())
      formErrors.titulo = "El título es requerido";
    if (!nuevaPregunta.descripcion.trim())
      formErrors.descripcion = "La descripción es requerida";
    return formErrors;
  };

  const handleSubmitAgregar = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      try {
        const respuesta = await agregarPreguntaFrecuente(nuevaPregunta);
        if (respuesta && respuesta.message === "Pregunta creada exitosamente") {
          // La pregunta se agregó con éxito
          setMessage(respuesta.message); // Mensaje de éxito del servidor
          setShowMessage(true);
          const preguntasObtenidas = await obtenerPreguntasFrecuentes();
          setPreguntas(preguntasObtenidas); // Actualiza la lista de preguntas
        } else {
          // No se recibió el mensaje esperado
          setMessage("No se pudo agregar la pregunta correctamente.");
          setShowMessage(true);
        }
        setNuevaPregunta({ titulo: "", descripcion: "" });
        setMostrarModalAgregar(false);
      } catch (error) {
        console.error("Error al agregar pregunta frecuente:", error);
        setMessage(
          "Error al agregar la pregunta. Por favor, inténtelo de nuevo."
        );
        setShowMessage(true);
      }
    } else {
      setErrors(formErrors);
    }
  };

  const handleSubmitEditar = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      try {
        const respuesta = await editarPreguntaFrecuente(
          nuevaPregunta,
          preguntaAEditar.id
        );
        if (
          respuesta &&
          respuesta.message === "Pregunta actualizada exitosamente"
        ) {
          // La pregunta se editó con éxito
          setMessage(respuesta.message); // Mensaje de éxito del servidor
          setShowMessage(true);
          const preguntasObtenidas = await obtenerPreguntasFrecuentes();
          setPreguntas(preguntasObtenidas); // Actualiza la lista de preguntas
        } else {
          // No se recibió el mensaje esperado
          setMessage("No se pudo editar la pregunta correctamente.");
          setShowMessage(true);
        }
        setNuevaPregunta({ titulo: "", descripcion: "" });
        setPreguntaAEditar(null);
        setMostrarModalEditar(false);
      } catch (error) {
        console.error("Error al editar pregunta frecuente:", error);
        setMessage(
          "Error al editar la pregunta. Por favor, inténtelo de nuevo."
        );
        setShowMessage(true);
      }
    } else {
      setErrors(formErrors);
    }
  };

  const eliminarPregunta = async (id) => {
    try {
      await eliminarPreguntaFrecuente(id);
      setPreguntas(preguntas.filter((pregunta) => pregunta.id !== id));
      setMessage("Pregunta eliminada con éxito.");
      setShowMessage(true);
    } catch (error) {
      console.error("Error al eliminar pregunta:", error);
      setMessage(
        "Error al eliminar la pregunta. Por favor, inténtelo de nuevo."
      );
      setShowMessage(true);
    }
  };

  const abrirModalEditar = (pregunta) => {
    setNuevaPregunta({
      titulo: pregunta.titulo,
      descripcion: pregunta.descripcion,
    });
    setPreguntaAEditar(pregunta);
    setMostrarModalEditar(true);
  };

  const confirmarEliminacion = (pregunta) => {
    setPreguntaAEliminar(pregunta);
    setMostrarModalConfirmacionEliminar(true);
  };

  const eliminarPreguntaConfirmada = async () => {
    if (preguntaAEliminar) {
      await eliminarPregunta(preguntaAEliminar.id);
      setPreguntas(preguntas.filter((p) => p.id !== preguntaAEliminar.id));
      setMostrarModalConfirmacionEliminar(false);
      setPreguntaAEliminar(null);
      setMessage("Pregunta eliminada con éxito.");
      setShowMessage(true);
    }
  };

  const paginaSiguiente = () => {
    setPaginaActual((paginaActual) => Math.min(paginaActual + 1, totalPaginas));
  };

  const paginaAnterior = () => {
    setPaginaActual((paginaActual) => Math.max(paginaActual - 1, 1));
  };

  const indiceUltimo = paginaActual * preguntasPorPagina;
  const indicePrimero = indiceUltimo - preguntasPorPagina;
  const preguntasActuales = preguntas.slice(indicePrimero, indiceUltimo);
  const totalPaginas = Math.ceil(preguntas.length / preguntasPorPagina);

  return (
    <div className="container mx-auto p-4">
      <div className="bg-white p-4 rounded shadow-md">
        {showMessage && (
          <div className="mb-4 p-2 border border-green-400 bg-green-100 rounded flex justify-between items-center">
            <span className="text-green-700">{message}</span>
            <button
              onClick={() => setShowMessage(false)}
              className="text-green-700 hover:text-green-500"
            >
              &#10005;
            </button>
          </div>
        )}
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Preguntas Frecuentes</h2>
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={() => setMostrarModalAgregar(true)}
          >
            Agregar Pregunta
          </button>
        </div>
        {preguntasActuales.map((pregunta) => (
          <div key={pregunta.id} className="border-b border-gray-200 py-4">
            <div className="flex justify-between items-center">
              <h3
                className="text-lg font-semibold cursor-pointer"
                onClick={() => toggleRespuesta(pregunta.id)}
              >
                {pregunta.titulo}
              </h3>
              <div>
                <button
                  className="mr-2 px-3 py-1 bg-blue-500 text-white rounded"
                  onClick={() => abrirModalEditar(pregunta)}
                >
                  Editar
                </button>
                <button
                  className="px-3 py-1 bg-red-500 text-white rounded"
                  onClick={() => confirmarEliminacion(pregunta)}
                >
                  Eliminar
                </button>
              </div>
            </div>
            {pregunta.abierta && (
              <p className="mt-2 text-gray-600">{pregunta.descripcion}</p>
            )}
          </div>
        ))}
        <div className="flex justify-between items-center mt-8">
          <button
            onClick={paginaAnterior}
            disabled={paginaActual === 1}
            className={`px-4 py-2 rounded-lg text-white ${
              paginaActual === 1
                ? "bg-gray-500"
                : "bg-blue-500 hover:bg-blue-700"
            } transition-colors duration-150`}
          >
            Anterior
          </button>
          <span className="text-lg font-semibold">
            Página {paginaActual} de {totalPaginas}
          </span>
          <button
            onClick={paginaSiguiente}
            disabled={paginaActual === totalPaginas}
            className={`px-4 py-2 rounded-lg text-white ${
              paginaActual === totalPaginas
                ? "bg-gray-500"
                : "bg-blue-500 hover:bg-blue-700"
            } transition-colors duration-150`}
          >
            Siguiente
          </button>
        </div>
      </div>
      {mostrarModalAgregar && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-xl w-3/4 md:w-1/2 lg:w-1/3">
            <form onSubmit={handleSubmitAgregar} className="space-y-4">
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Título de la Pregunta:
                </label>
                {errors.titulo && (
                  <p className="text-red-500 text-sm">{errors.titulo}</p>
                )}
                <input
                  type="text"
                  name="titulo"
                  value={nuevaPregunta.titulo}
                  onChange={handleInputChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Descripción:
                </label>
                {errors.descripcion && (
                  <p className="text-red-500 text-sm">{errors.descripcion}</p>
                )}
                <textarea
                  name="descripcion"
                  value={nuevaPregunta.descripcion}
                  onChange={handleInputChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="flex justify-between">
                <button
                  type="button"
                  onClick={() => setMostrarModalAgregar(false)}
                  className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Ingresar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {mostrarModalEditar && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-xl w-3/4 md:w-1/2 lg:w-1/3">
            <form onSubmit={handleSubmitEditar} className="space-y-4">
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Título de la Pregunta:
                </label>
                {errors.titulo && (
                  <p className="text-red-500 text-sm">{errors.titulo}</p>
                )}
                <input
                  type="text"
                  name="titulo"
                  value={nuevaPregunta.titulo}
                  onChange={handleInputChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Descripción:
                </label>
                {errors.descripcion && (
                  <p className="text-red-500 text-sm">{errors.descripcion}</p>
                )}
                <textarea
                  name="descripcion"
                  value={nuevaPregunta.descripcion}
                  onChange={handleInputChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="flex justify-between">
                <button
                  type="button"
                  onClick={() => setMostrarModalEditar(false)}
                  className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Ingresar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {mostrarModalConfirmacionEliminar && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 flex justify-center items-center">
          <div className="bg-white p-8 rounded-lg shadow-xl max-w-md mx-auto">
            <h2 className="text-xl font-semibold text-center mb-4">
              Confirmar Eliminación
            </h2>
            <p className="text-gray-700 mb-8">
              ¿Estás seguro de que deseas eliminar esta pregunta?
            </p>
            <div className="flex justify-center gap-4">
              <button
                onClick={() => setMostrarModalConfirmacionEliminar(false)}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-6 rounded transition ease-in-out duration-150"
              >
                Cancelar
              </button>
              <button
                onClick={() => eliminarPreguntaConfirmada()}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-6 rounded transition ease-in-out duration-150"
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PreguntasFrecuentes;
