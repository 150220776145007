import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { quienSomos } from "../services/Imagenes";
import Footer from "../services/Footer";
import { obtenerCarrusel } from "../services/Api";
import { API_BASE_URL } from "../services/Config";

const Inicio = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
  };
  const [imagenesCarrusel, setImagenesCarrusel] = useState([]);
  const [imagenesCarruselMovil, setImagenesCarruselMovil] = useState([]);

  useEffect(() => {
    const cargarCarrusel = async () => {
      try {
        
        const carruselObtenido = await obtenerCarrusel('Carrusel1');
        const carruselMovilObtenido = await obtenerCarrusel('Carrusel2');

        setImagenesCarrusel(carruselObtenido.imagenes);
        setImagenesCarruselMovil(carruselMovilObtenido.imagenes);
        
      } catch (error) {
        console.error("Error al obtener carrusel:", error);
      }
    };

    cargarCarrusel();
  }, []);

  return (
    <div className="flex flex-col min-h-screen ">
      <div className="overflow-hidden">
      <div className="w-full hidden md:block">
      <Slider {...settings}>
        {imagenesCarrusel.map((imagen, index) => (
          <div key={index}>
            <img
              src={`${API_BASE_URL}${imagen.uri_foto}`}
              alt={`Carrusel ${index + 1}`}
              className="w-full object-cover"
              style={{ height: "50vh" }}
            />
          </div>
        ))}
      </Slider>
    </div>
    {/* Slider para móviles */}
    <div className="w-full md:hidden">
      <Slider {...settings}>
        {imagenesCarruselMovil.map((imagen, index) => (
          <div key={index}>
            <img
              src={`${API_BASE_URL}${imagen.uri_foto}`}
              alt={`Carrusel Movil ${index + 1}`}
              className="w-full object-cover"
              style={{ height: "50vh" }}
            />
          </div>
        ))}
      </Slider>
    </div>
        {/* Sección de Quiénes Somos */}
        <div className="container mx-auto px-4 py-8 mt-16">
          {" "}
          {/* Añade un margen superior aquí */}
          <div className="flex flex-col md:flex-row justify-center items-center text-center md:text-left -mx-4">
            <div className="mb-4 md:mb-0 md:mr-8">
              <img
                src={quienSomos}
                alt="Quiénes Somos"
                className="mx-auto rounded-lg shadow-lg"
                style={{ maxWidth: "500px" }}
              />
            </div>
            <div className="max-w-md mx-auto md:mx-0">
              <h2 className="text-3xl font-bold text-gray-800 mb-3">
                ¿Quiénes Somos?
              </h2>
              <p className="text-gray-600 text-lg">
                Somos K'arallantas, una tienda especializada en aros de papel,
                comprometidos con la creatividad y la sostenibilidad. Nuestra
                misión es brindar productos únicos que no solo embellezcan su
                espacio, sino que también cuenten una historia.
              </p>
              {/* Más texto si es necesario */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Inicio;
