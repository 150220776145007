import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { restablecerContrasena } from "../services/Api"; // Asegura que esta función esté implementada correctamente en tu API
import Footer from "../services/Footer";
import { FaSpinner } from "react-icons/fa";

const RestablecerContrasena = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    password_confirmation: "",
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalSuccess, setModalSuccess] = useState(false);

  // Extraer el token de la URL
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    let formErrors = {};
    if (!formData.email) formErrors.email = "El correo electrónico es requerido";
    if (!formData.password) formErrors.password = "La contraseña es requerida";
    if (formData.password !== formData.password_confirmation) formErrors.password_confirmation = "Las contraseñas no coinciden";
    return formErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      setIsLoading(true);
      try {
        await restablecerContrasena({
          token,
          email: formData.email,
          password: formData.password,
          password_confirmation: formData.password_confirmation,
        });
        setModalMessage("Tu contraseña ha sido restablecida con éxito.");
        setModalSuccess(true);
        setShowModal(true);
      } catch (error) {
        setModalMessage("Error al restablecer la contraseña. Por favor, inténtalo más tarde.");
        setModalSuccess(false);
        setShowModal(true);
      } finally {
        setIsLoading(false);
      }
    } else {
      setErrors(formErrors);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    if (modalSuccess) {
      navigate("/login");
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow">
        <div className="flex items-center justify-center pt-32 pb-4">
          <div className="w-full max-w-md bg-white p-6 rounded shadow-md border border-gray-200">
            <h2 className="text-2xl font-semibold mb-4 text-center">Restablecer Contraseña</h2>
            {isLoading && (
              <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 flex justify-center items-center">
                <FaSpinner className="animate-spin text-4xl text-white" />
              </div>
            )}
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <input
                  className="w-full p-2 border border-gray-300 rounded"
                  type="email"
                  name="email"
                  placeholder="Correo electrónico*"
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
              </div>
              <div>
                <input
                  className="w-full p-2 border border-gray-300 rounded"
                  type="password"
                  name="password"
                  placeholder="Nueva Contraseña*"
                  value={formData.password}
                  onChange={handleChange}
                />
                {errors.password && <p className="text-red-500 text-sm">{errors.password}</p>}
              </div>
              <div>
                <input
                  className="w-full p-2 border border-gray-300 rounded"
                  type="password"
                  name="password_confirmation"
                  placeholder="Confirmar Nueva Contraseña*"
                  value={formData.password_confirmation}
                  onChange={handleChange}
                />
                {errors.password_confirmation && <p className="text-red-500 text-sm">{errors.password_confirmation}</p>}
              </div>
              <button
                type="submit"
                className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                disabled={isLoading}
              >
                Restablecer Contraseña
              </button>
            </form>
          </div>
          {showModal && (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 flex justify-center items-center">
              <div className="bg-white p-6 rounded-lg shadow-xl">
                <p className="text-lg">{modalMessage}</p>
                <div className="flex justify-end mt-4">
                  <button
                    onClick={handleCloseModal}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Aceptar
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default RestablecerContrasena;
