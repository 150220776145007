import React, { useState } from "react";
// Asegúrate de importar la imagen que desees utilizar
//import imagenProducto from '../logo.svg';
import { enviarSolicitudContacto } from "../services/Api";
import { logo } from "../services/Imagenes";
import { FaInstagram } from "react-icons/fa";
import { GridLoader } from "react-spinners";
import Footer from "../services/Footer";
import { useNavigate } from "react-router-dom";
import { Instagram } from "../services/Config";

const Contacto = () => {
  const [formData, setFormData] = useState({
    nombre: "",
    correo: "",
    numeroCelular: "",
    instagram: "",
    mensaje: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleAceptarClick = () => {
    setShowModal(false);
    navigate("/");
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      try {
        setIsLoading(true); 
        const respuesta = await enviarSolicitudContacto(formData);
        
        if (respuesta.message === "Solicitud de contacto creada exitosamente") {
          setModalMessage("Solicitud de contacto enviada con éxito.");
          setShowModal(true);
        } else {
          setModalMessage("Hubo un error al enviar la solicitud.");
          setShowModal(true);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error al enviar solicitud:", error);
        // Manejo del error aquí
      }
    } else {
      setErrors(formErrors);
    }
  };

  const validateForm = () => {
    let formErrors = {};
    if (!formData.nombre.trim()) formErrors.nombre = "El nombre es requerido";
    if (!formData.correo.trim()) {
      formErrors.correo = "El correo es requerido";
    } else if (!/\S+@\S+\.\S+/.test(formData.correo)) {
      formErrors.correo = "Correo inválido";
    }
    if (
      formData.numeroCelular &&
      !/^(\+\d{1,3})?\d{0,255}$/.test(formData.numeroCelular)
    ) {
      formErrors.numeroCelular = "Número de celular inválido";
    }

    if (formData.instagram && !/^.{0,255}$/.test(formData.instagram)) {
      formErrors.instagram = "Instagram inválido";
    }
    if (!formData.mensaje.trim())
      formErrors.mensaje = "El mensaje es requerido";

    return formErrors;
  };

  return (
    <div className="flex flex-col min-h-screen">
      <div className="mx-auto pt-12 pb-2 px-4 max-w-4xl">
        <h1 className="text-4xl font-bold mb-6">Contacto</h1>
      </div>
      <div className="flex flex-col md:flex-row items-center justify-center p-4 flex-grow mb-16">
      {isLoading && (
        <div style={{
          position: 'fixed', // Posición fija respecto al viewport
          top: 0, // Inicio en la parte superior del viewport
          left: 0, // Inicio en la parte izquierda del viewport
          width: '100%', // Ancho completo
          height: '100%', // Alto completo
          display: 'flex',
          justifyContent: 'center', // Centrado horizontal
          alignItems: 'center', // Centrado vertical
          backgroundColor: 'rgba(255, 255, 255, 0.7)', // Fondo ligeramente transparente
          zIndex: 1000, // Asegura que el loader se muestre por encima de otros elementos
        }}>
          <GridLoader color="#123abc" size={15} />
        </div>
      )}
        <div className="w-full md:w-1/2 p-4 hidden md:block">
          <img
            src={logo}
            alt="Producto"
            className="max-w-sm mx-auto bg-white p-6 rounded-lg shadow-lg border border-gray-200"
          />{" "}
          {/* Sombra más grande en el logo */}
        </div>
        <div className="w-full md:w-1/2 bg-white p-6 rounded-lg shadow-lg border border-gray-200">
          {" "}
          {/* Sombra y bordes más sutiles */}
          <h2 className="text-2xl font-semibold mb-4">
            Ponte en contacto con nosotros
          </h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            {errors.nombre && (
              <p className="text-red-500 text-sm">{errors.nombre}</p>
            )}
            <input
              className="w-full p-2 border border-gray-300 rounded"
              type="text"
              name="nombre"
              value={formData.nombre}
              onChange={handleChange}
              placeholder="Nombre*"
            />
            {errors.correo && (
              <p className="text-red-500 text-sm">{errors.correo}</p>
            )}
            <input
              className="w-full p-2 border border-gray-300 rounded"
              type="email"
              name="correo"
              value={formData.correo}
              onChange={handleChange}
              placeholder="Correo electrónico*"
            />
            {errors.numeroCelular && (
              <p className="text-red-500 text-sm">{errors.numeroCelular}</p>
            )}
            <input
              className="w-full p-2 border border-gray-300 rounded"
              type="text"
              name="numeroCelular"
              value={formData.numeroCelular}
              onChange={handleChange}
              placeholder="Número de celular"
            />
            {errors.instagram && (
              <p className="text-red-500 text-sm">{errors.instagram}</p>
            )}
            <input
              className="w-full p-2 border border-gray-300 rounded"
              type="text"
              name="instagram"
              value={formData.instagram}
              onChange={handleChange}
              placeholder="Instagram"
            />
            {errors.mensaje && (
              <p className="text-red-500 text-sm">{errors.mensaje}</p>
            )}
            <textarea
              className="w-full p-2 border border-gray-300 rounded"
              name="mensaje"
              value={formData.mensaje}
              onChange={handleChange}
              placeholder="Mensaje*"
            />

            <button
              type="submit"
              className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Enviar Mensaje
            </button>
          </form>
        </div>
        <div className="w-full md:w-1/2 p-4 pt-10 text-center">
          <h3 className="text-xl font-semibold">
            Síguenos en nuestras redes sociales
          </h3>
          <div className="flex justify-center space-x-4 mt-4">
            <a
              href={Instagram}
              className="text-gray-600 hover:text-gray-800"
              aria-label="Instagram"
            >
              <FaInstagram size="2em" />
            </a>
          </div>
        </div>
        {showModal && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded-lg shadow-xl">
              <h2 className="text-xl font-semibold mb-4">{modalMessage}</h2>
              <div className="flex justify-center">
                <button
                  onClick={handleAceptarClick}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Volver al inicio
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Contacto;
