import React, { useState, useEffect, useCallback } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { obtenerUsuario, modificarUsuarioPorToken } from "../services/Api";
import { API_BASE_URL } from "../services/Config";
import Footer from "../services/Footer";
import { GridLoader } from "react-spinners";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";

const Cuenta = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [usuario, setUsuario] = useState({
    name: "",
    apellido: "",
    email: "",
    ciudad: "",
    direccion: "",
    numeroCelular: "",
    instagram: "",
    boletas: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [messageType, setMessageType] = useState("success");
  const [visibleBoletaId, setVisibleBoletaId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const boletasPorPagina = 3;

  const indexOfLastBoleta = currentPage * boletasPorPagina;
  const indexOfFirstBoleta = indexOfLastBoleta - boletasPorPagina;
  const currentBoletas = usuario.boletas.slice(
    indexOfFirstBoleta,
    indexOfLastBoleta
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const pageNumbers = [];
  for (
    let i = 1;
    i <= Math.ceil(usuario.boletas.length / boletasPorPagina);
    i++
  ) {
    pageNumbers.push(i);
  }

  const cargarUsuario = useCallback(async () => {
    setIsLoading(true);
    try {
      const usuario = await obtenerUsuario();
      const boletasFiltradas = usuario.boletas.filter(
        (boleta) => boleta.transaccion && boleta.transaccion.estado === 2
      );
      setUsuario({
        name: usuario.name || "",
        apellido: usuario.apellido || "",
        email: usuario.email || "",
        ciudad: usuario.ciudad || "",
        direccion: usuario.direccion || "",
        numeroCelular: usuario.numeroCelular || "",
        instagram: usuario.instagram || "",
        boletas: boletasFiltradas || "",
      });
    } catch (error) {
      alert(error.message);
    }
    setIsLoading(false);
  }, []); // Agrega aquí cualquier dependencia si es necesario

  useEffect(() => {
    cargarUsuario();
  }, [cargarUsuario]);

  const [editable, setEditable] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUsuario({ ...usuario, [name]: value });
  };

  const toggleEditable = () => {
    setEditable(!editable);
  };

  const mostrarMensaje = (texto, tipo) => {
    setMessage(texto);
    setMessageType(tipo);
    setShowMessage(true);
  };

  const saveData = async () => {
    const formErrors = validateUserForm();
    if (Object.keys(formErrors).length === 0) {
      try {
        const respuesta = await modificarUsuarioPorToken(usuario);
        if (
          respuesta &&
          respuesta.message === "Usuario actualizado exitosamente"
        ) {
          // El usuario se editó con éxito
          setErrors({});
          mostrarMensaje(respuesta.message, "success");
          // Recargar la información del usuario
          await cargarUsuario();
        } else {
          // Manejar la respuesta inesperada
          mostrarMensaje(
            "No se pudo editar el usuario correctamente.",
            "error"
          );
        }
      } catch (error) {
        console.error("Error al editar usuario:", error);
        mostrarMensaje(
          "Error al editar el usuario. Por favor, inténtelo de nuevo.",
          "error"
        );
      }
    } else {
      setErrors(formErrors);
    }
    setEditable(false); // Desactivar modo de edición después de guardar
  };

  const validateUserForm = () => {
    let formErrors = {};

    // Validación del nombre
    if (!usuario.name.trim()) {
      formErrors.name = "El nombre es requerido";
    } else if (usuario.name.length < 3 || usuario.name.length > 20) {
      formErrors.name = "El nombre debe tener entre 3 y 20 caracteres";
    }

    // Validación del apellido
    if (!usuario.apellido.trim()) {
      formErrors.apellido = "El apellido es requerido";
    } else if (usuario.apellido.length < 3 || usuario.apellido.length > 20) {
      formErrors.apellido = "El apellido debe tener entre 3 y 20 caracteres";
    }

    // Validación para el correo electrónico
    if (!usuario.email.trim()) {
      formErrors.email = "El correo es requerido";
    } else if (!/\S+@\S+\.\S+/.test(usuario.email)) {
      formErrors.email = "Correo inválido";
    }

    // Validación para el número de celular
    if (
      usuario.numeroCelular &&
      !/^(\+\d{1,3})?\d{0,255}$/.test(usuario.numeroCelular)
    ) {
      formErrors.numeroCelular = "Número de celular inválido";
    }

    // Validación para Instagram (opcional, solo si el campo instagram existe)
    if (usuario.instagram && !/^.{0,255}$/.test(usuario.instagram)) {
      formErrors.instagram = "Instagram inválido";
    }

    // Validación para Instagram (opcional, solo si el campo instagram existe)
    if (usuario.direccion && !/^.{0,255}$/.test(usuario.direccion)) {
      formErrors.direccion = "Direccion inválida";
    }

    // Validación para Instagram (opcional, solo si el campo instagram existe)
    if (usuario.ciudad && !/^.{0,255}$/.test(usuario.ciudad)) {
      formErrors.ciudad = "Direccion inválida";
    }

    // Agrega aquí cualquier otra validación que necesites

    return formErrors;
  };

  const toggleProductos = (boletaId) => {
    if (visibleBoletaId === boletaId) {
      // Si ya está visible, ocultarlo
      setVisibleBoletaId(null);
    } else {
      // Mostrar los productos de esta boleta
      setVisibleBoletaId(boletaId);
    }
  };

  const renderProductos = (productos) => {
    return productos.map((producto) => (
      <div key={producto.id} className="flex items-center my-2">
        {producto.imagenes && producto.imagenes.length > 0 ? (
          <img
            src={`${API_BASE_URL}${producto.imagenes[0].uri_foto}`}
            alt={producto.nombre}
            className="w-20 h-20 object-cover mr-4"
          />
        ) : (
          <div className="w-20 h-20 mr-4 flex items-center justify-center border border-gray-200">
            Sin Imagen
          </div>
        )}
        <div>
          <p>Nombre del Producto: {producto.nombre}</p>
          <p>Precio: ${producto.precio.toLocaleString("de-DE")}</p>
          <p>
            Unidades Compradas:{" "}
            {producto.pivot.cantidad.toLocaleString("de-DE")}
          </p>
        </div>
      </div>
    ));
  };

  const renderCompras = () => {
    if (!currentBoletas || currentBoletas.length === 0) {
      return <p>No hay compras para mostrar.</p>;
    }

    return currentBoletas.map((boleta) => (
      <div key={boleta.id} className="border-b border-gray-200 mb-4 pb-4">
        <div
          className="flex justify-between items-center cursor-pointer"
          onClick={() => toggleProductos(boleta.id)}
        >
          <h3 className="text-lg font-semibold mb-2">
            Número de Boleta: {boleta.publicId}
          </h3>
          {visibleBoletaId === boleta.id ? <FaChevronUp /> : <FaChevronDown />}
        </div>
        <p>Fecha: {boleta.fecha}</p>
        <p>Total: ${boleta.total.toLocaleString("de-DE")}</p>

        <div
          style={{
            maxHeight: visibleBoletaId === boleta.id ? "1000px" : "0",
            overflow: "hidden",
            transition: "max-height 0.5s ease",
          }}
        >
          {visibleBoletaId === boleta.id && (
            <div className="mt-4">{renderProductos(boleta.productos)}</div>
          )}
        </div>
      </div>
    ));
  };

  const renderPagination = () => {
    const totalPaginas = Math.ceil(usuario.boletas.length / boletasPorPagina);
    let startPage, endPage;

    if (totalPaginas <= 3) {
      startPage = 1;
      endPage = totalPaginas;
    } else {
      if (currentPage <= 2) {
        startPage = 1;
        endPage = 3;
      } else if (currentPage + 1 >= totalPaginas) {
        startPage = totalPaginas - 2;
        endPage = totalPaginas;
      } else {
        startPage = currentPage - 1;
        endPage = currentPage + 1;
      }
    }

    const pages = [...Array(endPage + 1 - startPage).keys()].map(
      (i) => startPage + i
    );

    return (
      <nav>
        <ul className="flex justify-center">
          <li className="mx-1">
            <button onClick={() => paginate(1)} className="p-2">
              Primera
            </button>
          </li>
          {pages.map((number) => (
            <li
              key={number}
              className={`mx-1 ${currentPage === number ? "font-bold" : ""}`}
            >
              <button onClick={() => paginate(number)} className="p-2">
                {number}
              </button>
            </li>
          ))}
          <li className="mx-1">
            <button onClick={() => paginate(totalPaginas)} className="p-2">
              Última
            </button>
          </li>
        </ul>
      </nav>
    );
  };

  return (
    <div className="flex flex-col min-h-screen ">
      <div className="flex-1 container mx-auto px-4 py-6">
        <div className="text-center mb-6">
          <h1 className="text-3xl font-semibold">Mi cuenta</h1>
        </div>
        {showMessage && (
          <div
            className={`mb-4 p-2 border rounded flex justify-between items-center ${
              messageType === "error"
                ? "bg-red-100 border-red-400 text-red-700"
                : "bg-green-100 border-green-400 text-green-700"
            }`}
          >
            <span>{message}</span>
            <button
              onClick={() => setShowMessage(false)}
              className="text-green-700 hover:text-green-500"
            >
              &#10005;
            </button>
          </div>
        )}
        <div className="flex justify-center">
          {isLoading ? (
            <div className="flex justify-center items-center h-64">
              {" "}
              {/* Ajusta la altura según sea necesario */}
              <GridLoader color="#123abc" size={15} />{" "}
              {/* Puedes personalizar el color y el tamaño */}
            </div>
          ) : (
            <div className="w-full max-w-2xl bg-white rounded-xl shadow-lg p-6 border border-gray-200">
              <Tabs
                selectedIndex={tabIndex}
                onSelect={(index) => setTabIndex(index)}
              >
                <TabList className="flex border-b">
                  <Tab
                    style={{
                      flex: 1,
                      padding: "0.625rem 0",
                      textAlign: "center",
                      cursor: "pointer",
                      backgroundColor: tabIndex === 0 ? "#e2e8f0" : "", // Color de fondo para la pestaña seleccionada
                    }}
                    className="text-sm leading-5 font-medium rounded-tl-lg focus:outline-none"
                  >
                    Datos del Usuario
                  </Tab>
                  <Tab
                    style={{
                      flex: 1,
                      padding: "0.625rem 0",
                      textAlign: "center",
                      cursor: "pointer",
                      backgroundColor: tabIndex === 1 ? "#e2e8f0" : "", // Color de fondo para la pestaña seleccionada
                    }}
                    className="text-sm leading-5 font-medium rounded-tr-lg focus:outline-none"
                  >
                    Compras
                  </Tab>
                </TabList>

                <TabPanel>
                  <div className="container mx-auto mt-4 p-4">
                    <div className="flex justify-between items-center">
                      <h2 className="text-xl font-semibold">
                        Datos personales
                      </h2>
                      <button
                        onClick={toggleEditable}
                        className={`text-sm ${
                          editable ? "text-red-500" : "text-blue-500"
                        } focus:outline-none`}
                      >
                        {editable ? "Cancelar" : "Editar información"}
                      </button>
                    </div>
                    <div className="mb-4">
                      <label className="block">
                        Nombre
                        <input
                          type="text"
                          name="name"
                          value={usuario.name}
                          onChange={handleChange}
                          disabled={!editable}
                          className={`mt-1 p-2 border rounded-lg w-full focus:ring focus:border-blue-300 ${
                            editable ? "" : "bg-gray-100"
                          } ${errors.name ? "border-red-500" : ""}`}
                        />
                      </label>
                      {errors.name && (
                        <p className="text-red-500 text-xs italic">
                          {errors.name}
                        </p>
                      )}
                    </div>

                    <div className="mb-4">
                      <label className="block">
                        Apellido
                        <input
                          type="text"
                          name="apellido"
                          value={usuario.apellido}
                          onChange={handleChange}
                          disabled={!editable}
                          className={`mt-1 p-2 border rounded-lg w-full focus:ring focus:border-blue-300 ${
                            editable ? "" : "bg-gray-100"
                          } ${errors.apellido ? "border-red-500" : ""}`}
                        />
                      </label>
                      {errors.apellido && (
                        <p className="text-red-500 text-xs italic">
                          {errors.apellido}
                        </p>
                      )}
                    </div>

                    <div className="mb-4">
                      <label className="block">
                        Email
                        <input
                          type="email"
                          name="email"
                          value={usuario.email}
                          onChange={handleChange}
                          disabled={!editable}
                          className={`mt-1 p-2 border rounded-lg w-full focus:ring focus:border-blue-300 ${
                            editable ? "" : "bg-gray-100"
                          } ${errors.email ? "border-red-500" : ""}`}
                        />
                      </label>
                      {errors.email && (
                        <p className="text-red-500 text-xs italic">
                          {errors.email}
                        </p>
                      )}
                    </div>

                    <div className="mb-4">
                      <label className="block">
                        Ciudad
                        <input
                          type="text"
                          name="ciudad"
                          value={usuario.ciudad}
                          onChange={handleChange}
                          disabled={!editable}
                          className={`mt-1 p-2 border rounded-lg w-full focus:ring focus:border-blue-300 ${
                            editable ? "" : "bg-gray-100"
                          } ${errors.ciudad ? "border-red-500" : ""}`}
                        />
                      </label>
                      {errors.ciudad && (
                        <p className="text-red-500 text-xs italic">
                          {errors.ciudad}
                        </p>
                      )}
                    </div>

                    <div className="mb-4">
                      <label className="block">
                        Dirección
                        <input
                          type="text"
                          name="direccion"
                          value={usuario.direccion}
                          onChange={handleChange}
                          disabled={!editable}
                          className={`mt-1 p-2 border rounded-lg w-full focus:ring focus:border-blue-300 ${
                            editable ? "" : "bg-gray-100"
                          } ${errors.direccion ? "border-red-500" : ""}`}
                        />
                      </label>
                      {errors.direccion && (
                        <p className="text-red-500 text-xs italic">
                          {errors.direccion}
                        </p>
                      )}
                    </div>

                    <div className="mb-4">
                      <label className="block">
                        Número de Celular
                        <input
                          type="tel"
                          name="numeroCelular"
                          value={usuario.numeroCelular}
                          onChange={handleChange}
                          disabled={!editable}
                          className={`mt-1 p-2 border rounded-lg w-full focus:ring focus:border-blue-300 ${
                            editable ? "" : "bg-gray-100"
                          } ${errors.numeroCelular ? "border-red-500" : ""}`}
                        />
                      </label>
                      {errors.numeroCelular && (
                        <p className="text-red-500 text-xs italic">
                          {errors.numeroCelular}
                        </p>
                      )}
                    </div>

                    <div className="mb-4">
                      <label className="block">
                        Instagram
                        <input
                          type="text"
                          name="instagram"
                          value={usuario.instagram}
                          onChange={handleChange}
                          disabled={!editable}
                          className={`mt-1 p-2 border rounded-lg w-full focus:ring focus:border-blue-300 ${
                            editable ? "" : "bg-gray-100"
                          } ${errors.instagram ? "border-red-500" : ""}`}
                        />
                      </label>
                      {errors.instagram && (
                        <p className="text-red-500 text-xs italic">
                          {errors.instagram}
                        </p>
                      )}
                    </div>

                    {editable && (
                      <div className="flex justify-end mt-4">
                        <button
                          onClick={saveData}
                          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none"
                        >
                          Guardar
                        </button>
                      </div>
                    )}
                  </div>
                </TabPanel>

                <TabPanel>
                  <div className="container mx-auto mt-4 p-4">
                    <h2 className="text-xl font-semibold mb-4">Compras</h2>
                    {renderCompras()}
                    {renderPagination()}
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Cuenta;
