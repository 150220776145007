import React, { useEffect, useState } from "react";
import {
  obtenerPortafoliosActivos,
  obtenerCategoriasActivas,
  obtenerMaterialesActivos,
} from "../services/Api";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../services/Config";
import Footer from "../services/Footer";
import { GridLoader } from "react-spinners";

const Portafolio = () => {
  const [portafolios, setPortafolios] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [materiales, setMateriales] = useState([]);
  const [paginaActual, setPaginaActual] = useState(1);
  const [filtroCategoria, setFiltroCategoria] = useState("");
  const [filtroMaterial, setFiltroMaterial] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const productosPorPagina = 12;

  const navigate = useNavigate();

  const paginasVisibles = 5; // Número de páginas visibles alrededor de la página actual

  useEffect(() => {
    cargarPortafolios();
    cargarCategorias();
    cargarMateriales();
  }, []);

  const cargarPortafolios = async () => {
    setIsLoading(true);
    try {
      const respuesta = await obtenerPortafoliosActivos();
      setPortafolios(respuesta);
      setIsLoading(false);
    } catch (error) {
      console.error("Error al obtener los portafolios:", error);
      setIsLoading(false);
    }
  };

  const cargarCategorias = async () => {
    try {
      const respuesta = await obtenerCategoriasActivas();
      setCategorias(respuesta);
    } catch (error) {
      console.error("Error al obtener las categorías:", error);
    }
  };

  const cargarMateriales = async () => {
    try {
      const respuesta = await obtenerMaterialesActivos();
      setMateriales(respuesta);
    } catch (error) {
      console.error("Error al obtener los materiales:", error);
    }
  };

  const irADetalleProducto = (nombreProducto) => {
    // Reemplaza espacios con guiones para la URL
    const nombreProductoUrl = nombreProducto.split(" ").join("-").toLowerCase();
    navigate(`/catalogo/${nombreProductoUrl}`);
  };

  const handleFilterByCategory = (event) => {
    setFiltroCategoria(event.target.value);
    setPaginaActual(1); // Reinicia la página a 1 cada vez que cambia el filtro
  };

  const handleFilterByMaterial = (event) => {
    setFiltroMaterial(event.target.value);
    setPaginaActual(1); // Reinicia la página a 1 cada vez que cambia el filtro
  };

  const portafoliosFiltrados = portafolios.filter(
    (portafolio) =>
      (filtroCategoria === "" ||
        portafolio.Categoriaid.toString() === filtroCategoria) &&
      (filtroMaterial === "" ||
        portafolio.Materialid.toString() === filtroMaterial)
  );
  const paginasTotales = Math.ceil(
    portafoliosFiltrados.length / productosPorPagina
  );
  const indiceDelUltimoProducto = paginaActual * productosPorPagina;
  const indiceDelPrimerProducto = indiceDelUltimoProducto - productosPorPagina;
  const portafoliosActuales = portafoliosFiltrados.slice(
    indiceDelPrimerProducto,
    indiceDelUltimoProducto
  );
  // Cambiar página
  const paginar = (numeroDePagina) => {
    setPaginaActual(numeroDePagina);

    // Desplazarse hacia arriba de la página
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Esto hará que el desplazamiento hacia arriba sea suave.
    });
  };
  let paginas = [];
  for (
    let i = Math.max(1, paginaActual - Math.floor(paginasVisibles / 2));
    i <=
    Math.min(paginasTotales, paginaActual + Math.floor(paginasVisibles / 2));
    i++
  ) {
    paginas.push(i);
  }

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow">
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-4xl font-bold text-center mb-10">Portafolio</h1>

          <div className="flex justify-between mb-8">
            <div className="flex-grow">
              <select
                className="border rounded p-3"
                onChange={handleFilterByCategory}
              >
                <option value="">Filtrar por categoría</option>
                {categorias.map((categoria) => (
                  <option key={categoria.id} value={categoria.id}>
                    {categoria.nombre}
                  </option>
                ))}
              </select>
              <select
                className="border rounded p-3 ml-2"
                onChange={handleFilterByMaterial}
              >
                <option value="">Filtrar por material</option>
                {materiales.map((material) => (
                  <option key={material.id} value={material.id}>
                    {material.nombre}
                  </option>
                ))}
              </select>
            </div>
            <div className="text-right">
              {Array.from({ length: paginasTotales }, (_, i) => i + 1).map(
                (numero) => (
                  <button
                    key={numero}
                    onClick={() => paginar(numero)}
                    className={`mx-1 px-2 py-1 border rounded ${
                      paginaActual === numero
                        ? "bg-blue-500 text-white"
                        : "bg-white text-blue-500"
                    }`}
                  >
                    {numero}
                  </button>
                )
              )}
            </div>
          </div>
          {isLoading ? (
            <div className="flex justify-center items-center h-64">
              {" "}
              {/* Ajusta la altura según sea necesario */}
              <GridLoader color="#123abc" size={15} />{" "}
              {/* Puedes personalizar el color y el tamaño */}
            </div>
          ) : (
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
              {portafoliosActuales.map((portafolio) => (
                <div
                  key={portafolio.id}
                  onClick={() => irADetalleProducto(portafolio.titulo)}
                  className="p-1 md:p-2 cursor-pointer transform transition duration-300 ease-in-out hover:scale-105"
                >
                  <div
                    className="w-full"
                    style={{ maxWidth: "350px", margin: "0 auto" }}
                  >
                    <img
                      src={API_BASE_URL + portafolio.uri_foto}
                      alt={portafolio.nombre}
                      className="object-cover w-full"
                      style={{ height: "350px" }}
                    />
                  </div>
                  <div className="text-center mt-4">
                    <h3 className="text-lg font-semibold hover:text-blue-500 group-hover:text-blue-500">
                      {" "}
                      {/* Clase para cambio de color */}
                      {portafolio.titulo}
                    </h3>
                    <p className="text-gray-600">${portafolio.precio}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="py-2">
            <nav>
              <ul className="flex justify-center">
                {Array.from({ length: paginasTotales }, (_, i) => i + 1).map(
                  (numero) => (
                    <li key={numero} className="mx-1">
                      <button
                        onClick={() => paginar(numero)}
                        className={`px-4 py-2 border rounded ${
                          paginaActual === numero
                            ? "bg-blue-500 text-white"
                            : "bg-white text-blue-500"
                        }`}
                      >
                        {numero}
                      </button>
                    </li>
                  )
                )}
              </ul>
            </nav>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Portafolio;
