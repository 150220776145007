import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { confirmarPago } from "../services/Api";
import { CarritoContext } from "../services/CarritoProvider";
import { FaCheckCircle, FaTimesCircle, FaSpinner } from "react-icons/fa";
import Footer from "../services/Footer";

const ConfirmacionCompra = () => {
  const [resultadoPago, setResultadoPago] = useState({
    cargando: true,
    error: null,
    data: null,
  });
  const navigate = useNavigate();
  const location = useLocation();
  const { carrito, vaciarCarrito } = useContext(CarritoContext); // Añade vaciarCarrito aquí

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token_ws");
    const TBK_TOKEN = params.get("TBK_TOKEN");
    const numeroOrden = params.get("TBK_ORDEN_COMPRA");

    if ((token || TBK_TOKEN) && carrito.length > 0) {
      
      confirmarPago(token, TBK_TOKEN, carrito, numeroOrden)
        .then((response) => {
          
          setResultadoPago({ cargando: false, data: response });

          // Limpia el carrito si la transacción es exitosa
          if (response.status === "success") {
            vaciarCarrito();
          }
        })
        .catch((error) => {
          console.error("Error al confirmar pago:", error);
          setResultadoPago({ cargando: false, error, data: null });
        });
      } else{
        
        if(TBK_TOKEN == null && token == null){
          navigate("/");
        }
        
      }
  }, [carrito, location.search, vaciarCarrito, navigate]);

  const volverAlInicio = () => {
    navigate("/"); // Cambiar por la ruta de inicio de tu sitio
  };

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow container mx-auto mt-10 p-4 flex flex-col justify-start items-center">
        <div className="max-w-md w-full bg-white shadow-lg rounded-lg p-6 border border-gray-200">
          <h1 className="text-2xl font-semibold text-center mb-6">
            Confirmación de Compra
          </h1>
          {!resultadoPago.cargando ? (
            <div className="text-center">
              {resultadoPago.error ||
              resultadoPago.data.status === "failure" ||
              resultadoPago.data.status === "cancelled" ? (
                <div>
                  <FaTimesCircle className="mx-auto text-red-500 text-6xl" />
                  <p className="text-red-500 mt-4">
                    {resultadoPago.error
                      ? resultadoPago.error.message
                      : resultadoPago.data.message}
                  </p>
                </div>
              ) : (
                <div>
                  <FaCheckCircle className="mx-auto text-green-500 text-6xl" />
                  <p className="text-green-500 mt-4">
                    Tu pago ha sido procesado con éxito.
                  </p>
                  <div className="text-left mt-6">
                    <p>
                      <strong>ID de Boleta:</strong>{" "}
                      {resultadoPago.data.boleta.publicId}
                    </p>
                    <p>
                    <strong>Total:</strong> ${resultadoPago.data.boleta.total.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                    </p>
                    <p>
                      <strong>Fecha:</strong> {resultadoPago.data.boleta.fecha}
                    </p>
                    <p>
                      <strong>Número de Tarjeta:</strong>{" "}
                      {resultadoPago.data.response.cardNumber}
                    </p>
                  </div>
                </div>
              )}
              <button
                onClick={volverAlInicio}
                className="mt-8 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded block mx-auto"
              >
                Volver al inicio
              </button>
            </div>
          ) : (
            <div className="text-center">
              <FaSpinner className="animate-spin mx-auto text-4xl text-blue-500" />
              <p className="mt-4">Cargando información del pago...</p>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ConfirmacionCompra;
