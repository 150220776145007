import React, { useState, useEffect } from "react";
import {
  obtenerPortafolios,
  agregarPortafolio,
  obtenerCategorias,
  editarPortafolios,
  obtenerMateriales,
} from "../../services/Api";
import { API_BASE_URL } from "../../services/Config";

const Portafolios = () => {
  const [nuevoPortafolio, setNuevoPortafolio] = useState({
    titulo: "",
    descripcion: "",
    estado: "",
    tamanio: "",
    Categoriaid: "",
    Materialid: "",
    uri_foto: null, // Para la imagen
  });
  const [mostrarModalAgregar, setMostrarModalAgregar] = useState(false);
  const [portafolios, setPortafolios] = useState([]);
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [messageType, setMessageType] = useState("success"); // o 'error'
  const [errors, setErrors] = useState({});
  const [categorias, setCategorias] = useState([]);
  const [materiales, setMateriales] = useState([]);
  const [mostrarModalEditar, setMostrarModalEditar] = useState(false);
  const [mostrarModalConfirmacion, setMostrarModalConfirmacion] =
    useState(false);
  const [portafolioAEditar, setPortafolioAEditar] = useState(null);
  const [portafolioACambiarEstado, setPortafolioACambiarEstado] =
    useState(null);
    const [imagenesProcesadas, setImagenesProcesadas] = useState([]);
  const [resetEditorKey, setResetEditorKey] = useState(0);
  const [paginaActual, setPaginaActual] = useState(1);
  const portafoliosPorPagina = 10; // Ajustable según necesites
  const indiceUltimoPortafolio = paginaActual * portafoliosPorPagina;
  const indicePrimerPortafolio = indiceUltimoPortafolio - portafoliosPorPagina;
  const portafoliosActuales = portafolios.slice(
    indicePrimerPortafolio,
    indiceUltimoPortafolio
  );
  const totalPaginas = Math.ceil(portafolios.length / portafoliosPorPagina);

  useEffect(() => {
    cargarCategorias();
    cargarPortafolios();
    cargarMateriales();
  }, []);

  useEffect(() => {
    let timerId;
    if (showMessage) {
      // Establecer el temporizador para ocultar el mensaje después de 5 segundos
      timerId = setTimeout(() => {
        setShowMessage(false);
      }, 5000);
    }
    // Función de limpieza para borrar el temporizador
    return () => clearTimeout(timerId);
  }, [showMessage]);

  const cargarPortafolios = async () => {
    try {
      const portafoliosObtenidos = await obtenerPortafolios();
      setPortafolios(
        portafoliosObtenidos.map((p) => ({ ...p, abierto: false }))
      );
      
    } catch (error) {
      console.error("Error al obtener portafolios:", error);
    }
  };

  const cargarCategorias = async () => {
    try {
      const categoriasObtenidas = await obtenerCategorias();
      setCategorias(categoriasObtenidas);
    } catch (error) {
      console.error("Error al obtener las categorías:", error);
    }
  };

  const cargarMateriales = async () => {
    try {
      const materialesObtenidos = await obtenerMateriales();
      setMateriales(materialesObtenidos);
    } catch (error) {
      console.error("Error al obtener los materiales:", error);
    }
  };

  // Función para manejar la entrada de datos del formulario
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNuevoPortafolio({ ...nuevoPortafolio, [name]: value });
  };

  // Función para manejar la subida de imágenes
  const handleImageChange = (e) => {
    setNuevoPortafolio({ ...nuevoPortafolio, uri_foto: e.target.files[0] });
  };

  const handleEditImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setPortafolioAEditar({
        ...portafolioAEditar,
        uri_foto: e.target.files[0],
      });
    }
  };

  const handleSubmitAgregar = async (e) => {
    e.preventDefault();
    
    const formData = new FormData();
    for (const key in nuevoPortafolio) {
      if (nuevoPortafolio[key]) {
        formData.append(key, nuevoPortafolio[key]);
      }
    }
    
    // Validar el formulario antes de la subida
    const formErrors = validatePortafolioForm(nuevoPortafolio);
    if (Object.keys(formErrors).length === 0) {
      // No hay errores, realizar la subida
      try {
        
        const respuesta = await agregarPortafolio(formData);
        // Manejar la respuesta positiva
        if (
          respuesta &&
          respuesta.message === "Portafolio creado exitosamente"
        ) {
          // La categoría se agregó con éxito
          mostrarMensaje(respuesta.message, "success");
          // Actualizar la lista de portafolios...
          const portafoliosObtenidos = await obtenerPortafolios(); // Asegúrate de tener esta función para actualizar la lista
          setPortafolios(portafoliosObtenidos);
          setNuevoPortafolio({
            titulo: "",
            descripcion: "",
            estado: "",
            tamanio: "",
            Categoriaid: "",
            Materialid: "",
            uri_foto: null,
          });
          setMostrarModalAgregar(false);
        } else {
          // Manejar la respuesta inesperada
          mostrarMensaje(
            "No se pudo agregar el portafolio correctamente.",
            "error"
          );
        }
      } catch (error) {
        // Manejar el error del servidor
        mostrarMensaje(
          "Error al agregar el portafolio. Por favor, inténtelo de nuevo.",
          "error"
        );
      }
    } else {
      // Hay errores en el formulario
      setErrors(formErrors);
    }
  };

  // Función para mostrar mensajes
  const mostrarMensaje = (texto, tipo) => {
    setMessage(texto);
    setMessageType(tipo); // 'success' o 'error'
    setShowMessage(true);
  };

  const toggleDetalles = (id) => {
    setPortafolios(
      portafolios.map((portafolio) => {
        if (portafolio.id === id) {
          return { ...portafolio, abierto: !portafolio.abierto };
        }
        return portafolio;
      })
    );
  };

  const abrirModalEditar = (portafolio) => {
    setPortafolioAEditar(portafolio);
    setMostrarModalEditar(true);
  };

  const confirmarCambioEstado = (portafolio) => {
    setPortafolioACambiarEstado(portafolio);
    setMostrarModalConfirmacion(true);
  };

  const cambiarEstadoPortafolio = async () => {
    if (!portafolioACambiarEstado) return;

    const estadoActualizado =
      portafolioACambiarEstado.estado === "Activo" ? "Inactivo" : "Activo";
    const formData = new FormData();
    formData.append("estado", estadoActualizado);

    // Añadir otros campos si es necesario, por ejemplo:
    // formData.append('titulo', portafolioACambiarEstado.titulo);

    try {
      const respuesta = await editarPortafolios(
        portafolioACambiarEstado.id,
        formData
      );
      if (
        respuesta &&
        respuesta.message === "Portafolio actualizado exitosamente"
      ) {
        mostrarMensaje(respuesta.message, "success");
        cargarPortafolios();
      } else {
        mostrarMensaje("No se pudo cambiar el estado del portafolio.", "error");
      }
    } catch (error) {
      mostrarMensaje(
        "Error al cambiar el estado del portafolio. Por favor, inténtelo de nuevo.",
        "error"
      );
    }

    setMostrarModalConfirmacion(false);
    setPortafolioACambiarEstado(null);
  };

  const handleSubmitEditar = async (e) => {
    e.preventDefault();

    // Preparar FormData para manejar la subida de la imagen
    const formData = new FormData();
    for (const key in portafolioAEditar) {
      formData.append(key, portafolioAEditar[key]);
    }

    // Si se seleccionó una nueva imagen, añádela a formData
    if (portafolioAEditar.uri_foto instanceof File) {
      formData.append(
        "uri_foto",
        portafolioAEditar.uri_foto,
        portafolioAEditar.uri_foto.name
      );
    }

    

    // Validar el formulario antes de la subida
    const formErrors = validatePortafolioForm(portafolioAEditar);
    if (Object.keys(formErrors).length === 0) {
      try {
        
        const respuesta = await editarPortafolios(
          portafolioAEditar.id,
          formData
        );
        // Manejar la respuesta positiva
        if (
          respuesta &&
          respuesta.message === "Portafolio actualizado exitosamente"
        ) {
          mostrarMensaje(respuesta.message, "success");
          // Actualizar la lista de portafolios
          const portafoliosActualizados = await obtenerPortafolios();
          setPortafolios(portafoliosActualizados);
          setMostrarModalEditar(false);
        } else {
          // Manejar la respuesta inesperada
          mostrarMensaje(
            "No se pudo actualizar el portafolio correctamente.",
            "error"
          );
        }
      } catch (error) {
        // Manejar el error del servidor
        mostrarMensaje(
          "Error al actualizar el portafolio. Por favor, inténtelo de nuevo.",
          "error"
        );
      }
    } else {
      // Hay errores en el formulario
      setErrors(formErrors);
    }
  };

  const validatePortafolioForm = (datosPortafolio) => {
    let formErrors = {};

    // Validación para el título
    if (!datosPortafolio.titulo.trim()) {
      formErrors.titulo = "El título es requerido";
    } else if (datosPortafolio.titulo.trim().length > 255) {
      formErrors.titulo = "El título debe tener menos de 255 caracteres";
    }

    // Validación para la descripción
    if (
      datosPortafolio.descripcion &&
      datosPortafolio.descripcion.trim().length > 255
    ) {
      formErrors.descripcion =
        "La descripción debe tener menos de 255 caracteres";
    }

    // Validación para el tamaño
    if (
      datosPortafolio.tamanio &&
      datosPortafolio.tamanio.trim().length > 255
    ) {
      formErrors.tamanio = "El tamaño debe tener menos de 255 caracteres";
    }

    // Validación para la categoría
    if (!datosPortafolio.Categoriaid) {
      formErrors.Categoriaid = "La categoría es requerida";
    } else if (
      isNaN(datosPortafolio.Categoriaid) ||
      parseInt(datosPortafolio.Categoriaid) <= 0
    ) {
      formErrors.Categoriaid = "La categoría seleccionada es inválida";
    }

    // Validación para el material
    if (!datosPortafolio.Materialid) {
      formErrors.Materialid = "El material es requerido";
    } else if (
      isNaN(datosPortafolio.Materialid) ||
      parseInt(datosPortafolio.Materialid) <= 0
    ) {
      formErrors.Materialid = "El material seleccionado es inválido";
    }

    // Validación para la imagen
    if (
      datosPortafolio.uri_foto &&
      datosPortafolio.uri_foto !== nuevoPortafolio.uri_foto &&
      datosPortafolio.uri_foto !== portafolioAEditar.uri_foto
    ) {
      if (
        ![
          "image/jpeg",
          "image/png",
          "image/jpg",
          "image/gif",
          "image/svg+xml",
        ].includes(datosPortafolio.uri_foto.type)
      ) {
        formErrors.uri_foto =
          "Formato de imagen inválido. Solo se permiten JPEG, PNG, JPG, GIF, SVG.";
      } else if (datosPortafolio.uri_foto.size > 2048 * 1024) {
        formErrors.uri_foto = "El tamaño de la imagen debe ser menor a 2MB.";
      }
    }

    return formErrors;
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setPortafolioAEditar({ ...portafolioAEditar, [name]: value });
  };


  const manejarImagenesProcesadas = (imagenProcesada, id) => {
    

    setImagenesProcesadas((prevImagenes) => {
      // Verifica si el id ya existe en el array
      const imagenExiste = prevImagenes.some((imagen) => imagen.id === id);
      if (imagenExiste) {
        // Si la imagen ya existe, reemplaza su src manteniendo el mismo id
        return prevImagenes.map((imagen) =>
          imagen.id === id ? { ...imagen, src: imagenProcesada } : imagen
        );
      } else {
        // Si la imagen no existe, simplemente la añade al array
        return [...prevImagenes, { id, src: imagenProcesada }];
      }
    });
  };

  const manejarCambioDeOrden = async (nuevoOrdenDeImagenes) => {
    // Este código asume que todas las imágenes pueden necesitar ser convertidas de blob a Base64.
    // Si las imágenes ya están en Base64, simplemente se mantienen como están.
    const imagenesConvertidas = await Promise.all(
      nuevoOrdenDeImagenes.map(async (imagen) => {
        if (!imagen.src.startsWith("blob:")) {
          return imagen; // La imagen ya está en Base64
        }
        const srcBase64 = await blobToBase64(imagen.src);
        return { ...imagen, src: srcBase64 };
      })
    );
    setImagenesProcesadas(imagenesConvertidas);
  };

  function blobToBase64(blobUrl) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      fetch(blobUrl) // Fetch the blob from the blob URL
        .then((res) => res.blob())
        .then((blob) => {
          reader.readAsDataURL(blob); // Read the blob as DataURL
          reader.onloadend = function () {
            resolve(reader.result); // Return the result
          };
          reader.onerror = reject;
        })
        .catch(reject);
    });
  }

  const manejarEliminacionDeImagen = (imageId) => {
    

    // Filtra directamente las imágenes procesadas para eliminar la imagen con el id específico
    const imagenesActualizadas = imagenesProcesadas.filter(
      (imagen) => imagen.id !== imageId
    );

    setImagenesProcesadas(imagenesActualizadas);

    
  };

  const irAPaginaSiguiente = () => {
    setPaginaActual((pagActual) => Math.min(pagActual + 1, totalPaginas));
  };

  const irAPaginaAnterior = () => {
    setPaginaActual((pagActual) => Math.max(pagActual - 1, 1));
  };

  return (
    <div className="container mx-auto p-4">
      <div className="bg-white p-4 rounded shadow-md">
        {showMessage && (
          <div
            className={`mb-4 p-2 border rounded flex justify-between items-center ${
              messageType === "error"
                ? "bg-red-100 border-red-400 text-red-700"
                : "bg-green-100 border-green-400 text-green-700"
            }`}
          >
            <span>{message}</span>
            <button
              onClick={() => setShowMessage(false)}
              className="text-green-700 hover:text-green-500"
            >
              &#10005;
            </button>
          </div>
        )}
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold mb-4">Portafolios</h2>
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={() => setMostrarModalAgregar(true)}
          >
            Agregar Portafolio
          </button>
        </div>
        {portafoliosActuales.map((portafolio) => (
          <div key={portafolio.id} className="border-b border-gray-200 py-4">
            <div className="flex justify-between items-center">
              <h3
                className="text-lg font-semibold cursor-pointer"
                onClick={() => toggleDetalles(portafolio.id)}
              >
                {portafolio.titulo}
              </h3>
              <div>
                <button
                  className="mr-2 px-3 py-1 bg-blue-500 text-white rounded"
                  onClick={() => abrirModalEditar(portafolio)}
                >
                  Editar
                </button>
                {portafolio.estado === "Activo" ? (
                  <button
                    onClick={() => confirmarCambioEstado(portafolio)}
                    className="px-3 py-1 bg-red-500 text-white rounded"
                  >
                    Deshabilitar
                  </button>
                ) : (
                  <button
                    onClick={() => confirmarCambioEstado(portafolio)}
                    className="mr-2 px-3 py-1 bg-green-500 text-white rounded"
                  >
                    Habilitar
                  </button>
                )}
              </div>
            </div>
            {portafolio.abierto && (
              <div className="mt-2 text-gray-600">
                <p>
                  <strong>Descripción:</strong> {portafolio.descripcion}
                </p>
                <p>
                  <strong>Estado:</strong> {portafolio.estado}
                </p>
                <p>
                  <strong>Categoría:</strong> {portafolio.categoria.nombre}
                </p>
                <p>
                  <strong>Material:</strong> {portafolio.material.nombre}
                </p>
                <p>
                  <strong>Tamaño:</strong> {portafolio.tamanio}
                </p>
                {portafolio.uri_foto && (
                  <img
                    src={API_BASE_URL + portafolio.uri_foto}
                    alt={portafolio.titulo}
                    className="mt-2 max-w-xs"
                  />
                )}
              </div>
            )}
          </div>
        ))}
        <div className="flex justify-between items-center mt-8">
          <button
            onClick={irAPaginaAnterior}
            disabled={paginaActual === 1}
            className={`px-4 py-2 rounded-lg text-white ${
              paginaActual === 1
                ? "bg-gray-500"
                : "bg-blue-500 hover:bg-blue-700"
            } transition-colors duration-150`}
          >
            Anterior
          </button>
          <span className="text-lg font-semibold">
            Página {paginaActual} de {totalPaginas}
          </span>
          <button
            onClick={irAPaginaSiguiente}
            disabled={paginaActual === totalPaginas}
            className={`px-4 py-2 rounded-lg text-white ${
              paginaActual === totalPaginas
                ? "bg-gray-500"
                : "bg-blue-500 hover:bg-blue-700"
            } transition-colors duration-150`}
          >
            Siguiente
          </button>
        </div>
      </div>
      {/* Modal para agregar portafolio */}
      {mostrarModalAgregar && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-xl w-3/4 md:w-1/2 lg:w-1/3">
            <form onSubmit={handleSubmitAgregar} className="space-y-4">
              {/* Título */}
              <div className="mb-4">
                <label
                  htmlFor="titulo"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Título:
                </label>
                <input
                  type="text"
                  name="titulo"
                  id="titulo"
                  value={nuevoPortafolio.titulo}
                  onChange={handleInputChange}
                  placeholder="Introduce el título"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {errors.titulo && (
                  <p className="text-red-500 text-sm">{errors.titulo}</p>
                )}
              </div>

              {/* Descripción */}
              <div className="mb-4">
                <label
                  htmlFor="descripcion"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Descripción:
                </label>
                <textarea
                  name="descripcion"
                  id="descripcion"
                  value={nuevoPortafolio.descripcion}
                  onChange={handleInputChange}
                  placeholder="Breve descripción del portafolio"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                />
                {errors.descripcion && (
                  <p className="text-red-500 text-sm">{errors.descripcion}</p>
                )}
              </div>

              {/* Categoría */}
              <div className="mb-4">
                <label
                  htmlFor="categoria"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Categoría:
                </label>
                <select
                  name="Categoriaid"
                  id="categoria"
                  value={nuevoPortafolio.Categoriaid}
                  onChange={handleInputChange}
                  className="shadow border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value="">Seleccione una categoría</option>
                  {categorias.map((categoria) => (
                    <option key={categoria.id} value={categoria.id}>
                      {categoria.nombre}
                    </option>
                  ))}
                </select>
                {errors.Categoriaid && (
                  <p className="text-red-500 text-sm">{errors.Categoriaid}</p>
                )}
              </div>

              {/* Material */}
              <div className="mb-4">
                <label
                  htmlFor="material"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Material:
                </label>
                <select
                  name="Materialid"
                  id="material"
                  value={nuevoPortafolio.Materialid}
                  onChange={handleInputChange}
                  className="shadow border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value="">Seleccione un material</option>
                  {materiales.map((material) => (
                    <option key={material.id} value={material.id}>
                      {material.nombre}
                    </option>
                  ))}
                </select>
                {errors.Materialid && (
                  <p className="text-red-500 text-sm">{errors.Materialid}</p>
                )}
              </div>

              {/* Tamaño */}
              <div className="mb-4">
                <label
                  htmlFor="tamanio"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Tamaño:
                </label>
                <input
                  type="text"
                  name="tamanio"
                  id="tamanio"
                  value={nuevoPortafolio.tamanio}
                  onChange={handleInputChange}
                  placeholder="Dimensiones del trabajo"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {errors.tamanio && (
                  <p className="text-red-500 text-sm">{errors.tamanio}</p>
                )}
              </div>

              {/* Imagen */}
              <div className="mb-4">
                <label
                  htmlFor="uri_foto"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Imagen:
                </label>
                <input
                  type="file"
                  name="uri_foto"
                  id="uri_foto"
                  onChange={handleImageChange} // Asegúrate de implementar esta función
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {errors.uri_foto && (
                  <p className="text-red-500 text-sm">{errors.uri_foto}</p>
                )}
              </div>

              {/* Botones del formulario */}
              <div className="flex items-center justify-between">
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition ease-in-out duration-150"
                >
                  Agregar
                </button>
                <button
                  type="button"
                  onClick={() => setMostrarModalAgregar(false)}
                  className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded transition ease-in-out duration-150 ml-2"
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {mostrarModalEditar && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-xl w-3/4 md:w-1/2 lg:w-1/3">
            <form onSubmit={(e) => handleSubmitEditar(e)} className="space-y-4">
              {/* Título */}
              <div className="mb-4">
                <label
                  htmlFor="tituloEditar"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Título:
                </label>
                <input
                  type="text"
                  name="titulo"
                  id="tituloEditar"
                  value={portafolioAEditar.titulo}
                  onChange={handleEditInputChange}
                  placeholder="Introduce el título"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {errors.titulo && (
                  <p className="text-red-500 text-sm">{errors.titulo}</p>
                )}
              </div>

              {/* Descripción */}
              <div className="mb-4">
                <label
                  htmlFor="descripcionEditar"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Descripción:
                </label>
                <textarea
                  name="descripcion"
                  id="descripcionEditar"
                  value={portafolioAEditar.descripcion}
                  onChange={handleEditInputChange}
                  placeholder="Breve descripción del portafolio"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                />
                {errors.descripcion && (
                  <p className="text-red-500 text-sm">{errors.descripcion}</p>
                )}
              </div>

              {/* Categoría */}
              <div className="mb-4">
                <label
                  htmlFor="categoriaEditar"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Categoría:
                </label>
                <select
                  name="Categoriaid"
                  id="categoriaEditar"
                  value={portafolioAEditar.Categoriaid}
                  onChange={handleEditInputChange}
                  className="shadow border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value="">Seleccione una categoría</option>
                  {categorias.map((categoria) => (
                    <option key={categoria.id} value={categoria.id}>
                      {categoria.nombre}
                    </option>
                  ))}
                </select>
                {errors.Categoriaid && (
                  <p className="text-red-500 text-sm">{errors.Categoriaid}</p>
                )}
              </div>

              {/* Material */}
              <div className="mb-4">
                <label
                  htmlFor="materialEditar"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Material:
                </label>
                <select
                  name="Material"
                  id="materialEditar"
                  value={portafolioAEditar.Materialid}
                  onChange={handleEditInputChange}
                  className="shadow border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value="">Seleccione un material</option>
                  {materiales.map((material) => (
                    <option key={material.id} value={material.id}>
                      {material.nombre}
                    </option>
                  ))}
                </select>
                {errors.Materialid && (
                  <p className="text-red-500 text-sm">{errors.Materialid}</p>
                )}
              </div>

              {/* Tamaño */}
              <div className="mb-4">
                <label
                  htmlFor="tamanioEditar"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Tamaño:
                </label>
                <input
                  type="text"
                  name="tamanio"
                  id="tamanioEditar"
                  value={portafolioAEditar.tamanio}
                  onChange={handleEditInputChange}
                  placeholder="Dimensiones del trabajo"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {errors.tamanio && (
                  <p className="text-red-500 text-sm">{errors.tamanio}</p>
                )}
              </div>

              {/* Imagen */}
              <div className="mb-4">
                <label
                  htmlFor="uri_fotoEditar"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Imagen:
                </label>
                <input
                  type="file"
                  name="uri_foto"
                  id="uri_fotoEditar"
                  onChange={handleEditImageChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {errors.uri_foto && (
                  <p className="text-red-500 text-sm">{errors.uri_foto}</p>
                )}
              </div>

              {/* Botones del formulario */}
              <div className="flex items-center justify-between">
                <button
                  type="button"
                  onClick={() => setMostrarModalEditar(false)}
                  className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Guardar Cambios
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {mostrarModalConfirmacion && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-xl">
            <h3 className="mb-4 text-lg font-semibold">
              ¿Estás seguro de que quieres{" "}
              {portafolioACambiarEstado?.estado === "Activo"
                ? "deshabilitar"
                : "habilitar"}{" "}
              este portafolio?
            </h3>
            <div className="flex justify-around">
              <button
                onClick={() => setMostrarModalConfirmacion(false)}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
              >
                Cancelar
              </button>
              <button
                onClick={cambiarEstadoPortafolio}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Portafolios;
