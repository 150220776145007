import React, { useState, useEffect } from "react";
import { obtenerTransacciones } from "../../services/Api";
import { API_BASE_URL } from "../../services/Config";

const Ventas = () => {
  const [transacciones, setTransacciones] = useState([]);
  const [paginaActual, setPaginaActual] = useState(1);
  const transaccionesPorPagina = 10; // Esto puedes ajustarlo según necesites
  const indiceUltimaTransaccion = paginaActual * transaccionesPorPagina;
  const indicePrimeraTransaccion =
    indiceUltimaTransaccion - transaccionesPorPagina;
  const transaccionesActuales = transacciones.slice(
    indicePrimeraTransaccion,
    indiceUltimaTransaccion
  );
  const totalPaginas = Math.ceil(transacciones.length / transaccionesPorPagina);

  useEffect(() => {
    const cargarTransacciones = async () => {
      try {
        const transaccionesObtenidas = await obtenerTransacciones();
        setTransacciones(transaccionesObtenidas);
      } catch (error) {
        console.error("Error al obtener transacciones:", error);
      }
    };

    cargarTransacciones();
  }, []);

  const toggleDetalle = (id) => {
    setTransacciones(
      transacciones.map((transaccion) => {
        if (transaccion.id === id) {
          return { ...transaccion, abierta: !transaccion.abierta };
        }
        return transaccion;
      })
    );
  };

  const obtenerNombreEstado = (estado) => {
    const nombresEstados = {
      1: "Ingresado",
      2: "Aprobado",
      3: "Rechazado",
      4: "Anulado",
      5: "Caducado",
    };
    return nombresEstados[estado] || "Estado Desconocido";
  };

  const irAPaginaSiguiente = () => {
    setPaginaActual((pagActual) => Math.min(pagActual + 1, totalPaginas));
  };

  const irAPaginaAnterior = () => {
    setPaginaActual((pagActual) => Math.max(pagActual - 1, 1));
  };

  return (
    <div className="container mx-auto p-4">
      <div className="bg-white p-4 rounded shadow-md">
        <h2 className="text-xl font-semibold mb-4">Ventas</h2>
        {transaccionesActuales.map((transaccion) => (
          <div key={transaccion.id} className="border-b border-gray-200 py-4">
            <div className="flex justify-between items-center">
              <h3
                className="text-lg font-semibold cursor-pointer"
                onClick={() => toggleDetalle(transaccion.id)}
              >
                {`Boleta ID: ${
                  transaccion.boletas[0].publicId
                } - Fecha: ${new Date(
                  transaccion.fechaTransaccion
                ).toLocaleString()} - Estado: ${obtenerNombreEstado(
                  transaccion.estado
                )}`}
              </h3>
            </div>
            {transaccion.abierta && (
              <div className="mt-2 text-gray-600">
                <p>Tipo de Entrega: {transaccion.tipoEntrega}</p>
                <p>
                  Total: $
                  {transaccion.boletas
                    .reduce((total, boleta) => total + boleta.total, 0)
                    .toLocaleString("de-DE")}
                </p>

                {transaccion.boletas.map((boleta) => (
                  <div key={boleta.id} className="mt-2">
                    <h4 className="text-md font-semibold">
                      Productos en Boleta {transaccion.boletas[0].publicId}
                    </h4>
                    {boleta.productos.map((producto) => (
                      <div key={producto.id} className="flex items-center mt-1">
                        <img
                          src={API_BASE_URL + producto.imagenes[0].uri_foto}
                          alt={producto.nombre}
                          className="w-10 h-10 mr-2"
                        />
                        <p>
                          {producto.nombre} - Precio:{" "}$
                          {producto.precio.toLocaleString("de-DE")} - Cantidad:{" "}
                          {producto.pivot.cantidad.toLocaleString("de-DE")}
                        </p>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
        <div className="flex justify-between items-center mt-8">
          <button
            onClick={irAPaginaAnterior}
            disabled={paginaActual === 1}
            className={`px-4 py-2 rounded-lg text-white ${
              paginaActual === 1
                ? "bg-gray-500"
                : "bg-blue-500 hover:bg-blue-700"
            } transition-colors duration-150`}
          >
            Anterior
          </button>
          <span className="text-lg font-semibold">
            Página {paginaActual} de {totalPaginas}
          </span>
          <button
            onClick={irAPaginaSiguiente}
            disabled={paginaActual === totalPaginas}
            className={`px-4 py-2 rounded-lg text-white ${
              paginaActual === totalPaginas
                ? "bg-gray-500"
                : "bg-blue-500 hover:bg-blue-700"
            } transition-colors duration-150`}
          >
            Siguiente
          </button>
        </div>
      </div>
    </div>
  );
};

export default Ventas;
