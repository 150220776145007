import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { registrarUsuario } from "../services/Api"; // Asegúrate de implementar esta función en tu Api
import Footer from "../services/Footer";
import { FaSpinner } from "react-icons/fa";

const Registrarse = () => {
  const [formData, setFormData] = useState({
    name: "",
    apellido: "",
    email: "",
    password: "",
    password_confirmation: "",
  });

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const translateErrorMessage = (error) => {
    const errorTranslations = {
      "The email has already been taken.":
        "El correo electrónico ya ha sido registrado.",
      // Aquí puedes añadir más traducciones de errores si es necesario
    };

    return errorTranslations[error] || error; // Devuelve la traducción o el error original si no se encuentra una traducción
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      setIsLoading(true);
      try {
        setErrors({});
        const respuesta = await registrarUsuario(formData);
        
        localStorage.setItem("auth_token", respuesta.access_token); // Guarda el token en localStorage
        localStorage.setItem("auth_user_rol", respuesta.user.rol); // Guarda el token en localStorage
        localStorage.setItem("auth_user", JSON.stringify(respuesta.user)); // Convierte el objeto user a una cadena JSON
        navigate("/"); // Redirige al home
        window.location.reload(); // Recarga la página
      } catch (error) {
        console.error("Error al registrar usuario:", error);
        if (error.message === "Failed to fetch") {
          setErrorMessage(
            "No se pudo establecer conexión con el servidor. Por favor, inténtalo más tarde."
          );
        } else {
          // Procesamiento de otros tipos de errores
          try {
            const errorData = JSON.parse(error.message);

            let errorMessage = errorData.message;
            if (errorData.errors) {
              // Traducir cada mensaje de error individualmente
              const errorMessages = Object.values(errorData.errors)
                .map((err) =>
                  err.map((e) => translateErrorMessage(e)).join(" ")
                )
                .join(" ");
              errorMessage += ": " + errorMessages;
            }

            setErrorMessage(errorMessage);
          } catch (jsonError) {
            // Manejo de errores que no son JSON
            setErrorMessage(
              "Ocurrió un error inesperado. Por favor, inténtalo más tarde."
            );
          }
        }
        setShowErrorModal(true);
      } finally {
        setIsLoading(false); // Detener la animación de carga
      }
    } else {
      setErrors(formErrors);
    }
  };

  const validatePassword = (password) => {
    if (!password) return "La contraseña es requerida";

    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const isValidLength = password.length >= 8;

    if (!isValidLength)
      return "La contraseña debe tener al menos 8 caracteres.";
    if (!hasNumber) return "La contraseña debe incluir al menos un número.";
    if (!hasSpecialChar)
      return "La contraseña debe incluir al menos un carácter especial.";
    return "";
  };

  const validateForm = () => {
    let formErrors = {};
  
    // Validación del nombre
    if (!formData.name.trim()) {
      formErrors.name = "El nombre es requerido";
    } else if (formData.name.length < 3 || formData.name.length > 20) {
      formErrors.name = "El nombre debe tener entre 3 y 20 caracteres";
    }
  
    // Validación del apellido
    if (!formData.apellido.trim()) {
      formErrors.apellido = "El apellido es requerido";
    } else if (formData.apellido.length < 3 || formData.apellido.length > 20) {
      formErrors.apellido = "El apellido debe tener entre 3 y 20 caracteres";
    }
  
    // Validaciones existentes...
    if (!formData.email.trim()) {
      formErrors.email = "El correo electrónico es requerido";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = "Correo electrónico inválido";
    }
  
    const passwordError = validatePassword(formData.password);
    if (passwordError) formErrors.password = passwordError;
  
    if (formData.password !== formData.password_confirmation) {
      formErrors.password_confirmation = "Las contraseñas no coinciden";
    }
  
    return formErrors;
  };
  

  return (
    <div className="flex flex-col min-h-screen">
      {" "}
      {/* Asegura que el contenedor principal tenga al menos la altura de la pantalla */}
      <div className="flex-grow">
        {" "}
        {/* Hace que este div ocupe todo el espacio disponible */}
        <div className="flex items-center justify-center pt-16 pb-4">
          {" "}
          {/* Ajuste del padding aquí */}
          <div className="w-full max-w-md bg-white p-6 rounded shadow-md border border-gray-200">
            <h2 className="text-2xl font-semibold mb-4 text-center">
              Regístrate
            </h2>
            {isLoading && (
              <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 flex justify-center items-center">
                <FaSpinner className="animate-spin text-4xl text-white" />
              </div>
            )}
            <form onSubmit={handleSubmit} className="space-y-4">
              {errors.name && (
                <p className="text-red-500 text-sm">{errors.name}</p>
              )}
              <input
                className="w-full p-2 border border-gray-300 rounded"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Nombre*"
              />
              {errors.apellido && (
                <p className="text-red-500 text-sm">{errors.apellido}</p>
              )}
              <input
                className="w-full p-2 border border-gray-300 rounded"
                type="text"
                name="apellido"
                value={formData.apellido}
                onChange={handleChange}
                placeholder="Apellido*"
              />
              {errors.email && (
                <p className="text-red-500 text-sm">{errors.email}</p>
              )}
              <input
                className="w-full p-2 border border-gray-300 rounded"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Correo electrónico*"
              />
              {errors.password && (
                <p className="text-red-500 text-sm">{errors.password}</p>
              )}
              <input
                className="w-full p-2 border border-gray-300 rounded"
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Contraseña*"
              />
              {errors.password_confirmation && (
                <p className="text-red-500 text-sm">
                  {errors.password_confirmation}
                </p>
              )}
              <input
                className="w-full p-2 border border-gray-300 rounded"
                type="password"
                name="password_confirmation"
                value={formData.password_confirmation}
                onChange={handleChange}
                placeholder="Confirmar Contraseña*"
              />

              <button
                type="submit"
                className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Registrarse
              </button>
            </form>
          </div>
          {showErrorModal && (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 flex justify-center items-center">
              <div className="bg-white p-6 rounded-lg shadow-xl">
                <h3 className="text-lg font-semibold">Error al Registrarse</h3>
                <p>{errorMessage}</p>
                <div className="flex justify-end mt-4">
                  <button
                    onClick={() => setShowErrorModal(false)}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Aceptar
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Registrarse;
