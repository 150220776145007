import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { logo3 } from "./Imagenes";
import { cerrarSesion } from "./Api";
import ResumenCarrito from "./ResumenCarrito";
import { verificarToken } from "./Api";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Hidden from "@mui/material/Hidden";
import { FaInstagram } from "react-icons/fa";
import Divider from "@mui/material/Divider";
import { FaRegUser } from "react-icons/fa";
import { MdOutlineShoppingCart } from "react-icons/md";
import { Instagram } from "./Config";

const Navbar = () => {
  const isAuthenticated = !!localStorage.getItem("auth_token");
  const [userRol, setUserRol] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [authToken] = useState(localStorage.getItem("auth_token"));
  const closeTimer = useRef(null);

  useEffect(() => {
    verificarToken();
    const user_rol = localStorage.getItem("auth_user_rol");
    setUserRol(user_rol);
  }, [authToken]);
  
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Efecto para limpiar el temporizador del menú desplegable
  useEffect(() => {
    return () => {
      if (closeTimer.current) {
        clearTimeout(closeTimer.current);
      }
    };
  }, []);

  const handleLogout = async () => {
    
    const token = localStorage.getItem("auth_token");
    if (token) {
      try {
        await cerrarSesion(token);
        localStorage.removeItem("auth_token");
        localStorage.removeItem("auth_user");
        localStorage.removeItem("auth_user_rol");
        setUserRol(null);
        // Aquí puedes redirigir al usuario a la página de inicio o actualizar el estado
        window.location.reload(); // Esto recargará la página, actualizando el estado del navbar
      } catch (error) {
        console.error("Error al cerrar sesión:", error);
      }
    }
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  // Nuevo estado para controlar la visibilidad del menú de usuario
  const [showUserDropdown, setShowUserDropdown] = useState(false);

  // Funciones para manejar el evento del ratón
  const handleMouseEnter = () => {
    // Si hay un temporizador activo al reentrar, lo cancelamos
    if (closeTimer.current) {
      clearTimeout(closeTimer.current);
      closeTimer.current = null;
    }
    setShowUserDropdown(true);
  };

  const handleMouseLeave = () => {
    // Iniciamos un temporizador que cerrará el menú desplegable después de medio segundo
    closeTimer.current = setTimeout(() => {
      setShowUserDropdown(false);
    }, 100); // 500 ms = medio segundo
  };

  const closeDropdown = () => {
    setShowUserDropdown(false);
  };

  return (
    <nav className="bg-white border-b border-gray-200 relative z-30">
      <ResumenCarrito />
      <div className="flex justify-between items-center py-4 px-4 md:px-10">
        <Hidden mdUp>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleMobileMenu}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>

        <div className="flex-1 md:flex md:justify-start">
          <Link to="/">
            <img src={logo3} className="h-16 mx-auto md:mx-0" alt="logo" />
          </Link>
        </div>

        <div className="hidden md:flex gap-4 flex-1 justify-center">
          <Link className="text-gray-800 hover:text-gray-500 font-bold" to="/">
            Inicio
          </Link>
          <Link
            className="text-gray-800 hover:text-gray-500 font-bold"
            to="/catalogo"
          >
            Catálogo
          </Link>
          {/* <Link
            className="text-gray-800 hover:text-gray-500 font-bold"
            to="/portafolio"
          >
            Portafolio
          </Link> */}
          
          <Link
            to="/faq"
            className="text-gray-800 hover:text-gray-500 font-bold"
          >
            Preguntas frecuentes
          </Link>

          <Link
            className="text-gray-800 hover:text-gray-500 font-bold"
            to="/contacto"
          >
            Contacto
          </Link>
        </div>

        <div className="hidden md:flex items-center gap-4 flex-1 justify-end">
          <div
            className="relative"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <button className="hover:brightness-110" onClick={toggleDropdown}>
            <FaRegUser size={24} className="text-gray-800 hover:text-gray-500" />
            </button>
            {showUserDropdown && (
              <div
                className="absolute right-0 bg-gray-50 shadow-lg min-w-max rounded-lg z-50"
                style={{ top: "100%" }} // Ajusta esto según la necesidad
              >
              {isAuthenticated ? (
                <>
                  <Link
                    className="block px-4 py-2 text-black hover:bg-gray-100 rounded-t-lg"
                    to="/cuenta"
                    onClick={closeDropdown}
                  >
                    Cuenta
                  </Link>
                  <button
                    onClick={handleLogout}
                    className="block px-4 py-2 text-black hover:bg-gray-100 w-full text-left"
                  >
                    Cerrar sesión
                  </button>
                  {userRol === "Admin" && (
                    <Link
                    className="block px-4 py-2 text-black hover:bg-gray-100 rounded-b-lg"
                      to="/administrar"
                      onClick={closeDropdown}
                    >
                      Administrar
                    </Link>
                  )}
                </>
              ) : (
                <>
                  <Link
                    className="block px-4 py-2 text-black hover:bg-gray-100"
                    to="/login"
                    onClick={closeDropdown}
                  >
                    Iniciar sesión
                  </Link>
                  <Link
                    className="block px-4 py-2 text-black hover:bg-gray-100"
                    to="/register"
                    onClick={closeDropdown}
                  >
                    Registrarse
                  </Link>
                </>
              )}
            </div>
            )}
          </div>
          <button
            className="hover:brightness-110 mr-2 md:mr-2"
            style={{ minWidth: "48px", marginTop: -3 }}
          >
            <Link to="/carrito">
              <MdOutlineShoppingCart size={26} className="text-gray-800 hover:text-gray-500"/>
            </Link>
          </button>
        </div>
      </div>

      <Drawer anchor="left" open={isMobileMenuOpen} onClose={toggleMobileMenu}>
        <List>
          {/* Título del Menú */}
          <ListItem button onClick={toggleMobileMenu}>
            <ListItemText
              primary={
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  Menú
                </Typography>
              }
            />
          </ListItem>
          {/* Los demás elementos del menú */}
          <ListItem button component={Link} to="/" onClick={toggleMobileMenu}>
            <ListItemText primary="Inicio" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/catalogo"
            onClick={toggleMobileMenu}
          >
            <ListItemText primary="Catálogo" />
          </ListItem>
          {/* <ListItem
            button
            component={Link}
            to="/portafolio"
            onClick={toggleMobileMenu}
          >
            <ListItemText primary="Portafolio" />
          </ListItem> */}
          
          <ListItem
            button
            component={Link}
            to="/faq"
            onClick={toggleMobileMenu}
          >
            <ListItemText primary="Preguntas Frecuentes" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/contacto"
            onClick={toggleMobileMenu}
          >
            <ListItemText primary="Contacto" />
          </ListItem>
          {isAuthenticated && (
            <>
              <ListItem
                button
                component={Link}
                to="/cuenta"
                onClick={toggleMobileMenu}
              >
                <ListItemText primary="Cuenta" />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  handleLogout();
                  toggleMobileMenu();
                }}
              >
                <ListItemText primary="Cerrar sesión" />
              </ListItem>
              {userRol === "Admin" && (
                <ListItem
                  button
                  component={Link}
                  to="/administrar"
                  onClick={toggleMobileMenu}
                >
                  <ListItemText primary="Administrar" />
                </ListItem>
              )}
            </>
          )}
          {!isAuthenticated && (
            <>
              <ListItem
                button
                component={Link}
                to="/login"
                onClick={toggleMobileMenu}
              >
                <ListItemText primary="Iniciar sesión" />
              </ListItem>
              <ListItem
                button
                component={Link}
                to="/register"
                onClick={toggleMobileMenu}
              >
                <ListItemText primary="Registrarse" />
              </ListItem>
            </>
          )}
          <ListItem
            button
            component={Link}
            to="/carrito"
            onClick={toggleMobileMenu}
          >
            <ListItemText primary="Carrito" />
          </ListItem>
          {/* Divider con márgenes */}
          <Divider style={{ margin: "10px 20px" }} />

          {/* Ítem de Instagram */}
          <ListItem
            button
            component="a"
            href={Instagram}
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconButton>
              <FaInstagram size="24" />
            </IconButton>
          </ListItem>
        </List>
      </Drawer>
    </nav>
  );
};

export default Navbar;
