import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { solicitarRestablecimientoContrasena } from "../services/Api"; // Asegúrate de implementar esta función en tu Api
import Footer from "../services/Footer";
import { FaSpinner } from "react-icons/fa";

const SolicitarRestablecimiento = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email.trim()) {
      setError("El correo electrónico es requerido");
      return;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setError("Correo electrónico inválido");
      return;
    }
    setIsLoading(true);
    try {
      await solicitarRestablecimientoContrasena({ email });
      setShowConfirmationModal(true);
    } catch (error) {
      setError("No se pudo procesar la solicitud. Por favor, inténtalo más tarde.");
      setShowErrorModal(true);
      setErrorMessage(error.message || "Error al solicitar el restablecimiento de contraseña");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow">
        <div className="flex items-center justify-center pt-32 pb-4">
          <div className="w-full max-w-md bg-white p-6 rounded shadow-md border border-gray-200">
            <h2 className="text-2xl font-semibold mb-4 text-center">
              Restablecer Contraseña
            </h2>
            {isLoading && (
              <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 flex justify-center items-center">
                <FaSpinner className="animate-spin text-4xl text-white" />
              </div>
            )}
            <form onSubmit={handleSubmit} className="space-y-4">
              {error && <p className="text-red-500 text-sm">{error}</p>}
              <input
                className="w-full p-2 border border-gray-300 rounded"
                type="email"
                name="email"
                value={email}
                onChange={handleChange}
                placeholder="Correo electrónico*"
              />
              <button
                type="submit"
                className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Solicitar Restablecimiento
              </button>
            </form>
          </div>
          {showConfirmationModal && (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 flex justify-center items-center">
              <div className="bg-white p-6 rounded-lg shadow-xl">
                <h3 className="text-lg font-semibold">
                  Solicitud Enviada
                </h3>
                <p>Si tu correo electrónico está registrado, recibirás un enlace para restablecer tu contraseña.</p>
                <div className="flex justify-end mt-4">
                  <button
                    onClick={() => {
                      setShowConfirmationModal(false);
                      navigate("/login"); // Cambiar según la ruta de inicio de sesión de tu aplicación
                    }}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Aceptar
                  </button>
                </div>
              </div>
            </div>
          )}
          {showErrorModal && (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 flex justify-center items-center">
              <div className="bg-white p-6 rounded-lg shadow-xl">
                <h3 className="text-lg font-semibold">Error</h3>
                <p>{errorMessage}</p>
                <div className="flex justify-end mt-4">
                  <button
                    onClick={() => setShowErrorModal(false)}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Aceptar
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SolicitarRestablecimiento;
