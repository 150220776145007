import React, { useContext, useState, useEffect, useCallback } from "react";
import { CarritoContext } from "../services/CarritoProvider";
import { GridLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import {
  verificarToken,
  realizarPago,
  verificarStockCarrito,
} from "../services/Api";
import { API_BASE_URL } from "../services/Config";

const Pago = () => {
  const { carrito, setCarrito, obtenerTotal } = useContext(CarritoContext);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [errores, setErrores] = useState({});
  const navigate = useNavigate();
  const usuarioLocalStorage =
    JSON.parse(localStorage.getItem("auth_user")) || {};
  const [datosUsuario, setDatosUsuario] = useState({
    name: usuarioLocalStorage.name || "",
    apellido: usuarioLocalStorage.apellido || "",
    email: "",
    numeroCelular: usuarioLocalStorage.numeroCelular || "",
    tipoEntrega: "",
    direccion: usuarioLocalStorage.direccion || "",
    ciudad: usuarioLocalStorage.ciudad || "",
    tipoPago: "",
    total: 0,
  });
  const [mostrarModalStock, setMostrarModalStock] = useState(false);
  //const [respuestaStock, setRespuestaStock] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const verificarStockAntesDePagar = useCallback(async () => {
    try {
      const respuesta = await verificarStockCarrito(carrito); // Asumiendo que tienes esta función en tus servicios
      if (!respuesta.sinCambios) {
        //setRespuestaStock(respuesta);
        setMostrarModalStock(true);
        return true;
      }
      return false;
    } catch (error) {
      console.error("Error al verificar stock:", error);
    }
  }, [carrito]);

  const comprobarToken = useCallback(async () => {
    setIsLoading(true);
    try {
      const respuesta = await verificarToken();
      verificarStockAntesDePagar();

      if (respuesta) {
        setIsAuthenticated(true);
        const user = JSON.parse(localStorage.getItem("auth_user"));

        setDatosUsuario({
          name: user.name,
          apellido: user.apellido,
          numeroCelular: user.numeroCelular || "",
          direccion: user.direccion || "",
          ciudad: user.ciudad || "",
          // ...resto de los datos
        });
      } else {
        setIsAuthenticated(false);
      }
    } catch (error) {
      console.error("Error al verificar token:", error);
    } finally {
      setIsLoading(false); // Finalizar la carga independientemente del resultado
    }
  }, [verificarStockAntesDePagar]);

  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem("carrito")) || [];
    setCarrito(storedCart);
  }, [setCarrito]);

  useEffect(() => {
    if (carrito.length === 0) {
      //navigate("/");
    }

    comprobarToken();
  }, [comprobarToken, navigate, carrito]);

  const handleChangeDatosUsuario = (e) => {
    setDatosUsuario((datosUsuarioPrevios) => ({
      ...datosUsuarioPrevios,
      [e.target.name]: e.target.value,
    }));
  };

  const handlePagoClick = async () => {
    // Obtener el carrito del localStorage
    const storedCart = JSON.parse(localStorage.getItem("carrito")) || [];

    // Comparar el carrito del localStorage con el carrito en el contexto
    const isCartSame = JSON.stringify(storedCart) === JSON.stringify(carrito);

    if (!isCartSame) {
      // Si los carritos no son iguales, alertar al usuario y redirigir a la página del carrito
      alert(
        "El carrito ha sido modificado. Por favor, revisa tu carrito antes de proceder con la compra."
      );
      navigate("/carrito");
      return;
    }
    const erroresPago = validarDatosPago();
    if (Object.keys(erroresPago).length === 0) {
      setErrores({});
      const verificacion = await verificarStockAntesDePagar();

      if (!verificacion) {
        const nuevoDatosUsuario = { ...datosUsuario, total: obtenerTotal() };
        try {
          const respuestaPago = await realizarPago(nuevoDatosUsuario, carrito);

          if (respuestaPago && respuestaPago.url) {
            window.location.href = respuestaPago.url;
          } else {
            console.error("Error al obtener URL de pago");
          }
        } catch (error) {
          console.error("Error al procesar el pago:", error);
        }
      } else {
        console.log("Error en la validación");
      }
    }
    setErrores(erroresPago);
  };

  const validarDatosPago = () => {
    let nuevosErrores = {};

    if (!datosUsuario.name || datosUsuario.name.trim() === "") {
      nuevosErrores.name = "El nombre es requerido";
    }

    if (!datosUsuario.apellido || datosUsuario.apellido.trim() === "") {
      nuevosErrores.apellido = "El apellido es requerido";
    }

    if (!isAuthenticated) {
      if (!datosUsuario.email || datosUsuario.email.trim() === "") {
        nuevosErrores.email = "El correo electrónico es requerido";
      } else if (!/\S+@\S+\.\S+/.test(datosUsuario.email)) {
        nuevosErrores.email = "El formato del correo electrónico es inválido";
      }
    }

    if (
      !datosUsuario.numeroCelular ||
      datosUsuario.numeroCelular.trim() === ""
    ) {
      nuevosErrores.numeroCelular = "El número de contacto es requerido";
    }

    if (!datosUsuario.tipoEntrega) {
      nuevosErrores.tipoEntrega = "El tipo de entrega es requerido";
    }

    if (!datosUsuario.direccion || datosUsuario.direccion.trim() === "") {
      nuevosErrores.direccion = "La dirección de entrega es requerida";
    }

    if (!datosUsuario.ciudad || datosUsuario.ciudad.trim() === "") {
      nuevosErrores.ciudad = "La ciudad de entrega es requerida";
    }

    return nuevosErrores;
  };

  return (
    <>
      {isLoading ? (
        <div className="flex justify-center items-center h-screen">
          <GridLoader color="#4A90E2" />
        </div>
      ) : (
        <div className="container mx-auto mt-10 p-4 flex flex-wrap justify-center items-start gap-4">
          <div className="flex flex-col space-y-4 w-full lg:w-1/2 max-w-md">
            <h2 className="text-xl font-semibold">Datos de Entrega y Pago</h2>
            {!isAuthenticated && (
              <p className="text-blue-500 text-sm">
                <a href="/login">Inicia sesión</a> o{" "}
                <a href="/register">regístrate</a>
              </p>
            )}
            <div className="flex flex-col space-y-4">
              {/* Nombre */}
              <div>
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="name"
                >
                  Nombre
                </label>
                <input
                  type="text"
                  name="name"
                  value={datosUsuario.name}
                  onChange={handleChangeDatosUsuario}
                  placeholder="Tu nombre"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {errores.name && (
                  <p className="text-red-500 text-sm">{errores.name}</p>
                )}
              </div>

              {/* Apellido */}
              <div>
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="apellido"
                >
                  Apellido
                </label>
                <input
                  type="text"
                  name="apellido"
                  value={datosUsuario.apellido}
                  onChange={handleChangeDatosUsuario}
                  placeholder="Tu apellido"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {errores.apellido && (
                  <p className="text-red-500 text-sm">{errores.apellido}</p>
                )}
              </div>

              {!isAuthenticated && (
                <div>
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="email"
                  >
                    Correo Electrónico
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={datosUsuario.email}
                    onChange={handleChangeDatosUsuario}
                    placeholder="Tu correo electrónico"
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                  {errores.email && (
                    <p className="text-red-500 text-sm">{errores.email}</p>
                  )}
                </div>
              )}

              {/* Número de contacto */}
              <div>
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="numeroCelular"
                >
                  Número de Contacto
                </label>
                <input
                  type="tel"
                  name="numeroCelular"
                  value={datosUsuario.numeroCelular}
                  onChange={handleChangeDatosUsuario}
                  placeholder="Número de contacto"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {errores.numeroCelular && (
                  <p className="text-red-500 text-sm">
                    {errores.numeroCelular}
                  </p>
                )}
              </div>

              {/* Tipo de Entrega */}
              <div>
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="tipoEntrega"
                >
                  Tipo de Entrega
                </label>
                <select
                  name="tipoEntrega"
                  value={datosUsuario.tipoEntrega}
                  onChange={handleChangeDatosUsuario}
                  className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value="">Seleccione tipo de entrega</option>
                  <option value="Retiro">Retiro</option>
                  <option value="Domicilio">Domicilio</option>
                </select>
                {errores.tipoEntrega && (
                  <p className="text-red-500 text-sm">{errores.tipoEntrega}</p>
                )}
              </div>

              {/* Dirección de entrega */}
              <div>
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="direccion"
                >
                  Dirección de Entrega
                </label>
                <input
                  type="text"
                  name="direccion"
                  value={datosUsuario.direccion}
                  onChange={handleChangeDatosUsuario}
                  placeholder="Dirección de entrega"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {errores.direccion && (
                  <p className="text-red-500 text-sm">{errores.direccion}</p>
                )}
              </div>

              {/* Ciudad de Entrega */}
              <div>
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="ciudad"
                >
                  Ciudad de Entrega
                </label>
                <input
                  type="text"
                  name="ciudad"
                  value={datosUsuario.ciudad}
                  onChange={handleChangeDatosUsuario}
                  placeholder="Ciudad de entrega"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {errores.ciudad && (
                  <p className="text-red-500 text-sm">{errores.ciudad}</p>
                )}
              </div>
            </div>
          </div>

          {/* Resumen de la compra */}
          <div
            className="w-full lg:w-1/3 max-w-md p-6"
            style={{ backgroundColor: "#f1f5f9" }}
          >
            <h2 className="text-2xl font-semibold mb-4">
              Resumen de tu compra
            </h2>
            {/* Iterar sobre los productos en el carrito y mostrar los detalles */}
            <div
              className={`overflow-y-auto ${
                carrito.length > 5 ? "h-80" : "h-auto"
              }`}
              style={{ maxHeight: carrito.length > 5 ? "320px" : "none" }}
            >
              {carrito.length === 0 ? (
                <div className="text-center py-4">
                  No hay productos en el carrito.
                </div>
              ) : (
                carrito.map((producto, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between border-b-2 mb-2"
                  >
                    <img
                      src={API_BASE_URL + producto.imagenes[0].uri_foto}
                      alt={producto.nombre}
                      className="w-20 h-20 object-cover mr-4"
                    />
                    <div className="flex-grow">
                      <h5 className="text-lg font-bold">{producto.nombre}</h5>
                      <p className="text-gray-600">
                        Cantidad: {producto.cantidad}
                      </p>
                      <p className="text-gray-600">
                        Precio: $
                        {Number(producto.precio).toLocaleString("es-CL")}
                      </p>
                    </div>
                  </div>
                ))
              )}
            </div>

            <div className="flex justify-between items-center my-6">
              <span className="text-lg font-bold">Total</span>
              <span className="text-lg font-bold text-gray-800">
                ${Number(obtenerTotal()).toLocaleString("es-CL")}
              </span>
            </div>

            <button
              onClick={handlePagoClick}
              disabled={carrito.length === 0} // Deshabilita el botón si el carrito está vacío
              className={`mt-4 w-full ${
                carrito.length === 0
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-blue-600 hover:bg-blue-700"
              } text-white font-bold py-2 px-4 rounded-lg`}
            >
              Pagar
            </button>
          </div>
          {mostrarModalStock && (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 flex justify-center items-center">
              <div className="bg-white p-6 rounded-lg shadow-xl w-full max-w-md mx-4 md:mx-0">
                <div className="text-center">
                  <svg
                    className="mx-auto h-12 w-12 text-red-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <h2 className="text-lg leading-6 font-medium text-gray-900 mt-4">
                    Problemas con el Stock
                  </h2>
                  <p className="mt-2 text-gray-600">
                    Hemos encontrado algunos problemas con el stock de los
                    productos en tu carrito.
                  </p>
                  {/* Muestra los detalles de respuestaStock aquí */}
                  <div className="mt-4">
                    <button
                      onClick={() => navigate("/carrito")}
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-150 ease-in-out"
                    >
                      Revisar Carrito
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        // El resto de tu JSX cuando no está cargando
      )}
    </>
  );
};

export default Pago;
