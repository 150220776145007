import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { obtenerProductoPorNombreYPublicId } from "../services/Api";
import { API_BASE_URL } from "../services/Config";
import { CarritoContext } from "../services/CarritoProvider";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";
import { GridLoader } from "react-spinners";
import Footer from "../services/Footer";

const DetalleProducto = () => {
  let { nombreProducto, publicIdProducto } = useParams();
  const [producto, setProducto] = useState(null);
  const [cantidad, setCantidad] = useState(1);
  const [imagenSeleccionada, setImagenSeleccionada] = useState(0); // Nuevo estado para la imagen seleccionada
  const { añadirProducto } = useContext(CarritoContext);
  const [mostrarBotones, setMostrarBotones] = useState(false);

  useEffect(() => {
    const cargarProducto = async () => {
      try {
        
        const productoObtenido = await obtenerProductoPorNombreYPublicId(
          nombreProducto,
          publicIdProducto
        );
        setProducto(productoObtenido);
        window.scrollTo({ top: 0, behavior: "smooth" });
        
      } catch (error) {
        console.error("Error al obtener productos:", error);
      }
    };

    cargarProducto();
  }, [nombreProducto, publicIdProducto]);

  const añadirAlCarrito = () => {
    if (producto && cantidad > 0) {
      añadirProducto({ ...producto, cantidad: Number(cantidad) });
    }
  };

  // Manejador para cambiar la imagen principal
  const seleccionarImagen = (index) => {
    setImagenSeleccionada(index);
  };

  const avanzarImagen = () => {
    if (producto && producto.imagenes.length > 0) {
      setImagenSeleccionada(
        (prevIndex) => (prevIndex + 1) % producto.imagenes.length
      );
    }
  };

  const retrocederImagen = () => {
    if (producto && producto.imagenes.length > 0) {
      setImagenSeleccionada(
        (prevIndex) =>
          (prevIndex - 1 + producto.imagenes.length) % producto.imagenes.length
      );
    }
  };

  const incrementarCantidad = () => {
    setCantidad((prevCantidad) => prevCantidad + 1);
  };

  const decrementarCantidad = () => {
    setCantidad((prevCantidad) => (prevCantidad > 1 ? prevCantidad - 1 : 1));
  };

  if (!producto) {
    return (
      <div className="flex justify-center items-center h-64">
        {" "}
        {/* Ajusta la altura según sea necesario */}
        <GridLoader color="#123abc" size={15} />{" "}
        {/* Puedes personalizar el color y el tamaño */}
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen">
      <div className="container mx-auto flex-grow py-12 px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8 items-center lg:ml-44">
          <div className="flex flex-col items-center justify-center ">
            {producto.imagenes && producto.imagenes.length > 0 ? (
              <>
                <div className="mx-auto lg:ml-20">
                  <div className="max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl mx-auto relative">
                    <div
                      className="max-w-xs sm:max-w-sm md:max-w-md lg:w-10/12 lg:h-10/12 md:ml-20 lg:max-w-lg xl:max-w-xl mx-auto relative"
                      onMouseEnter={() => setMostrarBotones(true)}
                      onMouseLeave={() => setMostrarBotones(false)}
                    >
                      {mostrarBotones && (
                        <button
                          onClick={retrocederImagen}
                          className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 text-gray-800 text-2xl p-4 cursor-pointer hover:bg-opacity-75 z-10 "
                        >
                          &#60;
                        </button>
                      )}
                      <img
                        src={
                          API_BASE_URL +
                          producto.imagenes[imagenSeleccionada].uri_foto
                        }
                        alt={producto.nombre}
                        className="object-cover w-screen lg:h-full rounded-lg shadow-lg "
                      />
                      {mostrarBotones && (
                        <button
                          onClick={avanzarImagen}
                          className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 text-gray-800 text-2xl p-4 cursor-pointer hover:bg-opacity-75 z-10"
                        >
                          &#62;
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mx-auto lg:ml-40 relative">
                  <div
                    className={`max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl ${
                      producto.imagenes.length >= 4 ? "lg:w-[97%]" : ""
                    }`}
                  >
                    <div className="mt-4 overflow-x-auto">
                      <div className="flex space-x-2 snap-x snap-mandatory my-2">
                        {producto.imagenes.map((imagen, index) => (
                          <img
                            key={index}
                            src={API_BASE_URL + imagen.uri_foto}
                            alt={`Thumbnail ${index}`}
                            onClick={() => seleccionarImagen(index)}
                            className={`flex-none object-cover h-24 w-24 mx-1 md:w-28 md:h-28 cursor-pointer rounded-lg ${
                              index === imagenSeleccionada
                                ? "ring-2 ring-blue-500"
                                : ""
                            }`}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="flex items-center justify-center w-full bg-gray-200 rounded-lg shadow-lg md:w-96 md:h-96">
                Imagen no disponible
              </div>
            )}
          </div>

          <div className="mt-4 md:mt-0 md:mb-28 md:ml-0 px-4 sm:px-6">
            <h1 className="text-3xl font-bold mb-4">{producto.nombre}</h1>
            <p className="text-xl text-gray-800 mb-4">
              Precio: ${Number(producto.precio).toLocaleString("es-CL")}
            </p>
            <p className="text-gray-600 mb-4">
              Stock disponible: {producto.stock - producto.stockReserva}
            </p>
            <div className="flex items-center mb-4">
              <div className="flex items-center justify-center border border-gray-200 p-0.5 rounded-lg">
                <IconButton
                  onClick={decrementarCantidad}
                  size="small"
                  className="text-gray-500"
                >
                  <RemoveIcon />
                </IconButton>
                <input
                  type="text"
                  value={cantidad}
                  onChange={(e) => {
                    const nuevaCantidad = parseInt(e.target.value, 10);
                    if (nuevaCantidad >= 1) {
                      setCantidad(nuevaCantidad);
                    }
                  }}
                  className="text-center w-10"
                  readOnly
                />
                <IconButton
                  onClick={incrementarCantidad}
                  size="small"
                  className="text-gray-500"
                >
                  <AddIcon />
                </IconButton>
              </div>
            </div>
            <button
              onClick={añadirAlCarrito}
              className="px-6 py-3 bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700 transition duration-200"
            >
              Añadir al carrito
            </button>
          </div>
        </div>
        <div className="mt-8 md:mt-12 md:ml-[calc(50%-27rem)] px-4 sm:px-6">
          <h2 className="text-2xl font-bold mb-4">Descripción</h2>
          <div className="md:max-w-[calc(100%-17rem)]">
            <p className="text-gray-700">{producto.descripcion}</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DetalleProducto;
