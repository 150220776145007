import React, { useEffect, useState, useCallback } from "react";
import {
  obtenerProductosActivos,
  obtenerCategoriasActivas,
  obtenerMaterialesActivos,
} from "../services/Api";
import { useNavigate, useLocation } from "react-router-dom";
import { API_BASE_URL } from "../services/Config";
import Footer from "../services/Footer";
import { GridLoader } from "react-spinners";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import debounce from 'lodash.debounce';

const Catalogo = () => {
  const [productos, setProductos] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [materiales, setMateriales] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [paginaActual, setPaginaActual] = useState(parseInt(searchParams.get('pagina')) || 1);
  const [filtroCategoria, setFiltroCategoria] = useState(searchParams.get('filtroCategoria') || 'todos');
  const [filtroMaterial, setFiltroMaterial] = useState(searchParams.get('filtroMaterial') || 'todos');
  const [isLoading, setIsLoading] = useState(true);
  const [paginasTotales, setPaginasTotales] = useState(0);
  const [mostrarFiltros, setMostrarFiltros] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const pagina = parseInt(searchParams.get('pagina')) || 1;
    const categoria = searchParams.get('filtroCategoria') || 'todos';
    const material = searchParams.get('filtroMaterial') || 'todos';
  
    setPaginaActual(pagina);
    setFiltroCategoria(categoria);
    setFiltroMaterial(material);
  }, [location.search]); // Escucha activamente los cambios en la consulta de URL.
  

  const cargarProductos = useCallback(debounce(async (pagina, categoria, material) => {
    setIsLoading(true);
    try {
      const respuesta = await obtenerProductosActivos(pagina, categoria, material);
      setProductos(respuesta.data || []);
      setPaginasTotales(respuesta.last_page || 0);
    } catch (error) {
      console.error("Error al obtener los productos:", error);
    } finally {
      setIsLoading(false);
    }
  }, 100), []);

  useEffect(() => {
    // Llamamos a la función debounced pasando los argumentos necesarios
    cargarProductos(paginaActual, filtroCategoria, filtroMaterial);
    return () => cargarProductos.cancel(); // Cancela la ejecución debounced si el componente se desmonta o si las dependencias cambian
  }, [paginaActual, filtroCategoria, filtroMaterial, cargarProductos]);

  useEffect(() => {
    cargarCategorias();
    cargarMateriales();
  }, []); // Este efecto solo se ejecutará una vez, cuando el componente se monte.
  

  const cargarCategorias = async () => {
    try {
      const respuesta = await obtenerCategoriasActivas();
      setCategorias(respuesta);
    } catch (error) {
      console.error("Error al obtener las categorías:", error);
    }
  };

  const cargarMateriales = async () => {
    try {
      const respuesta = await obtenerMaterialesActivos();
      setMateriales(respuesta);
    } catch (error) {
      console.error("Error al obtener los materiales:", error);
    }
  };

  const irADetalleProducto = (nombreProducto, publicIdProducto) => {
    const nombreProductoUrl = nombreProducto.split(" ").join("-").toLowerCase();
    navigate(`/catalogo/${nombreProductoUrl}/${publicIdProducto}`);
  };

  // Actualizar cuando cambia la página
const paginar = (numeroDePagina) => {
  navigate(`/catalogo?pagina=${numeroDePagina}&filtroCategoria=${filtroCategoria}&filtroMaterial=${filtroMaterial}`);
  window.scrollTo({ top: 0, behavior: "smooth" });
};

// Actualizar cuando cambian los filtros
const handleFilterByCategory = (event) => {
  setFiltroCategoria(event.target.value);
  navigate(`/catalogo?pagina=1&filtroCategoria=${event.target.value}&filtroMaterial=${filtroMaterial}`);
};

const handleFilterByMaterial = (event) => {
  setFiltroMaterial(event.target.value);
  navigate(`/catalogo?pagina=1&filtroCategoria=${filtroCategoria}&filtroMaterial=${event.target.value}`);
};

  const calcularRangoPaginas = () => {
    let inicio = Math.max(paginaActual - 1, 1);
    let fin = Math.min(inicio + 2, paginasTotales);

    if (fin === paginasTotales) {
      inicio = Math.max(fin - 2, 1);
    }

    return Array.from({ length: fin - inicio + 1 }, (_, i) => inicio + i);
  };

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow">
        <div className="container mx-auto px-4 py-8 max-w-6xl">
          <h1 className="text-4xl font-bold text-center mb-10">Catálogo</h1>
          {/* Versión de escritorio */}
          <div className="hidden md:block">
            <div className="flex justify-between mb-8">
              <div className="flex-grow">
                {/* Filtro por categoría */}
                <select
                  value={filtroCategoria} // Asegúrate de que el valor del select está controlado por React
                  className="border rounded p-3"
                  onChange={handleFilterByCategory}
                >
                  <option value="">Filtrar por categoría</option>
                  {categorias.map((categoria) => (
                    <option key={categoria.id} value={categoria.id}>
                      {categoria.nombre}
                    </option>
                  ))}
                </select>

                {/* Filtro por material */}
                <select
                  value={filtroMaterial} // Asegúrate de que el valor del select está controlado por React
                  className="border rounded p-3 ml-2"
                  onChange={handleFilterByMaterial}
                >
                  <option value="">Filtrar por material</option>
                  {materiales.map((material) => (
                    <option key={material.id} value={material.id}>
                      {material.nombre}
                    </option>
                  ))}
                </select>

                {/* Botón para limpiar filtros */}
                <button
                  onClick={() => {
                    setFiltroCategoria("");
                    setFiltroMaterial("");
                    cargarProductos(); // Asegúrate de re-cargar los productos con filtros limpios
                  }}
                  className="ml-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition duration-300 p-3"
                >
                  Limpiar filtros
                </button>
              </div>
              <div className="text-right">
                <div className="py-2">
                  <nav>
                    <ul className="flex justify-center">
                      <li>
                        <button
                          onClick={() => paginar(Math.max(1, paginaActual - 1))}
                          disabled={paginaActual === 1}
                          className="mx-1 px-1 py-1 border rounded bg-white text-black hover:bg-gray-200 disabled:bg-gray-200 disabled:text-gray-400"
                        >
                          <ArrowBackIosNewIcon fontSize="small" />
                        </button>
                      </li>
                      {calcularRangoPaginas().map((numero) => (
                        <li key={numero} className="mx-1">
                          <button
                            onClick={() => paginar(numero)}
                            className={`mx-1 px-2 py-1 border rounded ${
                              paginaActual === numero
                                ? "bg-black text-white"
                                : "bg-white text-black hover:bg-gray-200"
                            }`}
                          >
                            {numero}
                          </button>
                        </li>
                      ))}
                      <li>
                        <button
                          onClick={() =>
                            paginar(Math.min(paginasTotales, paginaActual + 1))
                          }
                          disabled={paginaActual === paginasTotales}
                          className="mx-1 px-1 py-1 border rounded bg-white text-black hover:bg-gray-200 disabled:bg-gray-200 disabled:text-gray-400"
                        >
                          <ArrowForwardIosIcon fontSize="small" />
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div className="md:hidden">
            {/* Botón para mostrar/ocultar filtros */}
            <button
              onClick={() => setMostrarFiltros(!mostrarFiltros)}
              className="mb-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition duration-300 w-full"
            >
              Filtros
            </button>

            {mostrarFiltros && (
              <div>
                {/* Filtros móviles */}
                <select
                  value={filtroCategoria}
                  className="border rounded p-3 w-full mb-4"
                  onChange={handleFilterByCategory}
                >
                  <option value="">Filtrar por categoría</option>
                  {categorias.map((categoria) => (
                    <option key={categoria.id} value={categoria.id}>
                      {categoria.nombre}
                    </option>
                  ))}
                </select>

                <select
                  value={filtroMaterial}
                  className="border rounded p-3 w-full mb-4"
                  onChange={handleFilterByMaterial}
                >
                  <option value="">Filtrar por material</option>
                  {materiales.map((material) => (
                    <option key={material.id} value={material.id}>
                      {material.nombre}
                    </option>
                  ))}
                </select>

                {/* Botón para limpiar filtros */}
                <button
                  onClick={() => {
                    setFiltroCategoria("");
                    setFiltroMaterial("");
                    cargarProductos(); // Asegurarse de re-cargar los productos con filtros limpios
                  }}
                  className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition duration-300 w-full"
                >
                  Limpiar filtros
                </button>
              </div>
            )}

            {/* Paginador Móvil Compactado */}
            <div className="py-2">
              <nav>
                <ul className="flex justify-center items-center space-x-1">
                  <li>
                    <button
                      onClick={() => paginar(Math.max(1, paginaActual - 1))}
                      disabled={paginaActual === 1}
                      className="px-2 py-1 text-sm border rounded bg-white text-black hover:bg-gray-200 disabled:bg-gray-200 disabled:text-gray-400"
                    >
                      <ArrowBackIosNewIcon fontSize="small" />
                    </button>
                  </li>
                  {calcularRangoPaginas().map((numero) => (
                    <li key={numero}>
                      <button
                        onClick={() => paginar(numero)}
                        className={`px-2 py-1 text-sm border rounded ${
                          paginaActual === numero
                            ? "bg-black text-white"
                            : "bg-white text-black hover:bg-gray-200"
                        }`}
                      >
                        {numero}
                      </button>
                    </li>
                  ))}
                  <li>
                    <button
                      onClick={() =>
                        paginar(Math.min(paginasTotales, paginaActual + 1))
                      }
                      disabled={paginaActual === paginasTotales}
                      className="px-2 py-1 text-sm border rounded bg-white text-black hover:bg-gray-200 disabled:bg-gray-200 disabled:text-gray-400"
                    >
                      <ArrowForwardIosIcon fontSize="small" />
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>

          {
  isLoading ? (
    <div className="flex justify-center items-center h-64">
      <GridLoader color="#123abc" size={15} />
    </div>
  ) : productos.length > 0 ? (
    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
      {productos.map((producto) => (
  <a
    key={producto.id}
    href={`/catalogo/${encodeURIComponent(producto.nombre)}/${producto.publicId}`}
    className="group relative p-1 md:p-2 cursor-pointer transform transition duration-300 ease-in-out hover:scale-105 rounded"
    onClick={(e) => {
      e.preventDefault();
      irADetalleProducto(producto.nombre, producto.publicId);
    }}
  >
    <div
      className="w-full"
      style={{ maxWidth: "350px", margin: "0 auto" }}
    >
      {producto.imagenes && producto.imagenes.length > 0 ? (
        <img
          src={`${API_BASE_URL}${producto.imagenes[0].uri_foto}`}
          alt={producto.nombre}
          className="w-full h-full object-cover"
        />
      ) : (
        <div className="flex justify-center items-center h-full">Sin Imagen</div>
      )}
    </div>
    <div className="text-center mt-4">
      <h3 className="text-lg font-semibold hover:text-blue-500">
        {producto.nombre}
      </h3>
      <p className="text-gray-600">
        ${Number(producto.precio).toLocaleString("es-CL")}
      </p>
    </div>
  </a>
))}

    </div>
  ) : (
    <div className="text-center py-10">
      <p className="text-lg">No se encontraron productos.</p>
    </div>
  )
}

          <div className="py-2">
            <nav>
              <ul className="flex justify-center">
                <li>
                  <button
                    onClick={() => paginar(Math.max(1, paginaActual - 1))}
                    disabled={paginaActual === 1}
                    className="mx-1 px-1 py-1 border rounded bg-white text-black hover:bg-gray-200 disabled:bg-gray-200 disabled:text-gray-400"
                  >
                    <ArrowBackIosNewIcon fontSize="small" />
                  </button>
                </li>
                {calcularRangoPaginas().map((numero) => (
                  <li key={numero} className="mx-1">
                    <button
                      onClick={() => paginar(numero)}
                      className={`mx-1 px-2 py-1 border rounded ${
                        paginaActual === numero
                          ? "bg-black text-white"
                          : "bg-white text-black hover:bg-gray-200"
                      }`}
                    >
                      {numero}
                    </button>
                  </li>
                ))}
                <li>
                  <button
                    onClick={() =>
                      paginar(Math.min(paginasTotales, paginaActual + 1))
                    }
                    disabled={paginaActual === paginasTotales}
                    className="mx-1 px-1 py-1 border rounded bg-white text-black hover:bg-gray-200 disabled:bg-gray-200 disabled:text-gray-400"
                  >
                    <ArrowForwardIosIcon fontSize="small" />
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Catalogo;
