import React, { useState, useEffect } from "react";
import { obtenerPreguntasFrecuentesActivas } from "../services/Api";
import Footer from "../services/Footer";
import { GridLoader } from "react-spinners";
import Collapse from '@mui/material/Collapse';

const Preguntas = () => {
  const [preguntas, setPreguntas] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const cargarPreguntas = async () => {
      setIsLoading(true);
      try {
        const preguntasObtenidas = await obtenerPreguntasFrecuentesActivas();
        setPreguntas(preguntasObtenidas.map((p) => ({ ...p, abierta: false })));
        setIsLoading(false);
      } catch (error) {
        console.error("Error al obtener preguntas:", error);
        setIsLoading(false);
      }
    };

    cargarPreguntas();
  }, []);

  const toggleDescripcion = (index) => {
    setPreguntas(
      preguntas.map((pregunta, i) => {
        if (i === index) {
          return { ...pregunta, abierta: !pregunta.abierta };
        }
        return pregunta;
      })
    );
  };

  return (
    <div className="flex flex-col min-h-screen">
      {" "}
      {/* Flex container para toda la página */}
      <main className="flex-grow">
        <div className="mx-auto pt-12 pb-10 px-4 max-w-4xl">
          {" "}
          {/* Añadir padding superior e inferior */}
          <h2 className="text-4xl font-bold mb-8 text-center">
            Preguntas frecuentes
          </h2>{" "}
          {/* Margen aumentado entre el título y las preguntas */}
          {isLoading ? (
            <div className="flex justify-center items-center h-64">
              {" "}
              {/* Ajusta la altura según sea necesario */}
              <GridLoader color="#123abc" size={15} />{" "}
              {/* Puedes personalizar el color y el tamaño */}
            </div>
          ) : (
            <div>
          {preguntas.map((pregunta, index) => (
            <div key={pregunta.id} className="border-b-2 mb-2">
              <button
                className="w-full text-left px-4 py-3 flex justify-between items-center"
                onClick={() => toggleDescripcion(index)}
              >
                <span className="text-lg font-bold">{pregunta.titulo}</span>
                <span>{pregunta.abierta ? "▲" : "▼"}</span>
              </button>
              <Collapse in={pregunta.abierta}>
                <div className="px-4 py-2 text-lg font-light">
                  {pregunta.descripcion}
                </div>
              </Collapse>
            </div>
          ))}
          </div>
          )}
        </div>
      </main>
      <Footer /> {/* Footer al final de la página */}
    </div>
  );
};

export default Preguntas;
