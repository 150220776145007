import React, { useState, useEffect } from "react";
import { obtenerSolicitudesDeContacto } from "../../services/Api";

const SolicitudesContacto = () => {
  const [solicitudes, setSolicitudes] = useState([]);
  const [paginaActual, setPaginaActual] = useState(1);
  const solicitudesPorPagina = 10; // Ajusta este número según necesites
  const indiceUltimo = paginaActual * solicitudesPorPagina;
  const indicePrimero = indiceUltimo - solicitudesPorPagina;
  const solicitudesActuales = solicitudes.slice(indicePrimero, indiceUltimo);
  const totalPaginas = Math.ceil(solicitudes.length / solicitudesPorPagina);

  useEffect(() => {
    const cargarSolicitudes = async () => {
      try {
        const solicitudesObtenidas = await obtenerSolicitudesDeContacto();
        setSolicitudes(solicitudesObtenidas);
      } catch (error) {
        console.error("Error al obtener solicitudes de contacto:", error);
      }
    };

    cargarSolicitudes();
  }, []);

  const toggleDetalle = (id) => {
    setSolicitudes(
      solicitudes.map((solicitud) => {
        if (solicitud.id === id) {
          return { ...solicitud, abierta: !solicitud.abierta };
        }
        return solicitud;
      })
    );
  };

  const irAPaginaSiguiente = () => {
    setPaginaActual(Math.min(paginaActual + 1, totalPaginas));
  };

  const irAPaginaAnterior = () => {
    setPaginaActual(Math.max(paginaActual - 1, 1));
  };

  return (
    <div className="container mx-auto p-4">
      <div className="bg-white p-4 rounded shadow-md">
        <h2 className="text-xl font-semibold mb-4">Solicitudes de Contacto</h2>
        {solicitudesActuales.map((solicitud) => (
          <div key={solicitud.id} className="border-b border-gray-200 py-4">
            <div className="flex justify-between items-center">
              <h3
                className="text-lg font-semibold cursor-pointer"
                onClick={() => toggleDetalle(solicitud.id)}
              >
                {`${solicitud.nombre} - ${new Date(
                  solicitud.fecha
                ).toLocaleString()}`}
              </h3>
            </div>
            {solicitud.abierta && (
              <div className="mt-2 text-gray-600">
                <p>Correo: {solicitud.correo}</p>
                <p>Fecha: {new Date(solicitud.fecha).toLocaleDateString()}</p>
                <p>Hora: {new Date(solicitud.fecha).toLocaleTimeString()}</p>
                <p>
                  Número Celular: {solicitud.numeroCelular || "No disponible"}
                </p>
                <p>Instagram: {solicitud.instagram || "No disponible"}</p>
                <p>Mensaje: {solicitud.mensaje}</p>
              </div>
            )}
          </div>
        ))}
        <div className="flex justify-between items-center mt-8">
          <button
            onClick={irAPaginaAnterior}
            disabled={paginaActual === 1}
            className={`px-4 py-2 rounded-lg text-white ${
              paginaActual === 1
                ? "bg-gray-500"
                : "bg-blue-500 hover:bg-blue-700"
            } transition-colors duration-150`}
          >
            Anterior
          </button>
          <span className="text-lg font-semibold">
            Página {paginaActual} de {totalPaginas}
          </span>
          <button
            onClick={irAPaginaSiguiente}
            disabled={paginaActual === totalPaginas}
            className={`px-4 py-2 rounded-lg text-white ${
              paginaActual === totalPaginas
                ? "bg-gray-500"
                : "bg-blue-500 hover:bg-blue-700"
            } transition-colors duration-150`}
          >
            Siguiente
          </button>
        </div>
      </div>
    </div>
  );
};

export default SolicitudesContacto;
