import React, { useState, useEffect } from "react";
import {
  obtenerCategorias,
  agregarCategoria,
  editarCategoria,
} from "../../services/Api";

const Categorias = () => {
  const [categorias, setCategorias] = useState([]);
  const [nuevaCategoria, setNuevaCategoria] = useState({
    nombre: "",
    descripcion: "",
  });
  const [mostrarModalAgregar, setMostrarModalAgregar] = useState(false);
  const [mostrarModalEditar, setMostrarModalEditar] = useState(false);
  const [categoriaAEditar, setCategoriaAEditar] = useState(null);
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [messageType, setMessageType] = useState("success");
  const [mostrarModalConfirmacion, setMostrarModalConfirmacion] =
    useState(false);
  const [categoriaADeshabilitar, setCategoriaADeshabilitar] = useState(null);
  const [
    mostrarModalConfirmacionHabilitar,
    setMostrarModalConfirmacionHabilitar,
  ] = useState(false);
  const [categoriaAHabilitar, setCategoriaAHabilitar] = useState(null);
  const [paginaActual, setPaginaActual] = useState(1);
  const categoriasPorPagina = 10; // Ajusta este número según necesites
  const indiceUltimo = paginaActual * categoriasPorPagina;
  const indicePrimero = indiceUltimo - categoriasPorPagina;
  const categoriasActuales = categorias.slice(indicePrimero, indiceUltimo);
  const totalPaginas = Math.ceil(categorias.length / categoriasPorPagina);

  useEffect(() => {
    const cargarCategorias = async () => {
      try {
        const categoriasObtenidas = await obtenerCategorias();
        setCategorias(categoriasObtenidas);
      } catch (error) {
        console.error("Error al obtener categorías:", error);
      }
    };

    cargarCategorias();
  }, []);

  useEffect(() => {
    let timerId;
    if (showMessage) {
      // Establecer el temporizador para ocultar el mensaje después de 5 segundos
      timerId = setTimeout(() => {
        setShowMessage(false);
      }, 5000);
    }
    // Función de limpieza para borrar el temporizador
    return () => clearTimeout(timerId);
  }, [showMessage]);

  const handleInputChange = (e) => {
    setNuevaCategoria({ ...nuevaCategoria, [e.target.name]: e.target.value });
  };

  const confirmarDeshabilitacion = (categoria) => {
    setCategoriaADeshabilitar(categoria);
    setMostrarModalConfirmacion(true);
  };

  const deshabilitarCategoriaConfirmada = async () => {
    if (categoriaADeshabilitar) {
      // Aquí llamas a la función deshabilitarCategoria con la categoría seleccionada
      await deshabilitarCategoria(categoriaADeshabilitar);
      setMostrarModalConfirmacion(false);
      setCategoriaADeshabilitar(null);
    }
  };

  const mostrarMensaje = (texto, tipo) => {
    setMessage(texto);
    setMessageType(tipo);
    setShowMessage(true);
  };

  const validateForm = () => {
    let formErrors = {};
    if (!nuevaCategoria.nombre.trim())
      formErrors.nombre = "El nombre es requerido";
    if (!nuevaCategoria.descripcion.trim())
      formErrors.descripcion = "La descripción es requerida";
    return formErrors;
  };

  const handleSubmitAgregar = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      try {
        const respuesta = await agregarCategoria(nuevaCategoria);
        if (
          respuesta &&
          respuesta.message === "Categoría creada exitosamente"
        ) {
          // La categoría se agregó con éxito
          //setMessage(respuesta.message);
          //setShowMessage(true);
          mostrarMensaje(respuesta.message, "success");
          const categoriasObtenidas = await obtenerCategorias();
          setCategorias(categoriasObtenidas);
        } else {
          // Manejar la respuesta inesperada
          mostrarMensaje(
            "No se pudo agregar la categoría correctamente.",
            "error"
          );
          //setMessage('No se pudo agregar la categoría correctamente.');
          //setShowMessage(true);
        }
        setNuevaCategoria({ nombre: "", descripcion: "" });
        setMostrarModalAgregar(false);
      } catch (error) {
        console.error("Error al agregar categoría:", error);
        mostrarMensaje(
          "Error al agregar la categoría. Por favor, inténtelo de nuevo.",
          "error"
        );
        //setMessage('Error al agregar la categoría. Por favor, inténtelo de nuevo.');
        //setShowMessage(true);
      }
    } else {
      setErrors(formErrors);
    }
  };

  const confirmarHabilitacion = (categoria) => {
    setCategoriaAHabilitar(categoria);
    setMostrarModalConfirmacionHabilitar(true);
  };

  const habilitarCategoriaConfirmada = async () => {
    if (categoriaAHabilitar) {
      await habilitarCategoria(categoriaAHabilitar); // Esta función debe ser implementada
      setMostrarModalConfirmacionHabilitar(false);
      setCategoriaAHabilitar(null);
    }
  };

  const handleSubmitEditar = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      try {
        const respuesta = await editarCategoria(
          nuevaCategoria,
          categoriaAEditar.id
        );
        if (
          respuesta &&
          respuesta.message === "Categoría actualizada exitosamente"
        ) {
          // La categoría se editó con éxito
          //setMessage(respuesta.message);
          //setShowMessage(true);
          mostrarMensaje(respuesta.message, "success");
          const categoriasObtenidas = await obtenerCategorias();
          setCategorias(categoriasObtenidas);
        } else {
          // Manejar la respuesta inesperada
          mostrarMensaje(
            "No se pudo editar la categoría correctamente.",
            "error"
          );
          //setMessage('No se pudo editar la categoría correctamente.');
          //setShowMessage(true);
        }
        setNuevaCategoria({ nombre: "", descripcion: "" });
        setCategoriaAEditar(null);
        setMostrarModalEditar(false);
      } catch (error) {
        console.error("Error al editar categoría:", error);
        mostrarMensaje(
          "Error al editar la categoría. Por favor, inténtelo de nuevo.",
          "error"
        );
        //setMessage('Error al editar la categoría. Por favor, inténtelo de nuevo.');
        //setShowMessage(true);
      }
    } else {
      setErrors(formErrors);
    }
  };

  const deshabilitarCategoria = async (categoria) => {
    // Preparar la categoría para ser deshabilitada
    const categoriaDeshabilitada = { ...categoria, estado: "Inactivo" };

    try {
      const respuesta = await editarCategoria(
        categoriaDeshabilitada,
        categoria.id
      );
      if (
        respuesta &&
        respuesta.message === "Categoría actualizada exitosamente"
      ) {
        mostrarMensaje(respuesta.message, "success");
        const categoriasObtenidas = await obtenerCategorias();
        setCategorias(categoriasObtenidas);
      } else {
        mostrarMensaje(
          "No se pudo deshabilitar la categoría correctamente.",
          "error"
        );
      }
    } catch (error) {
      console.error("Error al deshabilitar categoría:", error);
      mostrarMensaje(
        "Error al deshabilitar la categoría. Por favor, inténtelo de nuevo.",
        "error"
      );
    }
  };

  const habilitarCategoria = async (categoria) => {
    // Preparar la categoría para ser deshabilitada
    const categoriaHabilitada = { ...categoria, estado: "Activo" };

    try {
      const respuesta = await editarCategoria(
        categoriaHabilitada,
        categoria.id
      );
      if (
        respuesta &&
        respuesta.message === "Categoría actualizada exitosamente"
      ) {
        mostrarMensaje(respuesta.message, "success");
        const categoriasObtenidas = await obtenerCategorias();
        setCategorias(categoriasObtenidas);
      } else {
        mostrarMensaje(
          "No se pudo habilitar la categoría correctamente.",
          "error"
        );
      }
    } catch (error) {
      console.error("Error al habilitar categoría:", error);
      mostrarMensaje(
        "Error al habilitar la categoría. Por favor, inténtelo de nuevo.",
        "error"
      );
    }
  };

  const abrirModalEditar = (categoria) => {
    setNuevaCategoria({
      nombre: categoria.nombre,
      descripcion: categoria.descripcion,
    });
    setCategoriaAEditar(categoria);
    setMostrarModalEditar(true);
  };

  const toggleDescripcion = (id) => {
    setCategorias(
      categorias.map((categoria) => {
        if (categoria.id === id) {
          return { ...categoria, abierta: !categoria.abierta };
        }
        return categoria;
      })
    );
  };

  const irAPaginaSiguiente = () => {
    setPaginaActual(Math.min(paginaActual + 1, totalPaginas));
  };

  const irAPaginaAnterior = () => {
    setPaginaActual(Math.max(paginaActual - 1, 1));
  };

  return (
    <div className="container mx-auto p-4">
      <div className="bg-white p-4 rounded shadow-md">
        {showMessage && (
          <div
            className={`mb-4 p-2 border rounded flex justify-between items-center ${
              messageType === "error"
                ? "bg-red-100 border-red-400 text-red-700"
                : "bg-green-100 border-green-400 text-green-700"
            }`}
          >
            <span>{message}</span>
            <button
              onClick={() => setShowMessage(false)}
              className="text-green-700 hover:text-green-500"
            >
              &#10005;
            </button>
          </div>
        )}
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Categorías</h2>
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={() => setMostrarModalAgregar(true)}
          >
            Agregar Categoría
          </button>
        </div>
        {categoriasActuales.map((categoria) => (
          <div key={categoria.id} className="border-b border-gray-200 py-4">
            <div className="flex justify-between items-center">
              <h3
                className="text-lg font-semibold cursor-pointer"
                onClick={() => toggleDescripcion(categoria.id)}
              >
                {categoria.nombre}
              </h3>
              <div>
                <button
                  className="mr-2 px-3 py-1 bg-blue-500 text-white rounded"
                  onClick={() => abrirModalEditar(categoria)}
                >
                  Editar
                </button>
                {categoria.estado === "Activo" ? (
                  <button
                    onClick={() => confirmarDeshabilitacion(categoria)}
                    className="px-3 py-1 bg-red-500 text-white rounded"
                  >
                    Deshabilitar
                  </button>
                ) : (
                  <button
                    onClick={() => confirmarHabilitacion(categoria)}
                    className="mr-2 px-3 py-1 bg-green-500 text-white rounded"
                  >
                    Habilitar
                  </button>
                )}
              </div>
            </div>
            {categoria.abierta && (
              <p className="mt-2 text-gray-600">{categoria.descripcion}</p>
            )}
          </div>
        ))}
        {/* Botones del paginador */}
        <div className="flex justify-between items-center mt-8">
          <button
            onClick={irAPaginaAnterior}
            disabled={paginaActual === 1}
            className={`px-4 py-2 rounded-lg text-white ${
              paginaActual === 1
                ? "bg-gray-500"
                : "bg-blue-500 hover:bg-blue-700"
            } transition-colors duration-150`}
          >
            Anterior
          </button>
          <span className="text-lg font-semibold">
            Página {paginaActual} de {totalPaginas}
          </span>
          <button
            onClick={irAPaginaSiguiente}
            disabled={paginaActual === totalPaginas}
            className={`px-4 py-2 rounded-lg text-white ${
              paginaActual === totalPaginas
                ? "bg-gray-500"
                : "bg-blue-500 hover:bg-blue-700"
            } transition-colors duration-150`}
          >
            Siguiente
          </button>
        </div>
      </div>

      {mostrarModalAgregar && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-xl w-3/4 md:w-1/2 lg:w-1/3">
            <form onSubmit={handleSubmitAgregar} className="space-y-4">
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Nombre de la Categoría:
                </label>
                {errors.nombre && (
                  <p className="text-red-500 text-sm">{errors.nombre}</p>
                )}
                <input
                  type="text"
                  name="nombre"
                  value={nuevaCategoria.nombre}
                  onChange={handleInputChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Descripción:
                </label>
                {errors.descripcion && (
                  <p className="text-red-500 text-sm">{errors.descripcion}</p>
                )}
                <textarea
                  name="descripcion"
                  value={nuevaCategoria.descripcion}
                  onChange={handleInputChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="flex justify-between">
                <button
                  type="button"
                  onClick={() => setMostrarModalAgregar(false)}
                  className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Agregar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Modal para editar categoría */}
      {mostrarModalEditar && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-xl w-3/4 md:w-1/2 lg:w-1/3">
            <form onSubmit={handleSubmitEditar} className="space-y-4">
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Nombre de la Categoría:
                </label>
                {errors.nombre && (
                  <p className="text-red-500 text-sm">{errors.nombre}</p>
                )}
                <input
                  type="text"
                  name="nombre"
                  value={categoriaAEditar.nombre}
                  onChange={handleInputChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Descripción:
                </label>
                {errors.descripcion && (
                  <p className="text-red-500 text-sm">{errors.descripcion}</p>
                )}
                <textarea
                  name="descripcion"
                  value={categoriaAEditar.descripcion}
                  onChange={handleInputChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="flex justify-between">
                <button
                  type="button"
                  onClick={() => setMostrarModalEditar(false)}
                  className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Editar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {/* Modal de confirmación para deshabilitar categoría */}
      {mostrarModalConfirmacion && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 flex justify-center items-center">
          <div className="bg-white p-8 rounded-lg shadow-xl max-w-md mx-auto">
            <h2 className="text-xl font-semibold text-center mb-4">
              Confirmar Deshabilitación
            </h2>
            <p className="text-gray-700 mb-8">
              ¿Estás seguro de que deseas deshabilitar esta categoría?
            </p>
            <div className="flex justify-center gap-4">
              <button
                onClick={() => setMostrarModalConfirmacion(false)}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-6 rounded transition ease-in-out duration-150"
              >
                Cancelar
              </button>
              <button
                onClick={deshabilitarCategoriaConfirmada}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-6 rounded transition ease-in-out duration-150"
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      )}

      {mostrarModalConfirmacionHabilitar && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 flex justify-center items-center">
          <div className="bg-white p-8 rounded-lg shadow-xl max-w-md mx-auto">
            <h2 className="text-xl font-semibold text-center mb-4">
              Confirmar Habilitación
            </h2>
            <p className="text-gray-700 mb-8">
              ¿Estás seguro de que deseas habilitar esta categoría?
            </p>
            <div className="flex justify-center gap-4">
              <button
                onClick={() => setMostrarModalConfirmacionHabilitar(false)}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-6 rounded transition ease-in-out duration-150"
              >
                Cancelar
              </button>
              <button
                onClick={habilitarCategoriaConfirmada}
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-6 rounded transition ease-in-out duration-150"
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Categorias;
