import React, { useState, useEffect } from "react";
import Cropper from "react-easy-crop";
import "react-easy-crop/react-easy-crop.css";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";

import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { SortableItem } from "./SortableItem";

const ImageCropperEditCarrusel = ({ imagenesExistentes, onImagesProcessed, onOrderChange, onImageRemove }) => {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageURL, setSelectedImageURL] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [croppedArea, setCroppedArea] = useState(null);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const [hoveredItemId, setHoveredItemId] = useState(null);
  const [originalImages, setOriginalImages] = useState([]);


  useEffect(() => {
    
    // Asumiendo que imagenesExistentes es un array, podemos verificar si está vacío
    if (imagenesExistentes && imagenesExistentes.length > 0 && originalImages.length === 0) {
      
      setImages(imagenesExistentes);
      setOriginalImages(imagenesExistentes);
      
    }
  }, [imagenesExistentes]);

  useEffect(() => {
    if (selectedImage) {
      setImageDimensions({
        width: selectedImage.width,
        height: selectedImage.height,
      });
    }
  }, [selectedImage]);

  const handleImageChange = (e) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);
  
      // Map each file to a promise that resolves to an object with the file's data and a src URL
      const filesPromises = files.map((file, index) => new Promise(resolve => {
        const src = URL.createObjectURL(file);
        resolve({
          id: `image-${Date.now()}-${index}`,
          src,
          orden: images.length + index,
        });
      }));
  
      // Wait for all promises to resolve
      Promise.all(filesPromises).then(loadedImages => {
        setImages(prevImages => [...prevImages, ...loadedImages]);
        setOriginalImages(prevOriginalImages => [...prevOriginalImages, ...loadedImages]);
  
        // Now that images are loaded in order, auto-crop them as needed
        loadedImages.forEach(image => {
          autoCropImage(image);
        });
      });
    }
  };
  

  const handleDragEnd = (event) => {
    const { active, over } = event;
    
    if (over && active.id !== over.id) {
      setImages((currentImages) => {
        const oldIndex = currentImages.findIndex((img) => img.id === active.id);
        const newIndex = currentImages.findIndex((img) => img.id === over.id);
        const reorderedImages = arrayMove(currentImages, oldIndex, newIndex);
        onOrderChange(reorderedImages); // Asegúrate de que esto envía el array completo
        
        return reorderedImages;
      });
    }
  };

  // Función para calcular y aplicar el recorte automático
  const autoCropImage = (imageURL) => {
    const img = new Image();
    img.src = imageURL.src;
  
    img.onload = () => {
      const { naturalWidth, naturalHeight } = img;
      const aspect = 2551 / 620; // Aspect ratio provided in the Cropper component
      let cropWidth = naturalWidth;
      let cropHeight = naturalHeight;
      let cropX = 0;
      let cropY = 0;
  
      if (naturalWidth / naturalHeight > aspect) {
        // Image is wider than the desired aspect ratio
        cropWidth = naturalHeight * aspect;
        cropX = (naturalWidth - cropWidth) / 2;
      } else {
        // Image is taller than the desired aspect ratio
        cropHeight = naturalWidth / aspect;
        cropY = (naturalHeight - cropHeight) / 2;
      }
  
      const crop = {
        x: cropX,
        y: cropY,
        width: cropWidth,
        height: cropHeight,
      };
  
      applyAutoCrop(crop, img, imageURL);
    };
  };
  

  // Implementar la lógica de recorte y conversión a URL de imagen
  const applyAutoCrop = (crop, image, originalImageURL) => {
    
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = crop.width;
    canvas.height = crop.height;
    ctx.drawImage(
      image,
      crop.x,
      crop.y,
      crop.width,
      crop.height,
      0,
      0,
      crop.width,
      crop.height
    );
    // Convertir el canvas a URL de imagen y enviar al componente padre
    const croppedImageUrl = canvas.toDataURL("image/jpeg");
    // Aquí actualizas el estado con la versión procesada de la imagen
    
    setImages((currentImages) =>
      currentImages.map((img) =>
        img.id === originalImageURL.id ? { ...img, src: croppedImageUrl } : img
      )
    );

    
    onImagesProcessed(croppedImageUrl, originalImageURL.id);
    // Opcional: si quieres mantener la imagen original y la recortada, puedes hacerlo aquí
    // Por ejemplo, agregando la URL recortada a un estado que maneje las imágenes recortadas.
  };

  const handleRemoveImage = (e, imageId) => {
    e.preventDefault();
    e.stopPropagation();

    

    // Eliminar visualmente la imagen en el componente hijo
    setImages((currentImages) =>
      currentImages.filter((image) => image.id !== imageId)
    );

    // Notificar al componente padre para actualizar el arreglo de IDs
    onImageRemove(imageId);
  };

  const handleEditImage = (event, imageId) => {
    event.preventDefault();
    event.stopPropagation();

    // Busca la imagen original para cargar en el editor, asegurándose de que se usa la versión sin modificar
    const imageToEdit = originalImages.find((img) => img.id === imageId);
    if (imageToEdit) {
      setSelectedImageURL(imageToEdit.src); // Usa la URL de la imagen original
      const img = new Image();
      img.src = imageToEdit.src; // Carga la imagen original para obtener sus dimensiones naturales
      img.id = imageToEdit.id;
      img.onload = () => {
        setSelectedImage(img);
        setImageDimensions({
          width: img.naturalWidth,
          height: img.naturalHeight,
        });
        setIsEditorOpen(true);
      };
    } else {
      console.error("No se encontró la imagen original para editar.");
    }
  };

  const handleCloseEditor = () => {
    setIsEditorOpen(false);
    setSelectedImage(null);
    setSelectedImageURL(null);
  };

  const handleAcceptCrop = async (event) => {
    event.preventDefault();

    if (!selectedImageURL || !croppedArea) {
      console.error(
        "Error en el proceso de recorte: No se ha seleccionado una imagen o área de recorte."
      );
      return;
    }

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    // Asegúrate de que las dimensiones del canvas coincidan con el área de recorte
    canvas.width = croppedArea.width;
    canvas.height = croppedArea.height;

    const imageToCrop = new Image();
    imageToCrop.src = selectedImageURL;
    imageToCrop.setAttribute("crossOrigin", "anonymous"); // Para evitar problemas de CORS

    imageToCrop.onload = () => {
      const scaleX = imageToCrop.naturalWidth / imageDimensions.width;
      const scaleY = imageToCrop.naturalHeight / imageDimensions.height;

      // Asegúrate de que las coordenadas y dimensiones de recorte sean positivas y no excedan las dimensiones de la imagen
      const cropX = Math.max(0, croppedArea.x * scaleX);
      const cropY = Math.max(0, croppedArea.y * scaleY);
      const cropWidth = Math.min(
        imageToCrop.naturalWidth,
        croppedArea.width * scaleX
      );
      const cropHeight = Math.min(
        imageToCrop.naturalHeight,
        croppedArea.height * scaleY
      );

      ctx.drawImage(
        imageToCrop,
        cropX,
        cropY,
        cropWidth,
        cropHeight,
        0,
        0,
        canvas.width,
        canvas.height
      );

      const croppedImageUrl = canvas.toDataURL("image/png"); // Usar PNG para evitar transparencias no deseadas

      // Proceso de actualización de la imagen recortada
      const updatedImages = images.map((img) =>
        img.id === selectedImage.id ? { ...img, src: croppedImageUrl } : img
      );

      

      setImages(updatedImages);
      onImagesProcessed(croppedImageUrl, selectedImage.id);

      setIsEditorOpen(false);
      setSelectedImage(null);
      setSelectedImageURL(null);
    };
  };

  // Asegúrate de implementar onCropComplete para actualizar croppedArea
  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  // onMouseEnter actualizado para tomar el id del item
  const handleMouseEnter = (itemId) => {
    
    setHoveredItemId(itemId);
  };

  // onMouseLeave actualizado para resetear el estado
  const handleMouseLeave = () => {
    
    setHoveredItemId(null);
  };

  const modalStyles = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    zIndex: 10,
  };

  const modalContentStyles = {
    position: "relative",
    width: "80%",
    maxWidth: "700px",
    maxHeight: "80vh",
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
  };

  const uploadButtonStyles = {
    width: "200px",
    height: "50px",
    border: "2px solid #ccc",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    margin: "auto",
    marginBottom: "20px",
  };

  return (
    <div>
      <input
        type="file"
        accept="image/*"
        multiple
        onChange={handleImageChange}
        style={{ display: "none" }}
        id="fileInput"
      />
      <label htmlFor="fileInput" style={uploadButtonStyles}>
        Subir imágenes
      </label>
      <div className="flex flex-wrap gap-2.5">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={images.map((img) => img.id)}
            strategy={verticalListSortingStrategy}
          >
            {images.map((image, index) => (
              <SortableItem
                key={image.id}
                id={image.id}
                isHovering={hoveredItemId === image.id}
              >
                <div className="relative w-[100px] h-[100px]">
                  <img
                    src={image.src}
                    alt={`Preview ${index}`}
                    className="w-full h-full object-cover"
                  />
                  <button
                    onMouseEnter={() => handleMouseEnter(image.id)}
                    onMouseLeave={handleMouseLeave}
                    className="absolute top-0 right-0 bg-transparent border-none p-1"
                    onClick={(e) => handleRemoveImage(e, image.id)}
                  >
                    <CloseIcon sx={{ color: "red" }} />
                  </button>
                  <button
                    onMouseEnter={() => handleMouseEnter(image.id)}
                    onMouseLeave={handleMouseLeave}
                    className="absolute top-0 left-0 bg-transparent border-none p-1"
                    onClick={(e) => handleEditImage(e, image.id)}
                  >
                    {/* Asumiendo que tienes un icono de edición similar a CloseIcon */}
                    <EditIcon sx={{ color: "blue" }} />{" "}
                    {/* Este icono representa el botón de edición */}
                  </button>
                </div>
              </SortableItem>
            ))}
          </SortableContext>
        </DndContext>
      </div>

      {isEditorOpen && (
        <div style={modalStyles} onClick={handleCloseEditor}>
          <div style={modalContentStyles} onClick={(e) => e.stopPropagation()}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  height: "400px",
                  margin: "auto",
                }}
              >
                <Cropper
                  image={selectedImage.src}
                  crop={crop}
                  zoom={zoom}
                  aspect={2551 / 620}
                  onCropChange={setCrop}
                  onZoomChange={setZoom}
                  onCropComplete={onCropComplete}
                  style={{
                    containerStyle: { width: "100%", height: "100%" },
                    mediaStyle: { height: "auto" },
                  }}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "10px",
              }}
            >
              <button onClick={handleCloseEditor}>Cancelar</button>
              <button onClick={handleAcceptCrop}>Aceptar</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageCropperEditCarrusel;
