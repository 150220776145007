import React, { useState, useEffect } from "react";
import { obtenerUsuarios, modificarUsuario } from "../../services/Api";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import { API_BASE_URL } from "../../services/Config";

const Usuarios = () => {
  const [usuarios, setUsuarios] = useState([]);
  //const [mostrarModalInformacion, setMostrarModalInformacion] = useState(false);
  const [mostrarModalEditarUsuario, setMostrarModalEditarUsuario] =
    useState(false);
  const [usuarioAEditar, setUsuarioAEditar] = useState(null);
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [messageType, setMessageType] = useState("success");
  const [
    mostrarModalConfirmacionDeshabilitar,
    setMostrarModalConfirmacionDeshabilitar,
  ] = useState(false);
  const [usuarioADeshabilitar, setUsuarioADeshabilitar] = useState(null);
  const [
    mostrarModalConfirmacionHabilitar,
    setMostrarModalConfirmacionHabilitar,
  ] = useState(false);
  const [usuarioAHabilitar, setUsuarioAHabilitar] = useState(null);
  const [paginaActual, setPaginaActual] = useState(1);
  const usuariosPorPagina = 10; // Puedes ajustar este valor según tus necesidades
  const indiceUltimoUsuario = paginaActual * usuariosPorPagina;
  const indicePrimerUsuario = indiceUltimoUsuario - usuariosPorPagina;
  const usuariosActuales = usuarios.slice(
    indicePrimerUsuario,
    indiceUltimoUsuario
  );
  const totalPaginas = Math.ceil(usuarios.length / usuariosPorPagina);
  const [mostrarModalInformacion, setMostrarModalInformacion] = useState(false);
  const [usuarioAInformar, setUsuarioAInformar] = useState(null);
  const [visibleBoletaId, setVisibleBoletaId] = useState(null);

  useEffect(() => {
    const cargarUsuarios = async () => {
      try {
        const usuariosObtenidos = await obtenerUsuarios();
        setUsuarios(usuariosObtenidos);
      } catch (error) {
        console.error("Error al obtener usuarios:", error);
      }
    };

    cargarUsuarios();
  }, []);

  const confirmarHabilitacion = (usuario) => {
    setUsuarioAHabilitar(usuario);
    setMostrarModalConfirmacionHabilitar(true);
  };

  const habilitarUsuarioConfirmado = async () => {
    if (usuarioAHabilitar) {
      await habilitarUsuario(usuarioAHabilitar.id); // Esta función aún debe ser implementada
      setMostrarModalConfirmacionHabilitar(false);
    }
  };

  const confirmarDeshabilitacion = (usuario) => {
    setUsuarioADeshabilitar(usuario);
    setMostrarModalConfirmacionDeshabilitar(true);
  };

  const deshabilitarUsuarioConfirmado = async () => {
    if (usuarioADeshabilitar) {
      await deshabilitarUsuario(usuarioADeshabilitar.id);
      setMostrarModalConfirmacionDeshabilitar(false);
    }
  };

  const mostrarMensaje = (texto, tipo) => {
    setMessage(texto);
    setMessageType(tipo);
    setShowMessage(true);
  };

  const abrirModalEditarUsuario = (usuario) => {
    setUsuarioAEditar(usuario);
    setMostrarModalEditarUsuario(true);
  };

  const handleInputChangeUsuario = (e) => {
    setUsuarioAEditar({ ...usuarioAEditar, [e.target.name]: e.target.value });
  };

  const validateUserForm = (datosUsuario) => {
    let formErrors = {};

    // Validación para el nombre
    if (!datosUsuario.name.trim()) {
      formErrors.name = "El nombre es requerido";
    }

    // Validación para el nombre
    if (!datosUsuario.apellido.trim()) {
      formErrors.apellido = "El apellido es requerido";
    }

    // Validación para el correo electrónico
    if (!datosUsuario.email.trim()) {
      formErrors.email = "El correo es requerido";
    } else if (!/\S+@\S+\.\S+/.test(datosUsuario.email)) {
      formErrors.email = "Correo inválido";
    }

    // Validación para el número de celular
    if (
      datosUsuario.numeroCelular &&
      !/^(\+\d{1,3})?\d{0,255}$/.test(datosUsuario.numeroCelular)
    ) {
      formErrors.numeroCelular = "Número de celular inválido";
    }

    // Validación para Instagram (opcional, solo si el campo instagram existe)
    if (datosUsuario.instagram && !/^.{0,255}$/.test(datosUsuario.instagram)) {
      formErrors.instagram = "Instagram inválido";
    }

    // Validación para Instagram (opcional, solo si el campo instagram existe)
    if (datosUsuario.direccion && !/^.{0,255}$/.test(datosUsuario.direccion)) {
      formErrors.direccion = "Direccion inválida";
    }

    // Validación para Instagram (opcional, solo si el campo instagram existe)
    if (datosUsuario.ciudad && !/^.{0,255}$/.test(datosUsuario.ciudad)) {
      formErrors.ciudad = "Direccion inválida";
    }

    // Agrega aquí cualquier otra validación que necesites

    return formErrors;
  };

  const handleSubmitEditarUsuario = async (e) => {
    e.preventDefault();
    const formErrors = validateUserForm(usuarioAEditar);
    if (Object.keys(formErrors).length === 0) {
      try {
        const respuesta = await modificarUsuario(
          usuarioAEditar.id,
          usuarioAEditar
        );
        if (
          respuesta &&
          respuesta.message === "Usuario actualizado exitosamente"
        ) {
          // El usuario se editó con éxito
          mostrarMensaje(respuesta.message, "success");
          const usuariosObtenidos = await obtenerUsuarios(); // Suponiendo que tienes una función para obtener los usuarios actualizados
          setUsuarios(usuariosObtenidos);
        } else {
          // Manejar la respuesta inesperada
          mostrarMensaje(
            "No se pudo editar el usuario correctamente.",
            "error"
          );
        }
        // Limpiar el formulario
        setUsuarioAEditar({
          name: "",
          apellido: "",
          email: "",
          rol: "",
          estado: "",
          ciudad: "",
          direccion: "",
          numeroCelular: "",
          instagram: "",
        });
        setMostrarModalEditarUsuario(false);
      } catch (error) {
        console.error("Error al editar usuario:", error);
        mostrarMensaje(
          "Error al editar el usuario. Por favor, inténtelo de nuevo.",
          "error"
        );
      }
    } else {
      setErrors(formErrors);
    }
  };

  const deshabilitarUsuario = async (id) => {
    // Suponiendo que 'id' es el ID del usuario que quieres deshabilitar
    const datosModificados = { estado: "Inactivo" };
    try {
      const respuesta = await modificarUsuario(id, datosModificados);
      if (
        respuesta &&
        respuesta.message === "Usuario actualizado exitosamente"
      ) {
        mostrarMensaje("Usuario deshabilitado con éxito", "success");
        // Aquí deberías actualizar el estado para reflejar el cambio en la UI, por ejemplo:
        setUsuarios(
          usuarios.map((usuario) => {
            if (usuario.id === id) {
              return { ...usuario, estado: "Deshabilitado" };
            }
            return usuario;
          })
        );
      } else {
        mostrarMensaje("No se pudo deshabilitar el usuario", "error");
      }
    } catch (error) {
      console.error("Error al deshabilitar usuario:", error);
      mostrarMensaje(
        "Error al deshabilitar el usuario. Por favor, inténtelo de nuevo.",
        "error"
      );
    }
  };

  const habilitarUsuario = async (id) => {
    const datosModificados = { estado: "Activo" };
    try {
      const respuesta = await modificarUsuario(id, datosModificados);
      if (
        respuesta &&
        respuesta.message === "Usuario actualizado exitosamente"
      ) {
        mostrarMensaje("Usuario habilitado con éxito", "success");
        setUsuarios(
          usuarios.map((usuario) => {
            if (usuario.id === id) {
              return { ...usuario, estado: "Activo" };
            }
            return usuario;
          })
        );
      } else {
        mostrarMensaje("No se pudo habilitar el usuario", "error");
      }
    } catch (error) {
      console.error("Error al habilitar usuario:", error);
      mostrarMensaje(
        "Error al habilitar el usuario. Por favor, inténtelo de nuevo.",
        "error"
      );
    }
  };

  const abrirModalInformacion = (usuario) => {
    setUsuarioAInformar(usuario);
    setMostrarModalInformacion(true);
  };

  const toggleProductos = (boletaId) => {
    setVisibleBoletaId(visibleBoletaId === boletaId ? null : boletaId);
  };

  const renderProductos = (productos) => {
    
    return productos.map((producto) => (
      <div key={producto.id} className="flex items-center my-2">
        {producto.imagenes &&
          producto.imagenes[0] &&
          producto.imagenes[0].uri_foto && (
            <img
              src={`${API_BASE_URL}${producto.imagenes[0].uri_foto}`}
              alt={producto.nombre}
              className="w-20 h-20 object-cover mr-4"
            />
          )}
        <div>
          <p>Nombre del Producto: {producto.nombre}</p>
          <p>Precio: ${producto.precio.toLocaleString()}</p>
          <p>Unidades Compradas: {producto.pivot.cantidad}</p>
        </div>
      </div>
    ));
  };

  const irAPaginaSiguiente = () => {
    setPaginaActual((pagActual) => Math.min(pagActual + 1, totalPaginas));
  };

  const irAPaginaAnterior = () => {
    setPaginaActual((pagActual) => Math.max(pagActual - 1, 1));
  };

  return (
    <div className="container mx-auto p-4">
      <div className="bg-white p-4 rounded shadow-lg">
        <h2 className="text-xl font-semibold mb-4">Usuarios</h2>
        <div className="overflow-x-auto">
          {showMessage && (
            <div
              className={`mb-4 p-2 border rounded flex justify-between items-center ${
                messageType === "error"
                  ? "bg-red-100 border-red-400 text-red-700"
                  : "bg-green-100 border-green-400 text-green-700"
              }`}
            >
              <span>{message}</span>
              <button
                onClick={() => setShowMessage(false)}
                className="text-green-700 hover:text-green-500"
              >
                &#10005;
              </button>
            </div>
          )}
          <table className="min-w-full border-collapse block md:table">
            <thead className="block md:table-header-group">
              <tr className="border border-gray-300 md:border-none block md:table-row">
                <th className="bg-gray-100 p-2 text-left block md:table-cell">
                  Nombre
                </th>
                <th className="bg-gray-100 p-2 text-left block md:table-cell">
                  Correo
                </th>
                <th className="bg-gray-100 p-2 text-left block md:table-cell">
                  Número
                </th>
                <th className="bg-gray-100 p-2 text-left block md:table-cell">
                  Instagram
                </th>
                <th className="bg-gray-100 p-2 text-left block md:table-cell">
                  Rol
                </th>
                <th className="bg-gray-100 p-2 text-left block md:table-cell">
                  Estado
                </th>
                <th className="bg-gray-100 p-2 text-center block md:table-cell w-auto">
                  Opciones
                </th>
              </tr>
            </thead>
            <tbody className="block md:table-row-group">
              {usuariosActuales.map((usuario) => (
                <tr
                  key={usuario.id}
                  className="bg-white border border-gray-300 md:border-none block md:table-row"
                >
                  <td className="p-2 block md:table-cell">{usuario.name}</td>
                  <td className="p-2 block md:table-cell">{usuario.email}</td>
                  <td className="p-2 block md:table-cell">
                    {usuario.numeroCelular || "No disponible"}
                  </td>
                  <td className="p-2 block md:table-cell">
                    {usuario.instagram || "No disponible"}
                  </td>
                  <td className="p-2 block md:table-cell">{usuario.rol}</td>
                  <td className="p-2 block md:table-cell">{usuario.estado}</td>
                  <td className="p-2 text-center block md:table-cell">
                    <div className="flex justify-center items-center space-x-2">
                      <button
                        onClick={() => abrirModalInformacion(usuario)}
                        className="mr-2 px-3 py-1 bg-blue-500 text-white rounded"
                      >
                        Información
                      </button>
                      <button
                        onClick={() => abrirModalEditarUsuario(usuario)}
                        className="mr-2 px-3 py-1 bg-blue-500 text-white rounded"
                      >
                        Editar
                      </button>
                      {usuario.estado === "Activo" ? (
                        <button
                          onClick={() => confirmarDeshabilitacion(usuario)}
                          className="px-3 py-1 bg-red-500 text-white rounded"
                        >
                          Deshabilitar
                        </button>
                      ) : (
                        <button
                          onClick={() => confirmarHabilitacion(usuario)}
                          className="mr-2 px-3 py-1 bg-green-500 text-white rounded"
                        >
                          Habilitar
                        </button>
                      )}
                    </div>
                  </td>
                  <td className="p-2 block md:table-cell">
                    {/* Botones para Información, Editar, Deshabilitar */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="flex justify-between items-center mt-8">
            <button
              onClick={irAPaginaAnterior} // Asegúrate de tener esta función definida
              disabled={paginaActual === 1}
              className={`px-4 py-2 rounded-lg text-white ${
                paginaActual === 1
                  ? "bg-gray-500"
                  : "bg-blue-500 hover:bg-blue-700"
              } transition-colors duration-150`}
            >
              Anterior
            </button>
            <span className="text-lg font-semibold">
              Página {paginaActual} de {totalPaginas}{" "}
              {/* Calcula estas variables basado en tu estado de usuarios */}
            </span>
            <button
              onClick={irAPaginaSiguiente} // Asegúrate de tener esta función definida
              disabled={paginaActual === totalPaginas}
              className={`px-4 py-2 rounded-lg text-white ${
                paginaActual === totalPaginas
                  ? "bg-gray-500"
                  : "bg-blue-500 hover:bg-blue-700"
              } transition-colors duration-150`}
            >
              Siguiente
            </button>
          </div>
        </div>
        {mostrarModalEditarUsuario && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded-lg shadow-xl w-3/4 md:w-1/2 lg:w-1/3">
              <form onSubmit={handleSubmitEditarUsuario} className="space-y-4">
                <h3 className="text-lg font-semibold">Editar Usuario</h3>
                <div>
                  <label
                    htmlFor="name"
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Nombre:
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={usuarioAEditar.name}
                    onChange={handleInputChangeUsuario}
                    placeholder="Nombre"
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                  {errors.name && (
                    <p className="text-red-500 text-xs italic">{errors.name}</p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="apellido"
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Apellido:
                  </label>
                  <input
                    type="text"
                    name="apellido"
                    id="apellido"
                    value={usuarioAEditar.apellido}
                    onChange={handleInputChangeUsuario}
                    placeholder="Apellido"
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                  {errors.apellido && (
                    <p className="text-red-500 text-xs italic">
                      {errors.apellido}
                    </p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="email"
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Email:
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={usuarioAEditar.email}
                    onChange={handleInputChangeUsuario}
                    placeholder="Email"
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  />
                  {errors.email && (
                    <p className="text-red-500 text-xs italic">
                      {errors.email}
                    </p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="rol"
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Rol:
                  </label>
                  <select
                    name="rol"
                    id="rol"
                    value={usuarioAEditar.rol}
                    onChange={handleInputChangeUsuario}
                    className="shadow border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  >
                    <option value="Cliente">Cliente</option>
                    <option value="Admin">Admin</option>
                  </select>
                </div>

                <div>
                  <label
                    htmlFor="ciudad"
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Ciudad:
                  </label>
                  <input
                    type="text"
                    name="ciudad"
                    id="ciudad"
                    value={usuarioAEditar.ciudad || ""}
                    onChange={handleInputChangeUsuario}
                    placeholder="Ciudad"
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                  {errors.ciudad && (
                    <p className="text-red-500 text-xs italic">
                      {errors.ciudad}
                    </p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="direccion"
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Dirección:
                  </label>
                  <input
                    type="text"
                    name="direccion"
                    id="direccion"
                    value={usuarioAEditar.direccion || ""}
                    onChange={handleInputChangeUsuario}
                    placeholder="Dirección"
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                  {errors.direccion && (
                    <p className="text-red-500 text-xs italic">
                      {errors.direccion}
                    </p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="numeroCelular"
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Número de Celular:
                  </label>
                  <input
                    type="text"
                    name="numeroCelular"
                    id="numeroCelular"
                    value={usuarioAEditar.numeroCelular || ""}
                    onChange={handleInputChangeUsuario}
                    placeholder="Número de Celular"
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                  {errors.numeroCelular && (
                    <p className="text-red-500 text-xs italic">
                      {errors.numeroCelular}
                    </p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="instagram"
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Instagram:
                  </label>
                  <input
                    type="text"
                    name="instagram"
                    id="instagram"
                    value={usuarioAEditar.instagram || ""}
                    onChange={handleInputChangeUsuario}
                    placeholder="Instagram"
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                  {errors.instagram && (
                    <p className="text-red-500 text-xs italic">
                      {errors.instagram}
                    </p>
                  )}
                </div>

                <div className="flex justify-end space-x-4">
                  <button
                    type="button"
                    onClick={() => setMostrarModalEditarUsuario(false)}
                    className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  >
                    Cancelar
                  </button>
                  <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  >
                    Guardar Cambios
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
        {mostrarModalConfirmacionDeshabilitar && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 flex justify-center items-center">
            <div className="bg-white p-8 rounded-lg shadow-xl max-w-md mx-auto">
              <h3 className="text-lg font-semibold text-center mb-4">
                Confirmar Deshabilitación
              </h3>
              <p className="text-gray-700 mb-8">
                ¿Estás seguro de que deseas deshabilitar a este usuario?
              </p>
              <div className="flex justify-center gap-4">
                <button
                  onClick={() => setMostrarModalConfirmacionDeshabilitar(false)}
                  className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-6 rounded transition ease-in-out duration-150"
                >
                  Cancelar
                </button>
                <button
                  onClick={deshabilitarUsuarioConfirmado}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-6 rounded transition ease-in-out duration-150"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        )}

        {mostrarModalConfirmacionHabilitar && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 flex justify-center items-center">
            <div className="bg-white p-8 rounded-lg shadow-xl max-w-md mx-auto">
              <h3 className="text-lg font-semibold text-center mb-4">
                Confirmar Habilitación
              </h3>
              <p className="text-gray-700 mb-8">
                ¿Estás seguro de que deseas habilitar a este usuario?
              </p>
              <div className="flex justify-center gap-4">
                <button
                  onClick={habilitarUsuarioConfirmado}
                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-6 rounded transition ease-in-out duration-150"
                >
                  Confirmar
                </button>
                <button
                  onClick={() => setMostrarModalConfirmacionHabilitar(false)}
                  className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-6 rounded transition ease-in-out duration-150"
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        )}

        {mostrarModalInformacion && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 flex justify-center items-center overflow-y-auto">
            <div className="bg-white p-6 rounded-lg shadow-xl w-3/4 md:w-1/2 lg:w-1/3 max-h-[80vh] overflow-y-auto rounded-t-lg">
              <Tabs>
                <TabList className="flex justify-around border-b border-gray-200">
                  <Tab className="text-center -mb-px flex-1 py-2 border-solid border-transparent border-b-2 hover:border-gray-300 focus:border-gray-400 cursor-pointer">
                    Datos del Usuario
                  </Tab>
                  <Tab className="text-center -mb-px flex-1 py-2 border-solid border-transparent border-b-2 hover:border-gray-300 focus:border-gray-400 cursor-pointer">
                    Compras
                  </Tab>
                </TabList>

                <TabPanel>
                  <div className="p-4">
                    <h2 className="text-lg font-semibold">Datos Personales</h2>
                    <p>Nombre: {usuarioAInformar.name}</p>
                    <p>Apellido: {usuarioAInformar.apellido}</p>
                    <p>Email: {usuarioAInformar.email}</p>
                    <p>Ciudad: {usuarioAInformar.ciudad}</p>
                    <p>Dirección: {usuarioAInformar.direccion}</p>
                    <p>Número de Celular: {usuarioAInformar.numeroCelular}</p>
                    <p>Instagram: {usuarioAInformar.instagram}</p>
                  </div>
                </TabPanel>

                <TabPanel>
                  <div className="p-4">
                    <h2 className="text-lg font-semibold">Compras</h2>
                    {usuarioAInformar.boletas.map((boleta) => (
                      <div
                        key={boleta.id}
                        className="border-b border-gray-200 mb-4 pb-4"
                      >
                        <div
                          className="flex justify-between items-center cursor-pointer"
                          onClick={() => toggleProductos(boleta.id)}
                        >
                          <h3 className="text-lg font-semibold">
                            Número de Boleta: {boleta.publicId}
                          </h3>
                          {visibleBoletaId === boleta.id ? (
                            <FaChevronUp />
                          ) : (
                            <FaChevronDown />
                          )}
                        </div>
                        <p>Fecha: {boleta.fecha}</p>
                        <p>Total: ${boleta.total.toLocaleString()}</p>
                        <div
                          style={{
                            maxHeight:
                              visibleBoletaId === boleta.id ? "1000px" : "0",
                            overflow: "hidden",
                            transition: "max-height 0.5s ease",
                          }}
                        >
                          {visibleBoletaId === boleta.id && (
                            <div className="mt-4">
                              {renderProductos(boleta.productos)}
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </TabPanel>
              </Tabs>
              <button
                onClick={() => setMostrarModalInformacion(false)}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-6 rounded transition ease-in-out duration-150"
              >
                Cerrar
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Usuarios;
