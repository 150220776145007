import React, { useState } from 'react';
import { FaBox, FaQuestion, FaTags, FaUsers, FaEnvelopeOpenText, FaBriefcase, FaPaintBrush, FaCashRegister, FaImages } from 'react-icons/fa';
import Productos from './Administrar_Ventanas/Productos';
import PreguntasFrecuentes from './Administrar_Ventanas/PreguntasFrecuentes';
import Categorias from './Administrar_Ventanas/Categorias';
import Usuarios from './Administrar_Ventanas/Usuarios';
import SolicitudesContacto from './Administrar_Ventanas/SolicitudesContacto';
import Portafolios from './Administrar_Ventanas/Portafolios';
import Materiales from './Administrar_Ventanas/Materiales';
import Ventas from './Administrar_Ventanas/Ventas';
import Carrusel from './Administrar_Ventanas/Carrusel';

const Administrar = () => {
  const [seccionActiva, setSeccionActiva] = useState('productos');

  const renderizarContenido = () => {
    switch(seccionActiva) {
      case 'productos':
        return <Productos />;
      case 'preguntasFrecuentes':
        return <PreguntasFrecuentes />;
      case 'categorias':
        return <Categorias />;
      case 'usuarios':
        return <Usuarios />;
      case 'solicitudContacto':
        return <SolicitudesContacto />;
      case 'portafolios':
        return <Portafolios />;
      case 'materiales':
        return <Materiales />;
      case 'ventas':
        return <Ventas />;
      case 'carrusel':
        return <Carrusel />;
      default:
        return <div>Selecciona una sección</div>;
    }
  };

  const ButtonLink = ({ icon, children, seccion }) => (
    <button 
      onClick={() => setSeccionActiva(seccion)}
      className={`flex items-center w-full px-4 py-2 mt-5 text-lg font-medium text-left hover:bg-blue-100 rounded-lg transition-colors duration-200 ease-in-out ${seccionActiva === seccion ? 'text-blue-500 bg-blue-100' : 'text-gray-600'}`}
    >
      {icon}
      <span className="ml-2 flex-1">{children}</span>
    </button>
  );

  return (
    <div className="flex min-h-screen bg-gray-100">
      <div className="flex flex-col w-64 px-4 py-8 bg-white border-r">
        <h2 className="text-3xl font-semibold text-center text-gray-700">Administrar</h2>
        <div className="flex flex-col justify-between mt-6">
          <aside>
            <ButtonLink icon={<FaBox />} seccion="productos">Productos</ButtonLink>
            <ButtonLink icon={<FaQuestion />} seccion="preguntasFrecuentes">Preguntas frecuentes</ButtonLink>
            <ButtonLink icon={<FaTags />} seccion="categorias">Categorías</ButtonLink>
            <ButtonLink icon={<FaUsers />} seccion="usuarios">Usuarios</ButtonLink>
            <ButtonLink icon={<FaEnvelopeOpenText />} seccion="solicitudContacto">Solicitud de contacto</ButtonLink>
            <ButtonLink icon={<FaBriefcase />} seccion="portafolios">Portafolios</ButtonLink>
            <ButtonLink icon={<FaPaintBrush />} seccion="materiales">Materiales</ButtonLink>
            <ButtonLink icon={<FaCashRegister />} seccion="ventas">Ventas</ButtonLink>
            <ButtonLink icon={<FaImages />} seccion="carrusel">Carrusel</ButtonLink>
          </aside>
        </div>
      </div>
      <div className="flex flex-1 overflow-auto p-6">
        {renderizarContenido()}
      </div>
    </div>
  );
};

export default Administrar;
