import React from 'react';
import { Link } from 'react-router-dom';
import { FaInstagram } from 'react-icons/fa'; // Importar solo el ícono de Instagram
import { logo } from './Imagenes'; // Asegúrate de que esta es la ruta correcta para tu imagen de logo
import { Instagram } from './Config';

const Footer = () => {
  return (
    <footer className="bg-[#f3e9dc] p-4 md:p-8">
      <div className="max-w-6xl mx-auto flex flex-col md:flex-row justify-between items-center">
        
      <div className="mb-4 md:mb-0">
        <img src={logo} alt="Logo" className="h-56 rounded-full" />
      </div>

        
        <div className="flex justify-center mb-4 md:mb-0">
          <div className="text-center">
            <h3 className="text-xl font-semibold text-gray-800 mb-2">Contacto</h3>
            <p className="text-gray-600">+56 9 1234 5678</p>
            <p className="text-gray-600">Contacto@Correo.cl</p>
          </div>
        </div>
        
        <div className="flex justify-center mb-4 md:mb-0">
          <div className="text-center">
            <h3 className="text-xl font-semibold text-gray-800 mb-2">Información</h3>
            <Link to="/faq" className="block text-gray-600 hover:text-gray-800">Preguntas frecuentes</Link>
            <Link to="/contacto" className="block text-gray-600 hover:text-gray-800">Contacto</Link>
            <Link to="/portafolio" className="block text-gray-600 hover:text-gray-800">Portafolio</Link>
          </div>
        </div>

        <div className="flex flex-col items-center mb-4 md:mb-0"> {/* Flex container for centering the icon */}
          <h3 className="text-xl font-semibold text-gray-800 mb-2">Redes Sociales</h3>
          <a href={Instagram} className="text-gray-600 hover:text-gray-800" aria-label="Instagram">
            <FaInstagram size="2em" />
          </a>
        </div>
      </div>
      
      <div className="text-center text-gray-600 mt-4">
        <p>© 2024 K'ARALLANTAS</p>
      </div>
    </footer>
  );
};

export default Footer;
