import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { iniciarSesion } from "../services/Api"; // Asegúrate de implementar esta función en tu Api
import Footer from "../services/Footer";
import { FaSpinner } from "react-icons/fa";

const IniciarSesion = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [showInvalidCredentialsModal, setShowInvalidCredentialsModal] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false); // Para controlar la visibilidad del modal de error
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      setIsLoading(true);
      try {
        const respuesta = await iniciarSesion(formData);
        
        localStorage.setItem("auth_token", respuesta.access_token); // Guarda el token en localStorage
        localStorage.setItem("auth_user_rol", respuesta.user.rol); // Guarda el token en localStorage
        localStorage.setItem("auth_user", JSON.stringify(respuesta.user)); // Convierte el objeto user a una cadena JSON
        navigate("/"); // Redirige al home
        window.location.reload(); // Recarga la página
      } catch (error) {
        if (error.message.includes("Failed to fetch")) {
          setErrorMessage(
            "No se pudo establecer conexión con el servidor. Por favor, inténtalo más tarde."
          );
          setShowErrorModal(true);
        } else {
          // Intentar procesar el error como un error de la API
          try {
            const errorData = JSON.parse(error.message);
            if (errorData.status === 401) {
              setShowInvalidCredentialsModal(true);
            } else {
              setErrorMessage(errorData.message || "Error al iniciar sesión");
              setShowErrorModal(true);
            }
          } catch (jsonError) {
            setErrorMessage(
              "Ocurrió un error inesperado. Por favor, inténtalo más tarde."
            );
            setShowErrorModal(true);
          }
        }
      } finally {
        setIsLoading(false); // Detener la animación de carga
      }
    } else {
      setErrors(formErrors);
    }
  };

  const validateForm = () => {
    let formErrors = {};
    if (!formData.email.trim()) {
      formErrors.email = "El correo electrónico es requerido";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = "Correo electrónico inválido";
    }
    if (!formData.password) formErrors.password = "La contraseña es requerida";
    return formErrors;
  };

  return (
    <div className="flex flex-col min-h-screen">
      {" "}
      {/* Asegura que el contenedor principal tenga al menos la altura de la pantalla */}
      <div className="flex-grow">
        {" "}
        {/* Hace que este div ocupe todo el espacio disponible */}
        <div className="flex items-center justify-center pt-32 pb-4">
          {" "}
          {/* Ajuste del padding aquí */}
          <div className="w-full max-w-md bg-white p-6 rounded shadow-md border border-gray-200">
            <h2 className="text-2xl font-semibold mb-4 text-center">
              Iniciar sesión
            </h2>
            {isLoading && (
              <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 flex justify-center items-center">
                <FaSpinner className="animate-spin text-4xl text-white" />
              </div>
            )}
            <form onSubmit={handleSubmit} className="space-y-4">
            {errors.email && (
                <p className="text-red-500 text-sm">{errors.email}</p>
              )}
              <input
                className="w-full p-2 border border-gray-300 rounded"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Correo electrónico*"
              />
              {errors.password && (
                <p className="text-red-500 text-sm">{errors.password}</p>
              )}
              <input
                className="w-full p-2 border border-gray-300 rounded"
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Contraseña*"
              />
              
              <button
                type="submit"
                className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Iniciar Sesión
              </button>
            </form>
            <div className="text-center mt-4">
              <button
                onClick={() => navigate("/forgot-password")}
                className="text-blue-500 hover:text-blue-700 focus:outline-none"
                style={{ background: 'none', border: 'none', padding: 0, color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
              >
                ¿Olvidaste tu contraseña?
              </button>
            </div>

          </div>
          {showInvalidCredentialsModal && (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 flex justify-center items-center">
              <div className="bg-white p-6 rounded-lg shadow-xl">
                <h3 className="text-lg font-semibold">
                  Credenciales Inválidas
                </h3>
                <p>Las credenciales proporcionadas son incorrectas.</p>
                <div className="flex justify-end mt-4">
                  <button
                    onClick={() => setShowInvalidCredentialsModal(false)}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Aceptar
                  </button>
                </div>
              </div>
            </div>
          )}
          {showErrorModal && (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 flex justify-center items-center">
              <div className="bg-white p-6 rounded-lg shadow-xl">
                <h3 className="text-lg font-semibold">Error</h3>
                <p>{errorMessage}</p>
                <div className="flex justify-end mt-4">
                  <button
                    onClick={() => setShowErrorModal(false)}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Aceptar
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default IniciarSesion;
