import React, { useState, useEffect } from "react";
import {
  obtenerMateriales,
  agregarMateriales,
  editarMateriales,
} from "../../services/Api";

const Materiales = () => {
  const [materiales, setMateriales] = useState([]);
  const [nuevoMaterial, setNuevoMaterial] = useState({
    nombre: "",
    descripcion: "",
    estado: "",
  });
  const [mostrarModalAgregar, setMostrarModalAgregar] = useState(false);
  const [mostrarModalEditar, setMostrarModalEditar] = useState(false);
  const [materialAEditar, setMaterialAEditar] = useState(null);
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [messageType, setMessageType] = useState("success");
  const [
    mostrarModalConfirmacionDeshabilitar,
    setMostrarModalConfirmacionDeshabilitar,
  ] = useState(false);
  const [materialADeshabilitar, setMaterialADeshabilitar] = useState(null);
  const [paginaActual, setPaginaActual] = useState(1);
  const materialesPorPagina = 10; // Ajustable según necesites
  const indiceUltimoMaterial = paginaActual * materialesPorPagina;
  const indicePrimerMaterial = indiceUltimoMaterial - materialesPorPagina;
  const materialesActuales = materiales.slice(
    indicePrimerMaterial,
    indiceUltimoMaterial
  );
  const totalPaginas = Math.ceil(materiales.length / materialesPorPagina);

  const [
    mostrarModalConfirmacionHabilitar,
    setMostrarModalConfirmacionHabilitar,
  ] = useState(false);
  const [materialAHabilitar, setMaterialAHabilitar] = useState(null);

  useEffect(() => {
    cargarMateriales();
  }, []);

  useEffect(() => {
    let timerId;
    if (showMessage) {
      // Establecer el temporizador para ocultar el mensaje después de 5 segundos
      timerId = setTimeout(() => {
        setShowMessage(false);
      }, 5000);
    }
    // Función de limpieza para borrar el temporizador
    return () => clearTimeout(timerId);
  }, [showMessage]);

  const cargarMateriales = async () => {
    try {
      const materialesObtenidos = await obtenerMateriales();
      // Agrega el atributo 'abierto' a cada material
      const materialesConDetalle = materialesObtenidos.map((material) => ({
        ...material,
        abierto: false, // Inicialmente cerrado
      }));
      setMateriales(materialesConDetalle);
    } catch (error) {
      console.error("Error al obtener materiales:", error);
    }
  };

  const toggleDetalle = (id) => {
    setMateriales(
      materiales.map((material) => {
        if (material.id === id) {
          return { ...material, abierto: !material.abierto };
        }
        return material;
      })
    );
  };

  // Función para manejar la entrada de datos del formulario
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (mostrarModalEditar) {
      setMaterialAEditar({ ...materialAEditar, [name]: value });
    } else {
      setNuevoMaterial({ ...nuevoMaterial, [name]: value });
    }
  };

  // Función para mostrar mensajes
  const mostrarMensaje = (texto, tipo) => {
    setMessage(texto);
    setMessageType(tipo);
    setShowMessage(true);
  };

  // Función para validar el formulario
  const validateForm = (datosMaterial) => {
    let formErrors = {};
    if (!datosMaterial.nombre.trim())
      formErrors.nombre = "El nombre es requerido";
    if (!datosMaterial.descripcion.trim())
      formErrors.descripcion = "La descripción es requerida";
    return formErrors;
  };

  // Función para manejar la adición de un nuevo material
  const handleSubmitAgregar = async (e) => {
    e.preventDefault();
    const formErrors = validateForm(nuevoMaterial);
    if (Object.keys(formErrors).length === 0) {
      try {
        const respuesta = await agregarMateriales(nuevoMaterial);
        if (respuesta && respuesta.message === "Material creado exitosamente") {
          mostrarMensaje(respuesta.message, "success");
          const materialesObtenidos = await obtenerMateriales();
          setMateriales(materialesObtenidos);
          setNuevoMaterial({ nombre: "", descripcion: "", estado: "Activo" });
          setMostrarModalAgregar(false);
        } else {
          mostrarMensaje(
            "No se pudo agregar el material correctamente.",
            "error"
          );
        }
      } catch (error) {
        mostrarMensaje(
          "Error al agregar el material. Por favor, inténtelo de nuevo.",
          "error"
        );
      }
    } else {
      setErrors(formErrors);
    }
  };

  // Función para manejar la edición de un material existente
  const handleSubmitEditar = async (e) => {
    e.preventDefault();
    const formErrors = validateForm(materialAEditar);
    if (Object.keys(formErrors).length === 0) {
      try {
        const respuesta = await editarMateriales(
          materialAEditar,
          materialAEditar.id
        );
        if (
          respuesta &&
          respuesta.message === "Material actualizado exitosamente"
        ) {
          mostrarMensaje(respuesta.message, "success");
          const materialesObtenidos = await obtenerMateriales();
          setMateriales(materialesObtenidos);
          setMaterialAEditar(null);
          setMostrarModalEditar(false);
        } else {
          mostrarMensaje(
            "No se pudo editar el material correctamente.",
            "error"
          );
        }
      } catch (error) {
        mostrarMensaje(
          "Error al editar el material. Por favor, inténtelo de nuevo.",
          "error"
        );
      }
    } else {
      setErrors(formErrors);
    }
  };

  // Función para abrir el modal de edición con los datos del material seleccionado
  const abrirModalEditar = (material) => {
    setMaterialAEditar(material);
    setMostrarModalEditar(true);
  };

  // Función para manejar el cierre de los modales
  const cerrarModal = () => {
    setMostrarModalAgregar(false);
    setMostrarModalEditar(false);
    setErrors({});
    setNuevoMaterial({ nombre: "", descripcion: "", estado: "Activo" });
  };

  const confirmarDeshabilitacion = (material) => {
    setMaterialADeshabilitar(material);
    setMostrarModalConfirmacionDeshabilitar(true);
  };

  const confirmarHabilitacion = (material) => {
    setMaterialAHabilitar(material);
    setMostrarModalConfirmacionHabilitar(true);
  };

  const deshabilitarMaterial = async (material) => {
    try {
      const materialActualizado = { ...material, estado: "Inactivo" };
      
      const respuesta = await editarMateriales(
        materialActualizado,
        material.id
      );
      if (
        respuesta &&
        respuesta.message === "Material actualizado exitosamente"
      ) {
        mostrarMensaje(respuesta.message, "success");
        cargarMateriales(); // Vuelve a cargar la lista de materiales
        setMostrarModalConfirmacionDeshabilitar(false);
      } else {
        mostrarMensaje(
          "No se pudo deshabilitar el material correctamente.",
          "error"
        );
        setMostrarModalConfirmacionDeshabilitar(false);
      }
    } catch (error) {
      mostrarMensaje(
        "Error al deshabilitar el material. Por favor, inténtelo de nuevo.",
        "error"
      );
      setMostrarModalConfirmacionDeshabilitar(false);
    }
  };

  const habilitarMaterial = async (material) => {
    try {
      const materialActualizado = { ...material, estado: "Activo" };
      const respuesta = await editarMateriales(
        materialActualizado,
        material.id
      );
      if (
        respuesta &&
        respuesta.message === "Material actualizado exitosamente"
      ) {
        mostrarMensaje(respuesta.message, "success");
        cargarMateriales(); // Vuelve a cargar la lista de materiales
        setMostrarModalConfirmacionHabilitar(false);
      } else {
        mostrarMensaje(
          "No se pudo habilitar el material correctamente.",
          "error"
        );
        setMostrarModalConfirmacionHabilitar(false);
      }
    } catch (error) {
      mostrarMensaje(
        "Error al habilitar el material. Por favor, inténtelo de nuevo.",
        "error"
      );
      setMostrarModalConfirmacionHabilitar(false);
    }
  };

  const irAPaginaSiguiente = () => {
    setPaginaActual((pagActual) => Math.min(pagActual + 1, totalPaginas));
  };

  const irAPaginaAnterior = () => {
    setPaginaActual((pagActual) => Math.max(pagActual - 1, 1));
  };

  return (
    <div className="container mx-auto p-4">
      <div className="bg-white p-4 rounded shadow-md">
        {showMessage && (
          <div
            className={`mb-4 p-2 border rounded flex justify-between items-center ${
              messageType === "error"
                ? "bg-red-100 border-red-400 text-red-700"
                : "bg-green-100 border-green-400 text-green-700"
            }`}
          >
            <span>{message}</span>
            <button
              onClick={() => setShowMessage(false)}
              className="text-green-700 hover:text-green-500"
            >
              &#10005;
            </button>
          </div>
        )}
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Materiales</h2>
          <button
            onClick={() => setMostrarModalAgregar(true)}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Agregar Material
          </button>
        </div>
        {materialesActuales.map((material) => (
          <div key={material.id} className="border-b border-gray-200 py-4">
            <div className="flex justify-between items-center">
              <h3
                className="text-lg font-semibold cursor-pointer"
                onClick={() => toggleDetalle(material.id)}
              >
                {material.nombre}
              </h3>
              <div>
                <button
                  onClick={() => abrirModalEditar(material)}
                  className="mr-2 px-3 py-1 bg-blue-500 text-white rounded"
                >
                  Editar
                </button>
                {material.estado === "Activo" ? (
                  <button
                    onClick={() => confirmarDeshabilitacion(material)}
                    className="px-3 py-1 bg-red-500 text-white rounded"
                  >
                    Deshabilitar
                  </button>
                ) : (
                  <button
                    onClick={() => confirmarHabilitacion(material)}
                    className="mr-2 px-3 py-1 bg-green-500 text-white rounded"
                  >
                    Habilitar
                  </button>
                )}
              </div>
            </div>
            {material.abierto && (
              <div className="mt-2 text-gray-600">
                {/* Aquí puedes colocar los detalles adicionales del material */}
                <p>
                  <strong>Descripción:</strong> {material.descripcion}
                </p>
                {/* Otros detalles si los hay */}
              </div>
            )}
          </div>
        ))}
        <div className="flex justify-between items-center mt-8">
          <button
            onClick={irAPaginaAnterior}
            disabled={paginaActual === 1}
            className={`px-4 py-2 rounded-lg text-white ${
              paginaActual === 1
                ? "bg-gray-500"
                : "bg-blue-500 hover:bg-blue-700"
            } transition-colors duration-150`}
          >
            Anterior
          </button>
          <span className="text-lg font-semibold">
            Página {paginaActual} de {totalPaginas}
          </span>
          <button
            onClick={irAPaginaSiguiente}
            disabled={paginaActual === totalPaginas}
            className={`px-4 py-2 rounded-lg text-white ${
              paginaActual === totalPaginas
                ? "bg-gray-500"
                : "bg-blue-500 hover:bg-blue-700"
            } transition-colors duration-150`}
          >
            Siguiente
          </button>
        </div>
      </div>

      {/* Modal para agregar material */}
      {mostrarModalAgregar && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-xl w-3/4 md:w-1/2 lg:w-1/3">
            <form onSubmit={handleSubmitAgregar} className="space-y-4">
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Nombre del Material:
                </label>
                <input
                  type="text"
                  name="nombre"
                  value={nuevoMaterial.nombre}
                  onChange={handleInputChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {errors.nombre && (
                  <p className="text-red-500 text-sm">{errors.nombre}</p>
                )}
              </div>
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Descripción:
                </label>
                <textarea
                  name="descripcion"
                  value={nuevoMaterial.descripcion}
                  onChange={handleInputChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                />
                {errors.descripcion && (
                  <p className="text-red-500 text-sm">{errors.descripcion}</p>
                )}
              </div>
              <div className="flex justify-between">
                <button
                  type="button"
                  onClick={cerrarModal}
                  className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Agregar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Modal para editar material */}
      {mostrarModalEditar && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-xl w-3/4 md:w-1/2 lg:w-1/3">
            <form onSubmit={handleSubmitEditar} className="space-y-4">
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Nombre del Material:
                </label>
                <input
                  type="text"
                  name="nombre"
                  value={materialAEditar.nombre}
                  onChange={handleInputChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {errors.nombre && (
                  <p className="text-red-500 text-sm">{errors.nombre}</p>
                )}
              </div>
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Descripción:
                </label>
                <textarea
                  name="descripcion"
                  value={materialAEditar.descripcion}
                  onChange={handleInputChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                />
                {errors.descripcion && (
                  <p className="text-red-500 text-sm">{errors.descripcion}</p>
                )}
              </div>
              <div className="flex justify-between">
                <button
                  type="button"
                  onClick={cerrarModal}
                  className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Guardar Cambios
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {/* Modal de confirmación para deshabilitar */}
      {mostrarModalConfirmacionDeshabilitar && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 flex justify-center items-center">
          <div className="bg-white p-8 rounded-lg shadow-xl max-w-md mx-auto">
            <h2 className="text-xl font-semibold text-center mb-4">
              Confirmar Deshabilitación
            </h2>
            <p className="text-gray-700 mb-8">
              ¿Estás seguro de que deseas deshabilitar este material?
            </p>
            <div className="flex justify-center gap-4">
              <button
                onClick={() => setMostrarModalConfirmacionDeshabilitar(false)}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-6 rounded transition ease-in-out duration-150"
              >
                Cancelar
              </button>
              <button
                onClick={() => deshabilitarMaterial(materialADeshabilitar)}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-6 rounded transition ease-in-out duration-150"
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Modal de confirmación para habilitar */}
      {mostrarModalConfirmacionHabilitar && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 flex justify-center items-center">
          <div className="bg-white p-8 rounded-lg shadow-xl max-w-md mx-auto">
            <h2 className="text-xl font-semibold text-center mb-4">
              Confirmar Habilitación
            </h2>
            <p className="text-gray-700 mb-8">
              ¿Estás seguro de que deseas habilitar este material?
            </p>
            <div className="flex justify-center gap-4">
              <button
                onClick={() => setMostrarModalConfirmacionHabilitar(false)}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-6 rounded transition ease-in-out duration-150"
              >
                Cancelar
              </button>
              <button
                onClick={() => habilitarMaterial(materialAHabilitar)}
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-6 rounded transition ease-in-out duration-150"
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Materiales;
