import React, { createContext, useState, useEffect } from "react";
import { verificarStockProducto } from "./Api";

export const CarritoContext = createContext();

export const CarritoProvider = ({ children }) => {
  const [carrito, setCarrito] = useState([]);
  const [productoAgregado, setProductoAgregado] = useState(null);

  // Cargar el carrito desde localStorage al iniciar la aplicación
  useEffect(() => {
    const carritoAlmacenado = localStorage.getItem("carrito");
    
    if (carritoAlmacenado) {
      setCarrito(JSON.parse(carritoAlmacenado));
    }
  }, []);

  // Guardar el carrito en localStorage cuando cambie
  /*
  useEffect(() => {
    localStorage.setItem("carrito", JSON.stringify(carrito));
  }, [carrito]);
  */

  const añadirProducto = (nuevoProducto) => {
    setCarrito((carritoActual) => {
        const productoExistente = carritoActual.find((producto) => producto.id === nuevoProducto.id);

        if (productoExistente) {
            const stockPotencial = productoExistente.cantidad + nuevoProducto.cantidad;

            if (stockPotencial <= nuevoProducto.stock - nuevoProducto.stockReserva) {
                const carritoActualizado = carritoActual.map((producto) =>
                    producto.id === nuevoProducto.id
                        ? { ...producto, cantidad: stockPotencial }
                        : producto
                );
                localStorage.setItem("carrito", JSON.stringify(carritoActualizado));
                setProductoAgregado({ ...nuevoProducto, cantidad: nuevoProducto.cantidad });
                return carritoActualizado;
            } else {
                alert("Stock insuficiente");
                return carritoActual;
            }
        } else {
            if (nuevoProducto.cantidad <= nuevoProducto.stock - nuevoProducto.stockReserva) {
                const carritoActualizado = [...carritoActual, { ...nuevoProducto, cantidad: nuevoProducto.cantidad }];
                localStorage.setItem("carrito", JSON.stringify(carritoActualizado));
                setProductoAgregado({ ...nuevoProducto, cantidad: nuevoProducto.cantidad });
                return carritoActualizado;
            } else {
                alert("Stock insuficiente");
                return carritoActual;
            }
        }
    });
};






    const removerProducto = (productoId) => {
      setCarrito((carritoActual) => {
          const carritoActualizado = carritoActual.filter((producto) => producto.id !== productoId);
          localStorage.setItem('carrito', JSON.stringify(carritoActualizado));
          return carritoActualizado;
      });
  };
  

  const actualizarCantidad = (productoId, nuevaCantidad) => {
    setCarrito((carritoActual) => {
      const carritoActualizado = carritoActual.map((producto) => {
        if (producto.id === productoId) {
          return { ...producto, cantidad: nuevaCantidad };
        }
        return producto;
      });
      localStorage.setItem('carrito', JSON.stringify(carritoActualizado));
      return carritoActualizado;
    });
  };
  

  async function manejarCambioCantidad(productoId, nuevaCantidad) {
    try {
        const { cantidadDisponible } = await verificarStockProducto(productoId, nuevaCantidad);
    
        setCarrito((carritoActual) => {
            let carritoActualizado;
            if (cantidadDisponible === 0) {
                // Mostrar mensaje de que el producto se ha agotado y será eliminado del carrito
                alert(`El producto se ha agotado y será eliminado de tu carrito.`);
                // Filtrar el producto fuera del carritoActualizado
                carritoActualizado = carritoActual.filter((producto) => producto.id !== productoId);
            } else {
                carritoActualizado = carritoActual.map((producto) => {
                    if (producto.id === productoId) {
                        // Si hay stock disponible pero menos del solicitado, ajustar la cantidad y notificar al usuario
                        if (nuevaCantidad > cantidadDisponible) {
                            alert(`Solo hay ${cantidadDisponible} unidades disponibles para este producto. Hemos ajustado la cantidad en tu carrito.`);
                            return { ...producto, cantidad: cantidadDisponible };
                        } else {
                            // Si la cantidad solicitada está disponible, simplemente actualizar la cantidad
                            return { ...producto, cantidad: nuevaCantidad };
                        }
                    }
                    return producto;
                });
            }
    
            // Actualizar el localStorage con el carrito actualizado
            localStorage.setItem('carrito', JSON.stringify(carritoActualizado));
            return carritoActualizado;
        });
    } catch (error) {
        console.error('Error al verificar el stock:', error);
    }
}

  


const vaciarCarrito = () => {
  setCarrito([]);
  localStorage.setItem('carrito', JSON.stringify([]));
};


  const obtenerTotal = () => {
    return carrito.reduce(
      (total, producto) => total + producto.precio * producto.cantidad,
      0
    );
  };

  return (
    <CarritoContext.Provider
      value={{
        carrito,
        setCarrito,
        productoAgregado,
        setProductoAgregado,
        añadirProducto,
        removerProducto,
        actualizarCantidad,
        vaciarCarrito,
        obtenerTotal,
        manejarCambioCantidad,
      }}
    >
      {children}
    </CarritoContext.Provider>
  );
};
