import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import './App.css';
import { CarritoProvider } from './services/CarritoProvider';
import Navbar from './services/Navbar';
import Inicio from './views/Inicio';
import Contacto from './views/Contacto';
import Registrarse from './views/Registrarse';
import IniciarSesion from './views/IniciarSesion';
import Administrar from './views/Administrar';
import Catalogo from './views/Catalogo';
import DetalleProducto from './views/DetalleProducto';
import Carrito from './views/Carrito';
import Pago from './views/Pago';
import ConfirmacionCompra from './views/ConfirmacionCompra';
import Preguntas from './views/Pregunta';
import Portafolio from './views/Portafolio';
import Cuenta from './views/Cuenta';
import { Navigate } from 'react-router-dom';
import { verificarToken } from "./services/Api";
import Error404 from "./views/Error404";
import SolicitarRestablecimiento from "./views/SolicitarRestablecimiento";
import RestablecerContrasena from "./views/RestablecerContrasena";

function App() {

  const [authToken] = useState(localStorage.getItem("auth_token"));
  const [userRol, setUserRol] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Nuevo estado para manejar la carga

  useEffect(() => {
    const verify = async () => {
      await verificarToken(); // Asume que esto es asíncrono
      const user_rol = localStorage.getItem("auth_user_rol");
      setUserRol(user_rol);
      setIsLoading(false); // Establecer la carga como false después de obtener los datos
    };

    verify();
  }, [authToken]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen" style={{backgroundColor: '#f3e9dc'}}>
        <CircularProgress style={{ color: '#ffffff' }} />
      </div>
    );
  }
  const PrivateRoute = ({ children, userRol, requiredRol, redirectTo }) => {
    return userRol === requiredRol ? children : <Navigate to={redirectTo} />;
  };

  const AuthRoute = ({ children }) => {
    return authToken ? children : <Navigate to="/" />;
  };

  return (
    <CarritoProvider> {/* Envuelve tus rutas con CarritoProvider */}
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Inicio />} />
          <Route path="/contacto" element={<Contacto />} />
          <Route path="/register" element={<Registrarse />} />
          <Route path="/login" element={<IniciarSesion />} />
          <Route path="/forgot-password" element={<SolicitarRestablecimiento />} />
          <Route path="/reset-password" element={<RestablecerContrasena />} />
          <Route path="/administrar" element={
            <PrivateRoute userRol={userRol} requiredRol="Admin" redirectTo="/">
              <Administrar />
            </PrivateRoute>
          } />
          <Route path="/catalogo" element={<Catalogo />} />
          <Route path="/catalogo/:nombreProducto/:publicIdProducto" element={<DetalleProducto />} />
          <Route path="/carrito" element={<Carrito />} />
          <Route path="/pago" element={<Pago />} />
          <Route path="/confirmacion-compra" element={<ConfirmacionCompra />} />
          <Route path="/faq" element={<Preguntas />} />
          <Route path="/portafolio" element={<Portafolio />} />
          <Route path="/cuenta" element={
            <AuthRoute>
              <Cuenta />
            </AuthRoute>
          } />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </Router>
    </CarritoProvider>
  );
}

export default App;
